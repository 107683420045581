import React from "react";
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Divider,
    useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilledButton from "../../../components/MainButtons/FilledButton";
import DeleteIcon from "../../../assets/Icons/delete.svg"




const DeleteVehicleModal = ({ open, handleClose, handleDeleteVehicle }) => {
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "576px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 1.5,
                    borderRadius: "15px",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px"
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography
                        id="modal-title"
                        sx={{ color: "#283246", fontSize: '24px', fontWeight: 600 }}
                    >
                        Delete
                    </Typography>
                    <IconButton onClick={handleClose} sx={{ fontSize: "24px", fontWeight: "600", color: "#283246" }} >
                        <CloseIcon />
                    </IconButton>
                </Box>


                <Box
                    sx={{
                        background: "#283246",
                        width: "2.75rem",
                        height: "2.75rem",
                        padding: "0.438rem",
                        borderRadius: "1.25rem",
                        cursor: "pointer",
                        margin: 'auto',
                        mt: 2,

                    }}
                >
                    <img src={DeleteIcon} alt="delete-icon" style={{ width: '42px', height: '42px' }} />
                </Box>


                <Box sx={{display:"flex",flexDirection:'column',justifyContent:"center",alignItems:"center",gap:"10px"}} >
                    <Typography
                        id="modal-description"
                        sx={{ color: '#283246', width: '80%', margin: 'auto', p: 2, fontSize: '1rem', fontWeight: 500 }}
                    >
                        Are you Sure you want to Delete ?
                    </Typography>

                    <FilledButton
                        sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}
                        onClick={handleDeleteVehicle}
                    >
                        Delete
                    </FilledButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default DeleteVehicleModal;
