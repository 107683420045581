export const CompletedTripsTablesCellItems=[
    {label:"Trip ID",key:"trip_id"},
    {label:"User ID",key:"user_id"},
    {label:"Vehicle QR",key:"vehicle_qr"},
    {label:"Username",key:"username"},
    {label:"Duration",key:"duration"},
    {label:"Distance(KM)",key:"distance"},
    {label:"Cost",key:"cost"},
    {label:"Date",key:"date"},
    {label:"Rating",key:"ratings"},
]

export const OngoingTripsTablesCellItems=[
    {label:"Trip ID",key:"trip_id"},
    {label:"User ID",key:"user_id"},
    {label:"Vehicle QR",key:"vehicle_qr"},
    {label:"Username",key:"username"},
    {label:"Duration",key:"duration"},
    {label:"Distance(KM)",key:"distance"},
    {label:"Cost",key:"cost"},
    {label:"Date",key:"date"},
    {label:"Vehicle Action",key:"vehicle_action"}
]

export const CompletedTripsTableData=[
    {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 12th, 2024 17:19:09","ratings":4.3,"pause_duration":"00:25:12","vehicle_id":"01","fleet":"Shyamal Fleet","status":"Force Stop",
        "activity": [
            {
                userId: "9",
                vehicleId: "01",
                type: "ended",
                desc:"This Ride has been ended.",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "pause",
                desc:"This Ride has been stop.",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "started",
                desc:"This Ride has been started.",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "checked",
                desc:"Payment Successfully Done.",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
    },
    {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 17:19:09","ratings":2.1,"pause_duration":"00:10:00","vehicle_id":"02","fleet":"New Ranip Fleet","status":"Automatically ended",
        "activity": [
            {
                userId: "9",
                vehicleId: "01",
                type: "ended",
                desc:"This Ride has been ended.",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "pause",
                desc:"This Ride has been stop.",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "started",
                desc:"This Ride has been started.",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "checked",
                desc:"Payment Successfully Done.",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
    },
    {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 20th, 2024 17:19:09","ratings":0.8,"pause_duration":"00:10:12","vehicle_id":"03","fleet":"Shiv Ranjani Fleet","status":"Grace Period",
        "activity": [
            {
                userId: "9",
                vehicleId: "01",
                type: "ended",
                desc:"This Ride has been ended.",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "pause",
                desc:"This Ride has been stop.",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "started",
                desc:"This Ride has been started.",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "checked",
                desc:"Payment Successfully Done.",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
    },
    {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 01th, 2024 17:19:09","ratings":1.9,"pause_duration":"00:02:12","vehicle_id":"04","fleet":"Shyamal Fleet","status":"Force Stop",
        "activity": [
            {
                userId: "9",
                vehicleId: "01",
                type: "ended",
                desc:"This Ride has been ended.",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "pause",
                desc:"This Ride has been stop.",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "started",
                desc:"This Ride has been started.",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "checked",
                desc:"Payment Successfully Done.",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
    },
    {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 05th, 2024 17:19:09","ratings":3.7,"pause_duration":"00:12:12","vehicle_id":"05","fleet":"New Ranip Fleet","status":"Automatically ended",
        "activity": [
            {
                userId: "9",
                vehicleId: "01",
                type: "ended",
                desc:"This Ride has been ended.",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "pause",
                desc:"This Ride has been stop.",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "started",
                desc:"This Ride has been started.",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                userId: "9",
                vehicleId: "01",
                type: "checked",
                desc:"Payment Successfully Done.",
                time: "Oct 2th, 2024 18:33:45"
            },
        ],
      },
]

export const OngoingTripsTableData= [
  {"trip_id":1,"user_id":101,"vehicle_qr":"vehicle001","username":"user101","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 1st, 2024 09:30:00","vehicle_action":"Unlock","fleet":"Shyamal Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":2,"user_id":102,"vehicle_qr":"vehicle002","username":"user102","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 2nd, 2024 11:45:00","vehicle_action":"Lock","fleet":"New Ranip Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":3,"user_id":103,"vehicle_qr":"vehicle003","username":"user103","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 3rd, 2024 08:15:00","vehicle_action":"Unlock","fleet":"Shiv Ranjani Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":4,"user_id":104,"vehicle_qr":"vehicle004","username":"user104","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 4th, 2024 10:20:00","vehicle_action":"Lock","fleet":"Shiv Ranjani Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":5,"user_id":105,"vehicle_qr":"vehicle005","username":"user105","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 5th, 2024 12:10:00","vehicle_action":"Unlock","fleet":"New Ranip Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":6,"user_id":106,"vehicle_qr":"vehicle006","username":"user106","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 6th, 2024 14:35:00","vehicle_action":"Unlock","fleet":"Shiv Ranjani Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":7,"user_id":107,"vehicle_qr":"vehicle007","username":"user107","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 7th, 2024 15:45:00","vehicle_action":"Unlock","fleet":"Shiv Ranjani Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":8,"user_id":108,"vehicle_qr":"vehicle008","username":"user108","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 8th, 2024 17:00:00","vehicle_action":"Lock","fleet":"New Ranip Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":9,"user_id":109,"vehicle_qr":"vehicle009","username":"user109","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 9th, 2024 18:20:00","vehicle_action":"Unlock","fleet":"Shiv Ranjani Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  },
  {"trip_id":10,"user_id":110,"vehicle_qr":"vehicle010","username":"user110","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 10th, 2024 19:15:00","vehicle_action":"Lock","fleet":"Shyamal Fleet",       
     "activity": [
    {
        userId: "9",
        vehicleId: "01",
        type: "ended",
        desc:"This Ride has been ended.",
        time: "Oct 5th, 2024 18:33:45"
    },
    {
        userId: "9",
        vehicleId: "01",
        type: "pause",
        desc:"This Ride has been stop.",
        time: "Oct 4th, 2024 18:33:45"
    },
    {
        userId: "9",
        vehicleId: "01",
        type: "started",
        desc:"This Ride has been started.",
        time: "Oct 3th, 2024 18:33:45"
    },
    {
        userId: "9",
        vehicleId: "01",
        type: "checked",
        desc:"Payment Successfully Done.",
        time: "Oct 2th, 2024 18:33:45"
    },
],},
  {"trip_id":11,"user_id":111,"vehicle_qr":"vehicle011","username":"user111","duration":"01:05:25","distance":"20.00 KM","cost":"$80.00","date":"Jul 11th, 2024 07:45:00","vehicle_action":"Lock","fleet":"New Ranip Fleet",
    "activity": [
        {
            userId: "9",
            vehicleId: "01",
            type: "ended",
            desc:"This Ride has been ended.",
            time: "Oct 5th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "pause",
            desc:"This Ride has been stop.",
            time: "Oct 4th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "started",
            desc:"This Ride has been started.",
            time: "Oct 3th, 2024 18:33:45"
        },
        {
            userId: "9",
            vehicleId: "01",
            type: "checked",
            desc:"Payment Successfully Done.",
            time: "Oct 2th, 2024 18:33:45"
        },
    ],
  }
]





