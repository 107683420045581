import { Box, Paper, MenuItem, FormControl, Select, Typography } from "@mui/material"
import SearchTextField from "../../../components/SearchTextField"
import FleetSelectIocn from "../../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import Plus from "../../../assets/Icons/plus.svg"
import FilledButton from "../../../components/MainButtons/FilledButton"
import {RestrictedZoneTableData,RestrictedZoneTablesCellItems} from "../utills"
import UserTableComponent from "../../UserManagement/UserTable"
import { useDispatch, useSelector } from "react-redux"
import DeleteRestrictedZone from "../DeleteRestrictedZone/DeleteRestrictedZone"
import { deleteRestrictedZone } from "../../../slices/restrictedzoneSlice"
import { MenuItemStyle } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const RestrictedZoneTable = () => {
    const navigate=useNavigate()
    const {restrictedzoneData}=useSelector((state)=>state.restrictedzoneManage)
    console.log(restrictedzoneData,"this is redux data")
    const [searchInput, setSearchInput] = useState("");
    const [fleetOptions, setFleetOptions] = useState("All")
    const [status, setStatus] = useState("All")
    const [deleteModal,setDeleteModal]=useState(false)
    const [deleteRestrictedZoneId,setDeleteRestrictedZoneId]=useState()
    const dispatch=useDispatch()

    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleFleetChange = (e) => {
        setFleetOptions(e.target.value)
    }
    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }


    const handleDeleteModalClose=()=>{
        setDeleteModal(false)
    }

    const handleDeleteRestrictedZone=()=>{
        
        // dispatch(deleteFleet(deleteFleetId))
        dispatch(deleteRestrictedZone(deleteRestrictedZoneId))
        setDeleteModal(false)
    
    }

    const OpenDeleteRestrictedZone=(id)=>{
        setDeleteModal(true)
        setDeleteRestrictedZoneId(id)
    }


    const filterRestrictedZoneTablesData = restrictedzoneData.filter((RestrictedZone) => {
        const matchesSearchInput = searchInput ? RestrictedZone.name.includes(searchInput) : true;
        const matchesFleetOption = fleetOptions !== "All" ? RestrictedZone.fleet === fleetOptions : true;
        const matchesStatus = status !== "All" ? RestrictedZone.status === status : true;

        return matchesSearchInput && matchesFleetOption && matchesStatus 
      });

    

    return (
        <Box sx={{display:"flex",flexDirection:"column",gap:"20px",  minHeight:"75vh",}} >
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography sx={{ display: "flex", gap: "10px", fontSize: "24px", fontWeight: "700",color:"#283246" }}> Restricted Zone</Typography>
                </Box>
            </Box>
            <Box sx=
                {{
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px', 
                    p: 1, 
                    borderRadius: '6px', 
                    mb: 2,
                    display:"flex"

                }}>

                <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >

                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={FleetSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fleetOptions}
                                    label="Fleet"
                                    onChange={(e) => handleFleetChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}
                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Shymal Cross Road Public Fleet"}>Shymal Cross Road Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Ranip Road Private Fleet"}>Ranip Road Private Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Shivranjini Cross Road Public Fleet"}>Shivranjini Cross Road Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>

                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={StatusSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    onChange={(e) => handleStatusChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}
                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Active"}>Active</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Inactive"}>Inactive</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>

                    </Box>

                </Box>
                <Box>
                    <SearchTextField value={searchInput} onChange={handleSearchChange} />
                </Box>
                <Box
                    onClick={() => { navigate("/dashboard/geofence/create-restricted-zone") }}
                >
                    <FilledButton startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />}sx={{ width: '200px', height: '40px', borderRadius: '6px', ml: 3 ,fontSize: "14px", fontWeight: "600"}}>
                    Restricted Zone
                    </FilledButton>
                </Box>
            </Box>
            <Box>
                <UserTableComponent
                    tableHeaders={RestrictedZoneTablesCellItems}
                    data={filterRestrictedZoneTablesData}
                    showActions={true}
                    handleEdit={()=>{}}
                    handleDelete={OpenDeleteRestrictedZone}
                    restrictedZoneNavigation={true}
                />
            </Box>
            <DeleteRestrictedZone open={deleteModal} handleClose={handleDeleteModalClose} handleRestrictedZone={handleDeleteRestrictedZone} />
        </Box>
    )
}


export default RestrictedZoneTable