export const PaymentGatewayTablesCellItems = [
    { label: "U UID", key: "uuid" },
    { label: "Name", key: "name" },
    { label: "Currency", key: "currencey" }
]

export const PaymentGatewayTablesData = [
    {
        "uuid": "Uuid 1",
        "name": "Strip Payment 1",
        "currencey": "EUR",
        "publish_key": "publish key 1",
        "secret_key": "secret key 1",
        "client_id": "client id 1",
        "cvv_requirement": "Yes",
        "payment_methods": [
            {
                name: "Apple Pay",
                support: true
            },
            {
                name: "Google Pay",
                support: false
            },
            {
                "name": "iDEAL",
                support: false
            },
            {
                name: "Bancontact",
                support: true
            }
        ],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 2",
        "name": "Strip Payment 2",
        "currencey": "INR",
        "publish_key": "publish key 2",
        "secret_key": "secret key 2",
        "client_id": "client id 2",
        "cvv_requirement": "No",
        "payment_methods": [
            {
                name: "Apple Pay",
                support: false
            },
            {
                name: "Google Pay",
                support: true
            },
            {
                "name": "iDEAL",
                support: true
            },
            {
                name: "Bancontact",
                support: false
            }
        ],
        "activity": [
            {
                user: "19",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "18",
                userRole: "Admin",
                type: "update",
                time: "Oct 15th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 8th, 2024 18:33:45"
            },
            {
                user: "10",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 4th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 3",
        "name": "Strip Payment 3",
        "currencey": "CAD",
        "publish_key": "publish key 3",
        "secret_key": "secret key 3",
        "client_id": "client id 3",
        "cvv_requirement": "No",
        "payment_methods": [
            {
                name: "Apple Pay",
                support: true
            },
            {
                name: "Google Pay",
                support: false
            },
            {
                "name": "iDEAL",
                support: false
            },
            {
                name: "Bancontact",
                support: true
            }
        ],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 4",
        "name": "Strip Payment 4",
        "currencey": "EUR",
        "publish_key": "publish key 4",
        "secret_key": "secret key 4",
        "client_id": "client id 4",
        "cvv_requirement": "No",
        "payment_methods": [
            {
                name: "Apple Pay",
                support: false
            },
            {
                name: "Google Pay",
                support: true
            },
            {
                "name": "iDEAL",
                support: true
            },
            {
                name: "Bancontact",
                support: false
            }
        ],
        "activity": [
            {
                user: "15",
                userRole: "Admin",
                type: "update",
                time: "Sep 5th, 2024 18:33:45"
            },
            {
                user: "28",
                userRole: "Admin",
                type: "update",
                time: "Sep 4th, 2024 18:33:45"
            },
            {
                user: "17",
                userRole: "Admin",
                type: "update",
                time: "Sep 3th, 2024 18:33:45"
            },
            {
                user: "21",
                userRole: "Super Admin",
                type: "create",
                time: "Sep 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "uuid": "Uuid 5",
        "name": "Strip Payment 5",
        "currencey": "IND",
        "publish_key": "publish key 5",
        "secret_key": "secret key 5",
        "client_id": "client id 5",
        "cvv_requirement": "No",
        "payment_methods": [
            {
                name: "Apple Pay",
                support: true
            },
            {
                name: "Google Pay",
                support: false
            },
            {
                "name": "iDEAL",
                support: false
            },
            {
                name: "Bancontact",
                support: true
            }
        ],
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    }
]