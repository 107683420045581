import { createSlice } from "@reduxjs/toolkit";
import {SlowZoneTableData} from "../features/SlowSpeedZone/utills"


const initialState={
    slowspeedzoneData:SlowZoneTableData || []
}

const SlowSpeedZoneManageSlice=createSlice({
    name:"slowspeedzone",
    initialState,
    reducers:{
        updateSlowSpeedZone:(state,action)=>{
            const updatedSlowSpeedZoneData = action.payload;
            const slowspeedzoneIndex = state.slowspeedzoneData.findIndex(slowspeedzone => slowspeedzone.id === updatedSlowSpeedZoneData.id);
            console.log(slowspeedzoneIndex,"slowspeedzoneIndex")
            if (slowspeedzoneIndex !== -1) {
                // Update the specific fleet data
                state.slowspeedzoneData[slowspeedzoneIndex] = {
                    ...state.slowspeedzoneData[slowspeedzoneIndex],
                    ...updatedSlowSpeedZoneData
                };
            }
        },
        createSlowSpeedZone:(state,action)=>{
            const NewSlowSpeedZoneData=action.payload
            if(NewSlowSpeedZoneData){
                state.slowspeedzoneData=[...state.slowspeedzoneData,NewSlowSpeedZoneData]
            }
        },
        deleteSlowSpeedZone:(state,action)=>{
            const slowSpeedZoneId=action.payload
            console.log(slowSpeedZoneId,"this is delete id")
            const SlowSpeedZoneToDelete=state.slowspeedzoneData.find(slowspeedZone=>slowspeedZone.id===slowSpeedZoneId)

            if(SlowSpeedZoneToDelete){
                state.slowspeedzoneData=state.slowspeedzoneData.filter(slowspeedZone=>slowspeedZone.id!==slowSpeedZoneId)
            }

        }
    }
})

export const {updateSlowSpeedZone,createSlowSpeedZone,deleteSlowSpeedZone}=SlowSpeedZoneManageSlice.actions
export default SlowSpeedZoneManageSlice.reducer