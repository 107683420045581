import { Box } from "@mui/material";
import { PaymentsCard } from "./PaymentsCard";
import PaymentsGraphs from "./PaymentsGraphs";

export const PaymentOverview = () => {
  return(
    <Box>

        <Box>
          <PaymentsCard/>
        </Box>
        <Box>
          <PaymentsGraphs/>
        </Box>

    </Box>
  )
};
