import React from "react";
import { Card, Paper, Box, Avatar, Typography, useTheme } from "@mui/material"
import AverageRevnueIcon from "../../assets/Icons/AverageRevnueIcon.svg"
import AverageTripRevnueIcon from "../../assets/Icons/AverageTripRevenuIcon.svg"
import AverageFleetRevnueIcon from "../../assets/Icons/AverageFleetRevnueIcon.svg"
export const PaymentsCard = () => {
  const paymentData = [
    {
      name: "Average Revenue Per Day",
      revenue: "$10",
      icon: AverageRevnueIcon
    },
    {
      name: "Average Trip Revenue",
      revenue: "$100",
      icon: AverageTripRevnueIcon
    },
    {
      name: "Average Fleet Revenue",
      revenue: "$200",
      icon: AverageFleetRevnueIcon
    }
  ]
  return (
    <Paper
      sx={{
        borderRadius: "1.25rem",
        background: "#F8F8F8",
        padding: "1.563rem",
        boxShadow: "none",
        display:"flex",
        justifyContent:"space-around",
        mt: 1,
        mb: 2,
      }}
    >
      {paymentData.map((user) => (
        <Card
          sx={{
            width: "20%",
            borderRadius: "1rem",
            padding: "1rem 3.438rem",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center content horizontally
            boxShadow: "none",
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: "52px",
              height: "52px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 1,
            }}
          >
            <Avatar src={user.icon} sx={{height:"70px",width:"70px"}} />
          </Box>

          <Typography
            sx={{
              fontSize: "16px",
              color: "#283246",
              fontWeight: 500,
              marginBottom: "0.5rem"
            }}
          >
            {user.name}
          </Typography>
          <Typography
            sx={{
              fontSize: "1.375rem",
              color: "#283246",
              fontWeight: 700,
            }}
          >
            {user.revenue}
          </Typography>
        </Card>
      ))}
    </Paper>
  )
};
