export const ParkingZoneTablesCellItems = [
    { label: "Name", key: "name" },
    { label: "Created Date", key: "created_date" },
    { label:'Created By',key:"created_by"},
    { label:"Status",key:'status' },
    { label:"Updated Date",key:'updated_date'},
]

export const ParkingZoneTablesData = [
    {
        "name":"Shyamal Parking Area",
        "id":"1",
        "fleet":"Shymal Cross Road Public Fleet",
        "zone_type":"Parking Zone",
        "minimum_vehicles":"10",
        "maximum_vehicles":"0",
        "desc":"THIS IS SHYAMAL PARKING ZONE",
        "status":"Active",
        "show_oprator_app":false,
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Ranip Parking Area",
        "id":"2",
        "fleet":"Ranip Road Private Fleet",
        "zone_type":"Parking Zone",
        "minimum_vehicles":"22",
        "maximum_vehicles":"10",
        "desc":"THIS IS RANIP PARKING ZONE",
        "status":"Inactive",
        "show_oprator_app":true,
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User2",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Shivranjini Parking Area ",
        "id":"3",
        "fleet":"Shivranjini Cross Road Public Fleet",
        "zone_type":"Parking Zone",
        "minimum_vehicles":"18",
        "maximum_vehicles":"20",
        "desc":"THIS IS IS Shivranjini PARKING ZONE",
        "status":"Active",
        "show_oprator_app":false,
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Gujarat University Parking Area ",
        "id":"4",
        "fleet":"Gujarat University Public Fleet",
        "zone_type":"Parking Zone",
        "minimum_vehicles":"15",
        "maximum_vehicles":"10",
        "desc":"THIS IS Gujarat University  PARKING ZONE",
        "status":"Inactive",
        "show_oprator_app":false,
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Nikol Parking Area",
        "id":"5",
        "fleet":"Nikol Cross Road Public Fleet",
        "zone_type":"Parking Zone",
        "minimum_vehicles":"10",
        "maximum_vehicles":"10",
        "desc":"THIS IS Nikol PARKING ZONE",
        "status":"Active",
        "show_oprator_app":true,
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
]