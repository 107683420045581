import { IconButton, Paper, Box, Typography, InputLabel, Select, MenuItem, Chip, Grid, Tab, Tabs, FormControl } from "@mui/material"
import React, { useState } from "react";
import SearchTextField from "../../components/SearchTextField";
import ServiceZone from "../../assets/Icons/FlagsIcon.svg"
import FleetSelectIocn from "../../assets/Icons/FleetSelectIocn.svg"
import VehicleConnectedIcon from "../../assets/Icons/VehicleConnectedIcon.svg"
import { MainMap } from "../../components/MainMap/MainMap";
import CustomDatePicker from "../Trips/TripsTable/CustomDatePicker";
import { flagDetails } from "../../utils/MapIocns";
import DropDownIcon from "../../assets/Icons/downgunmetal.svg"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { MenuItemStyle } from "../../styles/MenuItemStyle";




const tabStyles = {
  width: '191px',
  height: '40px',
  fontWeight: 600,
  fontSize: '1rem',
  textTransform: 'none',
  color: '#F99494',
  '&.Mui-selected': {
    background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
    color: '#FFFFFF',
    borderRadius: '12px',
  },
};


export const MapPage = () => {

  const [value, setValue] = useState(0)
  const [startDate, setStartDate] = useState(() => {
    const today = new Date();
    return new Date(today.getFullYear(), 0, 1); 
  });
  const [endDate, setEndDate] = useState(new Date());
  const [searchInput, setSearchInput] = useState("");
  const [FleetOptions, setFleetOptions] = useState("All")
  const [flags, setFlags] = useState("All")
  const [VehicleConnected, setVehicleConnected] = useState("All")
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleFleetChange = (e) => {
    setFleetOptions(e.target.value)
  }
  const handleVehicleConnectedChange = (e) => {
    setVehicleConnected(e.target.value)
  }


  const handleFlagChange = (e) => {
    setFlags(e.target.value)
  }
  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}>
        <Box>
          <Box sx={{ marginBottom:"20px" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Active Users"
              sx={{
                bgcolor: '#F8F8F8',
                width: "fit-content",
                borderRadius: '12px',
                border: '1px solid #DDDEE1',
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
              }}
            >
              {['Map', 'HeatMap'].map((label, index) => (
                <Tab
                  key={label}
                  disableRipple
                  label={label}
                  sx={tabStyles}
                />
              ))}
            </Tabs>
          </Box>

          <Box>
            {
              value == 0 ?
                (
                  <>
                    <Box sx={{ 
                      display: "flex", 
                      border: '1px solid #DDDEE1',
                      background: '#F1F1F2',
                      height: '42px', p: 1,
                      borderRadius: '6px', 
                      mb: 3 }}>

                      <Box sx={{ display: "flex", gap: "10px", flex: 1 }} >
                        <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            border:"1px solid #DDDEE1",
                            background:"white",
                            borderRadius: "8px"
                          }} >
                          <Box>
                            <img src={FleetSelectIocn} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={FleetOptions}
                                label="Fleet"
                                onChange={(e) => handleFleetChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}


                              >
                                <MenuItem sx={MenuItemStyle} value={"All"}>Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Shymal Cross Road Public Fleet"}>Shyamal Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Ranip Road Private Fleet"}>New Ranip Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Shivranjini Cross Road Public Fleet"}>Shiv Ranjani Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>

                        <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            border:"1px solid #DDDEE1",
                            padding: "5px",
                            borderRadius: "8px"
                          }} >
                          <Box>
                            <img src={ServiceZone} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={flags}
                                onChange={(e) => handleFlagChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}
                              >
                                <MenuItem sx={MenuItemStyle} value={"All"}>Flags</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Service Start/End"}>Service Start/End</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Maintenance"}>Maintenance</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"IOT Fault"}>IOT Fault</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"Battery Critical"}>Battery Critical</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                        </Box>

                        <Box sx=
                          {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border:"1px solid #DDDEE1",
                          }} >
                          <Box>
                            <img src={VehicleConnectedIcon} />
                          </Box>
                          <Box
                            sx={{
                              width: "fit-content"
                            }}
                          >
                            <FormControl variant="standard" >

                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={VehicleConnected}
                                onChange={(e) => handleVehicleConnectedChange(e)}
                                disableUnderline={true}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={MenuItemStyle}
                              >
                                <MenuItem sx={MenuItemStyle} value={"All"}>Vehicle Connected</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"3 Hours"}>3 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"6 Hours"}>6 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"9 Hours"}>9 Hours</MenuItem>
                                <MenuItem sx={MenuItemStyle} value={"12 Hours"}>12 Hours</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>

                        </Box>
                      </Box>

                      <Box>
                        <SearchTextField value={searchInput}
                          onChange={handleSearchChange} />

                      </Box>

                    </Box>
                    <Box  >
                      <MainMap largeHeight={"400px"} extraLargeHeight={"500px"} />
                    </Box>
                  </>
                ) : (<></>)
            }

          </Box>
        </Box>

      </Paper>
      {
        value == 0 ?
          (<Paper
            sx={{
              borderRadius: '1.25rem',
              background: '#F8F8F8',
              padding: '1.563rem',
              boxShadow: 'none',
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 2,
            }}
          >
            <Typography sx={{ color: "#283246", fontWeight: "600", fontSize: "24px" }}>
              Flags
            </Typography>
            <Grid container spacing={4}>
              {flagDetails.map((flag, i) => (
                <>
                  <Grid item xs={2.4}>
                    <Box display="flex" alignItems="center">
                      <Box sx={{ cursor: "pointer" }}>
                        <img src={flag.icon} alt="flag-icon" />
                      </Box>
                      <Typography sx={{ ml: 1, color: '#283246', fontSize: '14px', fontWeight: "600" }}>{flag.name}</Typography>
                    </Box>
                  </Grid>
                </>
              ))}
            </Grid>
          </Paper>





          ) : (<></>)
      }

      {
        value == 1 ?
          (<>
            <Paper
              sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                gap: 2,
                mt: 1,
                mb: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  border: '1px solid #DDDEE1',
                  background: '#F1F1F2',
                  height: '42px', p: 1,
                  borderRadius: '6px', mb: 5, gap: "20px",
                  alignItems: 'center'

                }}>

                <Box sx={{ display: "flex", }} >
                  <Box sx=
                    {{
                      display: "flex",
                      alignItems: "center",
                      width: "fit-content",
                      gap: "10px",
                      background: "white",
                      padding: "5px",
                      borderRadius: "8px",
                      border:"1px solid #DDDEE1",
                    }} >
                    <Box>
                      <img src={FleetSelectIocn} />
                    </Box>
                    <Box
                      sx={{
                        width: "fit-content"
                      }}
                    >
                      <FormControl variant="standard" >

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={FleetOptions}
                          label="Fleet"
                          onChange={(e) => handleFleetChange(e)}
                          disableUnderline={true}
                          IconComponent={KeyboardArrowDownIcon}
                          sx={MenuItemStyle}

                        >
                          <MenuItem sx={MenuItemStyle} value={"All"}>Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Shymal Cross Road Public Fleet"}>Shyamal Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Ranip Road Private Fleet"}>New Ranip Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Shivranjini Cross Road Public Fleet"}>Shiv Ranjani Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                          <MenuItem sx={MenuItemStyle} value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>



                </Box>

                <Box >
                  <CustomDatePicker
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                </Box>


              </Box>

              <Box>
                <MainMap largeHeight={"400px"} extraLargeHeight={"500px"} />
              </Box>

            </Paper>
          </>) :
          (<>

          </>)
      }


    </>
  )
};
