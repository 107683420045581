import React, { useState } from "react";
import { Box, Paper, Typography, useTheme, InputLabel, Grid, Select, MenuItem } from "@mui/material";
import mobileScreen1 from "../../assets/Images/mobileScreen1.png";
import mobileScreen2 from "../../assets/Images/mobileScreen2.png";
import mobileScreen3 from "../../assets/Images/mobileScreen3.png";
import FilledButton from "../../components/MainButtons/FilledButton";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import { useSelector, useDispatch } from "react-redux";
import { updateSettings } from "../../slices/settingsSlice"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



export const Settings = () => {
  const theme = useTheme();
  const dispath = useDispatch()
  const { settingsData } = useSelector((state) => state.settingManage)
  console.log(settingsData, "this is bois")
  const inputLabel = {
    fontSize: "12px",
    fontWeight: 700,
    mb: 1,
    color: "#283246"
  }

  const [formData, setFormData] = useState({
    current_version: settingsData.current_version,
    app_store_url: settingsData.app_store_url,
    desc: settingsData.desc,
    play_store_url: settingsData.play_store_url,
    iot_type: settingsData.iot_type
  })

  console.log(formData, "this is changes")

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "75vh",
          display: "flex",
          flexDirection: 'column'
        }}
      >

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' }, width: '100%', flex: 1 }}>
          <Box sx={{ flex: 1 }}>
            {/* Application Configuration Section */}
            <Typography sx={{
              color: "#283246",
              fontSize: "24px",
              fontWeight: "600", mb: 3
            }}>Application Configuration</Typography>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }} >
              <Box sx={{ display: "flex", gap: "20px" }}  >


                <Box>
                  <InputLabel sx={inputLabel}>
                    Current Version  <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <Select
                    value={formData.current_version}
                    defaultValue={formData.current_version}
                    onChange={handleInputChange}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      borderRadius: "10px",
                      width: {
                        xs: "100px",
                        sm: "300px",
                        md: "400px",
                        lg: "300px",
                        xl: "400px"
                    },
                      height: '40px'
                    }}
                    name="current_version"

                  >
                    <MenuItem value={"Select Current Version"}>Select Current Version</MenuItem>
                    <MenuItem value="Version 1.0">Version 1.0</MenuItem>
                    <MenuItem value="Version 2.0">Version 2.0</MenuItem>
                    <MenuItem value="Version 3.0">Version 3.0</MenuItem>
                    <MenuItem value="Version 4.0">Version 4.0</MenuItem>
                  </Select>
                </Box>


                <Box>
                  <InputLabel sx={inputLabel}>
                    App store URL<span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.app_store_url}
                    onChange={handleInputChange}
                    name="app_store_url"
                    disabled
                    placeholder={"Enter App Store URL"}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "20px" }} >
                <Box>
                  <InputLabel sx={inputLabel}>
                    Description <span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.desc}
                    onChange={handleInputChange}
                    name="desc"
                    placeholder={"Enter Description"}
                  />
                </Box>
                <Box>
                  <InputLabel sx={inputLabel}>
                    Play store URL<span style={{ color: "#F99494" }}>*</span>
                  </InputLabel>
                  <CustomTextField
                    value={formData.play_store_url}
                    onChange={handleInputChange}
                    disabled
                    name="play_store_url"
                    placeholder={"Enter Play Store URL"}
                  />
                </Box>
              </Box>

            </Box>
            {/* Vehicle Configuration Section */}
            <Typography
              sx={{
                color: "#283246",
                fontSize: "24px",
                fontWeight: "600",
                mt: 5,
                mb: 2,
              }}
            >
              Vehicle Configuration
            </Typography>
            <Box sx={{ width: '50%', mb: 5 }}>
              <Box>
                <InputLabel sx={inputLabel}>
                  IOT Type<span style={{ color: "#F99494" }}>*</span>
                </InputLabel>
                <Select
                  value={formData.iot_type}
                  defaultValue={formData.iot_type}
                  onChange={handleInputChange}
                  IconComponent={KeyboardArrowDownIcon}
                  sx={{
                    borderRadius: "10px",
                    width: {
                      xs: "100px",
                      sm: "300px",
                      md: "400px",
                      lg: "300px",
                      xl: "400px"
                  },
                    height: '40px'
                  }}
                  name="iot_type"

                >
                  <MenuItem value={"Select IOT Type"}>Select IOT Type</MenuItem>
                  <MenuItem value="Axons Micro">Axons Micro</MenuItem>
                  <MenuItem value="Axons Micro Pro">Axons Micro Pro</MenuItem>


                </Select>
              </Box>
            </Box>
          </Box>

          {/* Mobile Screens Section */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: { xs: 2, sm: 3 },
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            {[mobileScreen1, mobileScreen2, mobileScreen3].map((screen, index) => (
              <img
                key={index}
                src={screen}
                alt={`Mobile screen ${index + 1}`}
                style={{ width: index === 1 ? '136px' : '96px', height: index === 1 ? '276px' : '195px', borderRadius: '10px' }}
              />
            ))}
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
          <Box
            onClick={() => {
              dispath(updateSettings(formData))
            }}
          >
            <FilledButton sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>Update</FilledButton>
          </Box>
          <Box>
            {/* <FilledButton
              sx={{
                width: '150px', height: '50px', borderRadius: '12px', color: "#97A1B6", borderColor: "#97A1B6", background: "#F8F8F8",
                fontSize: "16px", fontWeight: "600",
                ":hover": {
                  color: "#97A1B6",
                  borderColor: "#97A1B6",
                  background: "white"
                },
              }}
            >
              Cancel
            </FilledButton> */}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

