export const RestrictedZoneTablesCellItems = [
    { label: "Name", key: "name" },
    { label: "Created Date", key: "created_date" },
    { label:'Created By',key:"created_by"},
    { label:"Status",key:'status' },
    { label:"Updated Date",key:'updated_date'},
]

export const RestrictedZoneTableData = [
    {
        "name":"Shyamal Restricted Area",
        "id":"1",
        "fleet":"Shymal Cross Road Public Fleet",
        "zone_type":"Restricted Zone",
        "desc":"THIS IS SHYAMAL Restricted ZONE",
        "status":"Active",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Ranip Restricted Area",
        "id":"2",
        "fleet":"Ranip Road Private Fleet",
        "zone_type":"Restricted Zone",
        "vehicle_exits_this_zone":["Alarm"],
        "desc":"THIS IS RANIP Restricted ZONE",
        "status":"Inactive",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User2",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Shivranjini Restricted Area ",
        "id":"3",
        "fleet":"Shivranjini Cross Road Public Fleet",
        "vehicle_exits_this_zone":["Throttle"],
        "zone_type":"Restricted Zone",
        "desc":"THIS IS IS Shivranjini Restricted ZONE",
        "status":"Active",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Gujarat University Restricted Area",
        "id":"4",
        "fleet":"Gujarat University Public Fleet",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "zone_type":"Restricted Zone",
        "desc":"THIS IS Gujarat University  Restricted ZONE",
        "status":"Inactive",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Nikol Restricted Area",
        "id":"5",
        "fleet":"Nikol Cross Road Public Fleet",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "zone_type":"Restricted Zone",
        "desc":"THIS IS Nikol Restricted ZONE",
        "status":"Active",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
]