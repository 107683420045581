import { Typography,Box,Paper } from "@mui/material"
import PaymentGatewayTable from "./PaymentGatewayTable/PaymentGatewayTable"


const PaymentGateway=()=>{
    return(
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>

                    <PaymentGatewayTable/>
                </Paper>
        </Box>
    )
}

export default PaymentGateway