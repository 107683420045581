import { Box } from "@mui/material";
import React from "react";
import TripsCards from "./TripsCards";
import TripsTable from "./TripsTable";


export const Trips = () => {
  return(
    <Box>
      <Box>
        <TripsCards/>
      </Box>
      <Box>
        <TripsTable/>
      </Box>
    </Box>
  )
};
