import {Paper} from "@mui/material"
import ServiceZoneTable from "./ServiceZoneTable/ServiceZoneTable"
const ServiceZone = () => {
    return (
        <> 
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>
            <ServiceZoneTable/>
        </Paper>
        </>
    )
}

export default ServiceZone