import BillingPlanTable from "./BillingPlanTabel/BillingPlanTable"

const { Typography, Box, Paper } = require("@mui/material")

const BillingPlan = () => {
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>
                <BillingPlanTable />
            </Paper>
        </Box>
    )
}

export default BillingPlan