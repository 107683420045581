import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"


const PaymentGateWayRevenurBarChart = () => {
  const series = [
    {
      name: "Monthly Data",
      data: [150,70,120,80,110,45,65,80,125,60,90,105], // Sample data for each month
    },
  ];

  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false
      },

    },
    legend: {
      show: false
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "March", "Apr", "May", "Jun",
        "July", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      labels:{
        style: {
            colors: ["#535A6A"],
            fontSize: '14px',
            fontFamily: 'Helvetica Neue',
            fontWeight: 400,
            // cssClass: 'apexcharts-yaxis-label',
        },
    }

    },
    yaxis: {
      min: 0,
      max: 250,
      tickAmount: 5,
      labels: {
        formatter: (value) => `$${value}`, // Add $ sign to y-axis labels
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
      },
      },

    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadiusApplication: "end",
        borderRadius: 5,
        columnWidth: "50%",
      },
    },
    colors: [
      "#67748F", "#F99494"
    ], // Colors for each bar
  };

  return (
    <Box
      bgcolor="#F8F8F8"
      borderRadius="25px"
    //   padding="5px"
    >
      <Box>
        <Typography 
         sx={{
          padding:"20px",
          color: "black",
          fontSize: "16px",
          fontWeight: "600",
          color:"#283246;",
          display:"flex"
        }}>Payment Gateway Using Stripe Revenue : $
        <Typography
            sx={{
                color: "#F99494",
                fontSize: "16px",
                fontWeight: "600"
            }}
        >200</Typography> 
        
        </Typography>
      </Box>
      <Box>
        <Chart
          options={options}
          series={series}
          type="bar"
          height={"450px"}
        />
      </Box>
    </Box>
  )
}

export default PaymentGateWayRevenurBarChart
