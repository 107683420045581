import React, { useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Pagination,
} from "@mui/material";
import ShowIcon from "../../assets/Icons/show.svg";

// Reusable Table Component
const TripsTableComponent = ({
  tableHeaders,
  data, 
  showActions = false, 
  handleEdit = null, 
  handleDelete = null 
}) => {

  const [page, setPage] = useState(1); // State for current page
  const itemsPerPage = 10; // Set items per page



  const filteredData = useMemo(() => {
    return data?.filter((item) => item); // Adjust the filter logic if needed
  }, [data]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };



  return (
    <>
    <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
      <Table>
        <TableHead sx={{ background: '#DDDEE1' }}>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            {tableHeaders?.map((header, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.938rem",
                }}
              >
                {header.label}
              </TableCell>
            ))}
            {showActions && <TableCell sx={{ fontWeight: 500, fontSize: "0.938rem" }}>Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredData?.length > 0 ? (
            filteredData.map((item, index) => (
              <TableRow key={item.id || index}>
                <TableCell padding="checkbox"></TableCell>
                {tableHeaders?.map((header, i) => (
                  <TableCell
                    key={i}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: 500,
                      color:"#283246"
                    }}
                  >
                    {item[header.key]}
                  </TableCell>
                ))}
                {showActions && (
                  <TableCell>
                    <Box
                       sx={{
                        display: "flex",
                         width: "auto"
                      }}
                    >
                      {handleEdit && (
                        <Box
                          onClick={() => handleEdit(item)}
                          sx={{
                            background: "#283246",
                            width: "1rem",
                            height: "1rem",
                            padding: "0.438rem",
                            borderRadius: "0.313rem",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img src={ShowIcon} alt="edit-icon" style={{ width: '25px', height: '25px' }} />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                )}
              </TableRow>

              
            ))
          ) : (
            <Typography>No data available</Typography>
          )}
        </TableBody>
      </Table>
    </TableContainer>
      {/* Pagination Component */}
      {/* <Box sx={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
  <Pagination
    count={Math.ceil(data.length / itemsPerPage)}
    page={page}
    onChange={handleChangePage}
   color='primary'
  />
</Box> */}

      </>
  );
};

export default TripsTableComponent;
