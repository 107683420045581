// theme.js
import { createTheme } from "@mui/material/styles";

// Create a custom theme
const theme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", Arial, sans-serif',
    text1: {
      fontSize: "0.75rem", // Example font size for h1
      color: "#283246",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem", // Example font size for h2
    },
    body1: {
      fontSize: "1rem", // Example font size for body text
    },
    linerGradient:"linear-gradient(90deg, #535A6A 0%, #283246 100%)"
    // Add more typography overrides as needed
  },
  color: {
    text1: "",
  },
  // You can add other customizations like palette, components, etc.
});

export default theme;
