import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatePicker.css";


const CustomDatePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {



  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  return (
    <DatePicker
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      maxDate={new Date()}
      className="custom-datepicker"
      dateFormat="MMM d, yyyy" // Use "MMM" for abbreviated month names
      customInput={<CustomInput endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />}
    />
  );
};

const CustomInput = React.forwardRef(({ value, onClick,endDate,setEndDate,setStartDate }, ref) => (
  
  <button className="custom-input" onClick={onClick} ref={ref}>
    {console.log(endDate,"this is endDAte")}
    <span className="text-color" > {value || "Select Date Range"}</span>
    {
      endDate== Date()?
      (
        <span className="calendar-icon">
      <svg
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-calendar"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_183_23603)">
          <mask id="mask0_183_23603" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="31">
            <path d="M19.2499 19.75V1.25006H0.75V19.75H19.2499Z" stroke="white" stroke-width="2.0" />
          </mask>
          <g mask="url(#mask0_183_23603)">
            <path d="M9.23173 15.8906H10.7682M13.85 15.8906H15.3864M4.62237 15.8906H6.15881M9.23173 11.2813H10.7682M13.85 11.2813H15.3864M4.62237 11.2813H6.15881M0.78125 7.42711H19.2276M14.6182 5.12243V1.2813M5.39061 5.12243V1.2813M3.86304 19.7188H16.1458C17.8478 19.7188 19.2276 18.339 19.2276 16.6369V5.89957C19.2276 4.19755 17.8478 2.81775 16.1458 2.81775H3.86304C2.16101 2.81775 0.78125 4.19755 0.78125 5.89957V16.6369C0.78125 18.339 2.16101 19.7188 3.86304 19.7188Z" stroke="#283246" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_183_23603">
            <rect width="20" height="20" transform="translate(0 0.5)" />
          </clipPath>
        </defs>
      </svg>
    </span>
      ):
      (
        <span className="calendar-icon" onClick={()=>{
          
          setEndDate(new Date())
          setStartDate(()=>{
            {
              const today = new Date();
              return new Date(today.getFullYear(), 0, 1); 
            }
          })
          }} >
        <svg
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-calendar"
          viewBox="0 0 20 15"
          xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_183_23603)">
            <mask id="mask0_183_23603" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="60">
              <path d="M19.2499 19.75V1.25006H0.75V19.75H19.2499Z" stroke="white" stroke-width="2.0" />
            </mask>
            <g mask="url(#mask0_183_23603)">
              <path d="M9.23173 15.8906H10.7682M13.85 15.8906H15.3864M4.62237 15.8906H6.15881M9.23173 11.2813H10.7682M13.85 11.2813H15.3864M4.62237 11.2813H6.15881M0.78125 7.42711H19.2276M14.6182 5.12243V1.2813M5.39061 5.12243V1.2813M3.86304 19.7188H16.1458C17.8478 19.7188 19.2276 18.339 19.2276 16.6369V5.89957C19.2276 4.19755 17.8478 2.81775 16.1458 2.81775H3.86304C2.16101 2.81775 0.78125 4.19755 0.78125 5.89957V16.6369C0.78125 18.339 2.16101 19.7188 3.86304 19.7188Z" stroke="#283246" stroke-width="70" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_183_23603">
            <rect y="1.89893" width="1.97988" height="17.8189" rx="0.989938" transform="rotate(-45 0 1.89893)" fill="#283246"/>
            <rect x="1.39844" y="14.501" width="1.97988" height="17.8189" rx="0.989938" transform="rotate(-135 1.39844 14.501)" fill="#283246"/>
            </clipPath>
          </defs>
        </svg>
      </span>
      )
    }
  </button>
));

export default CustomDatePicker;