import { Box, Typography } from "@mui/material";
import Star from "../../../../assets/Icons/Star.svg"
import Chart from "react-apexcharts"
import React, { useState, useEffect } from 'react';
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";


const TripsRatingsSemiPieChart = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const TripsRatingsData = [
        {
            type: "Five Star",
            count: 10
        },
        {
            type: "Four Star",
            count: 10
        },
        {
            type: "Three Star",
            count: 10
        },
        {
            type: "Two Star",
            count: 10
        },
        {
            type: "One Star",
            count: 10
        },
        {
            type: "Zero Star",
            count: 0
        },
    ]
    const series = [50, 50];
    const options = {
        chart: {
            type: 'donut',
        },
        labels: ['Positive Ratings', 'Nagetive Ratings'],
        plotOptions: {
            pie: {
                startAngle: -90,
                endAngle: 90,
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels
        },
        stroke: {
            show: true,
            width: 20, // Adjust the width of the border around the slices
            colors: '#F8F8F8',
            curve: 'smooth',
        },
        legend: {
            show: false,
        },
        annotations: {
            position: 'front',
            texts: [
                {
                    text: '50%',
                    x: 210,
                    y: 110,
                    fontSize: '35px',
                    fontWeight: 'bold',
                    fontColor:'#283246',
                    fontFamily:"Helvetica Neue",
                    textAnchor: 'middle',
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
                {
                    text: 'Rating Positive Star',
                    x: 205,
                    y: 130,
                    fontSize: '12px',
                    fontWeight: '300',
                    fontWeight: 'bold',
                    fontColor:'#283246',
                    fontFamily:"Helvetica Neue",
                    textAnchor: 'middle',
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
            ],
        },
        colors: ["#F99494", "#283246"]
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            bgcolor="#F8F8F8"
            borderRadius="25px">
            <Box sx={{display:'flex',alignItems:"center",justifyContent:"space-between",
            // padding:"10px"

            }} >
               <Box>
               <Typography sx={{
                    paddingInline: "15px",
                    paddingBlock: "10px",
                    color: "#283246",
                    fontSize: "16px",
                    fontWeight: "600"
                }}  >Trips Ratings</Typography>
               </Box>
               <Box>
               {/* <CustomDatePicker
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            /> */}
               </Box>
            </Box>
            <Box
                display="flex"
                alignItems="center"
                gap="10px"
                sx={{ paddingInline: "10px" }} 
>
                <Box>
                    <img src={Star} alt="" />
                </Box>
                <Box>
                    <Typography sx={{
                      
                        color: "#283246",
                        fontSize: "16px",
                        fontWeight: "500"
                    }} >50</Typography>
                </Box>
            </Box>
            <Box>
                <Typography sx={{
                    paddingInline: "15px",
                    paddingBlock: "5px",
                    color: "#97A1B6",
                    fontSize: "14px",
                    fontWeight: "600"
                }}>Ratings given in the selected time frame</Typography>
            </Box>
            <Box sx={{ height: {
                lg:"255px",
                xl:"275px"
            } }}>
                <Chart
                    type="donut"
                    series={series}
                    options={options}
                    height={"350px"}
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    textAlign:"center",
                    gap:"5px",
                    marginInline:"25px"
                    

                }}
            >
                {
                    TripsRatingsData.map((data, key) => {
                        return (
                            <Box
                                key={key}
                                sx={{
                                    // paddingInline: "9px"
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                        color: "#283246",
                                        fontSize: "16px",
                                        fontFamily:'Helvetica Neue',
                                        fontWeight: "600"
                                        }}
                                    >{data.count}</Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        sx={{
                                        color: "#97A1B6",
                                        fontSize: "14px",
                                        fontFamily:'Helvetica Neue',
                                        fontWeight: "600"
                                        }}
                                    >{data.type}</Typography>
                                </Box>

                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

// const TripsRatingsSemiPieChart=()=>

export default TripsRatingsSemiPieChart