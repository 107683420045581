import { createSlice } from "@reduxjs/toolkit";
import {FleetTablesData} from "../features/Fleet/FleetTable/utills"

const initialState={
    fleetData:FleetTablesData||[]
}

const FleetMangaeSlice=createSlice({
    name:"fleet",
    initialState,
    reducers:{
        updateFleet:(state,action)=>{
            console.log(state.fleetData,"undo")
            const updatedFleetData = action.payload; // the new fleet data to update
            const fleetIndex = state.fleetData.findIndex(fleet => fleet.fleet_id === updatedFleetData.fleet_id);
            console.log(fleetIndex,"fleet index")
            if (fleetIndex !== -1) {
                // Update the specific fleet data
                state.fleetData[fleetIndex] = {
                    ...state.fleetData[fleetIndex],
                    ...updatedFleetData
                };
            }
        },
        deleteFleet:(state,action)=>{
            const fleetId=action.payload
            console.log(fleetId,"this is delete id")
            const fleetToDelete=state.fleetData.find(fleet=>fleet.fleet_id===fleetId)

            if(fleetToDelete){
                state.fleetData=state.fleetData.filter(fleet=>fleet.fleet_id!==fleetId)
            }

        },
        createFleet:(state,action)=>{

            const NewfleetData=action.payload
            if(NewfleetData){
                state.fleetData=[...state.fleetData,NewfleetData]
            }
        }
    }
})

export const {deleteFleet,updateFleet,createFleet}=FleetMangaeSlice.actions
export default FleetMangaeSlice.reducer