import { createSlice } from "@reduxjs/toolkit";

const initialState={
    settingsData:{
        current_version:"Version 4.0",
        app_store_url:"https://apps.apple.com/us/app/Axons",
        play_store_url:"https://play.google.com/store/apps/id=com.Axons",
        iot_type:"Axons Micro",
        desc:"THIS IS DESCRIPTION FOR SEETINGS"
    }
}


const SettingsManageSlice=createSlice({
    name:"setting",
    initialState,
    reducers:{
        updateSettings:(state,action)=>{
           const updatedSettings=action.payload
            console.log(updateSettings,"thi is update")
           if(updatedSettings){
                state.settingsData=updatedSettings
           }
        }
    }
})

export const {updateSettings}=SettingsManageSlice.actions
export default SettingsManageSlice.reducer