import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Login from "./pages/Login";
import DashboardLayout from "./layouts/DashboardLayout";
import { UserManagement } from "./features/UserManagement";
import { EditUser } from "./features/UserManagement/EditUser";
import TripsDetails from "./features/Trips/TripsDetails"
import { Analytics } from "./features/Analytics";
import { Trips } from "./features/Trips";
import { MapPage } from "./features/MapPage";
import { PaymentOverview } from "./features/PaymentOverview";
import { PaymentHistory } from "./features/PaymentHistory";
import { Fleet } from "./features/Fleet";
import { Vehicles } from "./features/Vehicles";
import { Settings } from "./features/Settings";
import CreateFleet from "./features/Fleet/CreateFleet";
import EditFleet from "./features/Fleet/EditFleet";
import PaymentGateway from "./features/PaymentGateway";
import Tax from "./features/Tax";
import BillingPlan from "./features/BillingPlan";
import Transaction from "./features/Transaction";
import CreatePaymentGateway from "./features/PaymentGateway/CreatePaymentGateway/CreatePaymentGateway";
import EditPaymentGayeway from "./features/PaymentGateway/EditPaymentGateway/EditPaymentGateway";
import CreateTax from "./features/Tax/CreateTax/CreateTax";
import EditTax from "./features/Tax/EditTax/EditTax";
import EditBillingPlan from "./features/BillingPlan/EditBillingPlan/EditBillingPlan";
import CreateBillingPlan from "./features/BillingPlan/CreateBillingPlan/CreateBillingPlan";
import ParkingZone from "./features/ParkingZone";
import CreateParkingZone from "./features/ParkingZone/CreateParkingZone/CreateParking";
import EditParkingZone from "./features/ParkingZone/EditParkingZone/EditParkingZone";
import RestrictedZone from "./features/RestrictedZone"
import EditRestrictedZone  from "./features/RestrictedZone/EditRestrictedZone/EditRestrictedZone"
import CreateRestrictedZone from "./features/RestrictedZone/CreateRestrictedZone/CreateRestrictedZone"
import ServiceZone from "./features/ServiceZone";
import ServiceZoneCreate from "./features/ServiceZone/ServiceZoneCreate/ServiceZoneCreate";
import ServiceZoneEdit from "./features/ServiceZone/ServiceZoneEdit/ServiceZoneEdit";
import SlowSpeedZone from "./features/SlowSpeedZone";
import EditSlowSpeedZone from "./features/SlowSpeedZone/EditSlowSpeedZone/EditSlowSpeedZone";
import CreateSlowSpeedZone from "./features/SlowSpeedZone/CreateSlowSpeedZone/CreateSlowSpeedZone";
import AddVehicles from "./features/Vehicles/AddVehicles/AddVehicles";
import EditVehicles from "./features/Vehicles/EditVehicles/EditVehicles";


function App() {
  return (
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              {/* <Route
              path="/dashboard/*"
              element={
                <PrivateRoute>
                  <DashboardLayout />
                </PrivateRoute>
              }
            /> */}
              {/* <Route
  path="/dashboard/admin"
  element={
    <RoleBasedRoute allowedRoles={['admin']}>
      <AdminDashboard />
    </RoleBasedRoute>
  }
/> */}

              <Route path="/dashboard" element={<DashboardLayout />}>
                <Route path="analytics" element={<Analytics />} />
                <Route path="user-management" element={<UserManagement />} />
                <Route path="user-edit/:id" element={<EditUser/>}/>
                <Route path="trips" element={<Trips />} />
                <Route path="vehicles" element={<Vehicles />} />
                <Route path="vehicles/add-vehicle" element={<AddVehicles/>} />
                <Route path="vehicles/edit-vehicle/:id" element={<EditVehicles/>} />
                <Route path="map" element={<MapPage />} />
                <Route path="fleet" element={<Fleet />} />
                <Route path="payments/overview" element={<PaymentOverview />} />
                <Route path="payments/payment-gateway" element={<PaymentGateway/>} />
                <Route path="payments/create-payment-gateway" element={<CreatePaymentGateway/>} />
                <Route path="payments/edit-payment-gateway/:id" element={<EditPaymentGayeway/>} />
                <Route path="payments/tex" element={<Tax/>} />
                <Route path="payments/create-tex" element={<CreateTax/>} />
                <Route path="payments/edit-tex/:id" element={<EditTax/>} />
                <Route path="payments/billing-plan" element={<BillingPlan/>} />
                <Route path="payments/create-billing-plan" element={<CreateBillingPlan/>} />
                <Route path="payments/edit-billing-plan/:id" element={<EditBillingPlan/>} />
                <Route path="payments/transaction" element={<Transaction/>} /> 
                <Route path="payments/history" element={<PaymentHistory />} />
                <Route path="geofence/parking-zone" element={<ParkingZone/>}  />
                <Route path="geofence/create-parking-zone" element={<CreateParkingZone/>} />
                <Route path="geofence/edit-parking-zone/:id" element={<EditParkingZone/>} />
                <Route path="geofence/restricted-zone" element={<RestrictedZone/>}  />
                <Route path="geofence/create-restricted-zone" element={<CreateRestrictedZone/>}  />
                <Route path="geofence/restricted-zone/:id" element={<EditRestrictedZone/>}  />
                <Route path="geofence/service-zone" element={<ServiceZone/>}  />
                <Route path="geofence/service-zone-create" element={<ServiceZoneCreate/>}  />
                <Route path="geofence/edit-service-zone/:id" element={<ServiceZoneEdit/>}  />
                <Route path="geofence/slow-speed-zone" element={<SlowSpeedZone/>}  />
                <Route path="geofence/create-slow-speed-zone" element={<CreateSlowSpeedZone/>}  />
                <Route path="geofence/edit-slow-speed-zone/:id" element={<EditSlowSpeedZone/>}  />
                <Route path="restricted-zone" element={<RestrictedZone/>} />
                <Route path="settings" element={<Settings />} />
                <Route path="trips-details/:id" element={<TripsDetails/>}  />
                <Route path="create-fleet" element={<CreateFleet/>} />
                <Route path="edit-fleet/:id" element={<EditFleet/>} />
              </Route>

              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Router>
  );
}

export default App;
