import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import {CardContent, Typography, Avatar ,Box, Paper} from '@mui/material';
import attachment from "../../assets/Icons/attachment.svg"
import LinkTimeline from "../../assets/Icons/LinkTimeLine.svg"
export default function UserTimeline({timelineData,username}) {


  return (
    // <>
    // <Typography sx={{textAlign:'center',color:"#283246",fontSize:'1rem',fontWeight:500}}>Chris Evans</Typography>
    // <Typography sx={{textAlign:'center',color:"#283246",fontSize:'0.625rem',fontWeight:400,width:'80%',margin:'auto'}}>manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.</Typography>

    // <Timeline position="alternate">
    //   {timelineData?.map((event, index) => (
    //     <TimelineItem key={index}>
    //       <TimelineSeparator>
    //         <TimelineDot   sx={{
    //   backgroundColor: '#F99494',
    // }}/>
    //         {index !== timelineData.length - 1 && <TimelineConnector />}
    //       </TimelineSeparator>
    //       <TimelineContent>
    //         <Paper
    //           sx={{
    //             backgroundColor: "#ffffff",
    //             display: "flex",
    //             alignItems: "center",
    //             borderRadius:'1rem',
    //             padding: "10px",
    //             maxWidth: "600px",
    //             marginLeft: index % 2 === 0 ? "auto" : 0,
    //             marginRight: index % 2 !== 0 ? "auto" : 0,
    //             boxShadow:'none'
    //           }}
    //         >
    //           <Avatar
    //             sx={{ background: "#FEEEEE", marginRight: "10px" }}
    //             children={<img src={attachment} />}
    //           />
    //           <CardContent>
    //             <Typography sx={{color:"#283246",fontSize:'1rem',fontWeight:500}}>
    //               {event.title}
    //             </Typography>
    //             <Typography sx={{color:'#97A1B6',fontSize:'0.95rem',fontWeight:400}}>
    //               {event.description}
    //             </Typography>
    //           </CardContent>
    //           <Box sx={{ marginLeft: "auto", paddingLeft: "10px" }}>
    //             <Typography sx={{color:'#283246',fontSize:'rem',fontWeight:400}}>
    //               {event.date}
    //             </Typography>
    //           </Box>
    //         </Paper>
    //       </TimelineContent>
    //     </TimelineItem>
    //   ))}
    // </Timeline>
    // </>
    <Box
    sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px"
    }}
>
    <Box>
        <Typography
            sx={{
                color: "#283246",
                fontSize: "24px",
                fontWeight: "600"
            }}
        >
            {username}

        </Typography>
    </Box>
    <Box>
        <Typography
            sx={{
                fontSize: "18px",
                color: "#28324680",
                textAlign: "center",
                paddingInline: "150px",
                fontWeight: "500"
            }}
        >
            This is timeline for user activity here you can see activity that done by parking user create and update with time.
            </Typography>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
        <Timeline position="alternate" >
            {
                timelineData.map((activity, key) => {
                    return (
                        <TimelineItem >
                            <TimelineSeparator
                                sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                            >
                                <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                <TimelineConnector sx={{ background: "#97A1B6",width:"5px" }} />
                            </TimelineSeparator>
                            <TimelineContent

                            >
                                <Box
                                    sx={{
                                        width: key % 2 !== 0 ? "135%" : "93%",
                                        background: "#ffff",
                                        display: "flex",
                                        paddingBlock: "20px",
                                        flexDirection: "row",
                                        gap: {
                                            lg: "20px",
                                            xl: "33px"
                                        },
                                        paddingInline: "20px",
                                        borderRadius: "20px"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            backgroundColor: "#F994941A",

                                            width: "fit-content",
                                            padding: {
                                                lg: "18px",
                                                xl: "20px"
                                            },
                                            borderRadius: "60px",

                                        }}

                                    >
                                        <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                    </Box>
                                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                        <Box>
                                            <Typography sx={{

                                                color: "#283246",
                                                fontSize: {
                                                    lg: "14px",
                                                    xl: "16px"
                                                },
                                                fontWeight: "600"

                                            }} >
                                                User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this User.
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography sx={{
                                                fontSize: "14px",
                                                color: '#97A1B6'

                                            }} >
                                                This User  {activity.type == "update" ? ("updated") : ("created")}
                                            </Typography>
                                        </Box>
                                    </Box>

                                    <Box>
                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                            {activity.time.split(" ")[0]}
                                        </Typography>
                                        <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                            {activity.time.split(" ")[1].replace("th,", "")}
                                        </Typography>
                                        <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                            {activity.time.split(" ")[2]}
                                        </Typography>
                                    </Box>
                                </Box>
                            </TimelineContent>
                        </TimelineItem>
                    )
                })
            }


        </Timeline>
    </Box>
</Box>
  );
}
