import { Box, Typography, Paper, Grid, InputLabel, Select, MenuItem, FormControl } from "@mui/material"
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useState } from "react"
import IOSSwitch from "../../../components/Switch/IOSswitch"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import { MainMap } from "../../../components/MainMap/MainMap"
import getCurrentTimeFormatted from "../../../utils/TimeFormat"
import { useDispatch } from "react-redux"
import { createServiceZone } from "../../../slices/servicezoneSlice"
import { useNavigate } from "react-router-dom"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const ServiceZoneCreate = () => {

    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        id: Math.floor(Math.random() * (1000000 - 10 + 1)) + 10,
        name: "",
        zone_type: "Service Zone",
        fleet: "Select Fleet",
        desc: "",
        vehicle_exits_this_zone: [],
        status: "Active",
        lat: "",
        lng: "",
        created_date: getCurrentTimeFormatted(),
        updated_date: getCurrentTimeFormatted(),
        created_by: "User1",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]

    })
    console.log(formData, "this is form")
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleSwitchChange = (zoneType) => {
        setFormData(prevState => {
            const updatedExitZones = prevState.vehicle_exits_this_zone.includes(zoneType)
                ? prevState.vehicle_exits_this_zone.filter(zone => zone !== zoneType) // remove if exists
                : [...prevState.vehicle_exits_this_zone, zoneType]; // add if doesn't exist

            return {
                ...prevState,
                vehicle_exits_this_zone: updatedExitZones
            };
        });
    };
    return (
        <>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>
                <Box>
                    <Typography
                        sx={{
                            color: "#283246",
                            fontSize: "24px",
                            fontWeight: "800"
                        }}
                    >Add Service Zone</Typography>
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder={"Enter Service Zone Name"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <FormControl sx={{ marginTop: "25px" }} disabled >
                                <InputLabel sx={{ fontSize: "0.8rem", fontWeight: 700, mb: 1, color: "#283246", margin: "-13px" }}>
                                    Zone Type  <span style={{ color: "#F99494" }}>*</span>
                                </InputLabel>
                                <Select

                                    value={formData.zone_type}
                                    defaultValue={formData.zone_type}
                                    onChange={handleInputChange}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={{
                                        borderRadius: "10px",
                                        width: {
                                            xs: "100px",
                                            sm: "300px",
                                            md: "400px",
                                            lg: "300px",
                                            xl: "400px"
                                        },
                                        height: '40px'
                                    }}
                                    name="zone_type"


                                >
                                    <MenuItem value={"Select Zone Type"}>Select Zone Type</MenuItem>
                                    <MenuItem value="Parking Zone">Parking Zone</MenuItem>
                                    <MenuItem value="Restricted Zone">Restricted Zone</MenuItem>
                                    <MenuItem value="Service Zone">Service Zone</MenuItem>
                                    <MenuItem value="Slow Speed Zone">Slow Speed Zone</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>

                            <InputLabel sx={inputLabel}>
                                Fleet  <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <Select
                                value={formData.fleet}
                                defaultValue={formData.fleet}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px'
                                }}
                                name="fleet"

                            >
                                <MenuItem value={"Select Fleet"}>Select Fleet</MenuItem>
                                <MenuItem value={"Shymal Cross Road Public Fleet"}>Shymal Cross Road Public Fleet</MenuItem>
                                <MenuItem value={"Ranip Road Private Fleet"}>Ranip Road Private Fleet</MenuItem>
                                <MenuItem value={"Shivranjini Cross Road Public Fleet"}>Shivranjini Cross Road Public Fleet</MenuItem>
                                <MenuItem value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                                <MenuItem value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel sx={inputLabel}>
                                Description <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.desc}
                                onChange={handleInputChange}
                                name="desc"
                                placeholder={"Enter Description of Service Zone"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} mt={2} >
                            <InputLabel sx={inputLabel}>
                                When a vehicle exits this zone, the following will be activated
                            </InputLabel>
                            <Box sx={{ display: "flex", gap: "15px" }} >
                                <Box sx={{ display: "flex", gap: "10px" }} >
                                    <IOSSwitch onChange={() => handleSwitchChange("Throttle")} />
                                    <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Throttle</Typography>
                                </Box>
                                <Box sx={{ display: "flex", gap: "10px" }} >
                                    <IOSSwitch onChange={() => handleSwitchChange("Alarm")} />
                                    <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Alarm</Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <MainMap largeHeight={"400px"} extraLargeHeight={"450px"} />
                </Box>
                <Box sx={{ marginBlock: "20px" }} >
                    <Box sx={{ display: "flex", gap: "10px" }} >

                        <Box onClick={() => {
                            dispatch(createServiceZone(formData))
                            navigate("/dashboard/geofence/service-zone")
                        }} >
                            <FilledButton sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                Create
                            </FilledButton>
                        </Box>
                        <Box
                            onClick={
                                () => {
                                    navigate("/dashboard/geofence/service-zone")
                                }
                            }
                        >
                            <OutLinedButton sx={{
                                width: '150px', height: '50px', borderRadius: '12px', color: "#97A1B6", borderColor: "#97A1B6", background: "#F8F8F8",
                                fontSize: "16px", fontWeight: "600",
                                ":hover": {
                                    color: "#97A1B6",
                                    borderColor: "#97A1B6",
                                    background: "white"
                                },
                            }}  >
                                Cancel
                            </OutLinedButton>
                        </Box>
                    </Box>
                </Box>
            </Paper>

        </>
    )
}

export default ServiceZoneCreate
