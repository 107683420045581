import service from "../assets/Icons/ServiceMapFlagIcon.svg"
import batteryflagicon from "../assets/Icons/BatteryMapFlagIcon.svg"
import maintenanceicon from "../assets/Icons/MaintaniceFlag.svg"
import iotflagicon from "../assets/Icons/IOTMapFLagIcon.svg"
import unavailableflag from "../assets/Icons/UnAvliableMapFlagIcon.svg"
import ebikeflagicon from "../assets/Icons/EbikeMapFlagIcon.svg"
import motorbikeflagicon from "../assets/Icons/MotorBikeMapFlagIcon.svg"
import scooterflagicon from "../assets/Icons/ScooterMapFlagIcon.svg"
import tractorflagicon from "../assets/Icons/TractorMapFlagIcon.svg"
import busflagicon from "../assets/Icons/BusMapFlagIcon.svg"
import heavyvehicleflagicon from "../assets/Icons/HeavyVehicleMapFlagIcon.svg"
import parkingflag from "../assets/Icons/ParkingMapFlagIcon.svg"
import otherflag from "../assets/Icons/OtherMapFlagIcon.svg"
import missingflagicon from "../assets/Icons/SearchMapFlagIcon.svg"
import carflagicon from "../assets/Icons/CarMapFlagIcon.svg"

export const flagDetails = [
    {
      icon: service,
      name: 'Service Start/End'
    },
    {
      icon: batteryflagicon,
      name: 'Battery Critical'
    },
    {
      icon: maintenanceicon,
      name: 'Maintenance'
    },
    {
      icon: iotflagicon,
      name: 'IoT Fault'
    },
    {
      icon: missingflagicon,
      name: 'Missing'
    },
    {
      icon: unavailableflag,
      name: 'Unavailable'
    },
    {
      icon: ebikeflagicon,
      name: 'E-bike'
    },
    {
      icon: motorbikeflagicon,
      name: 'Motorbike'
    },
    {
      icon: scooterflagicon,
      name: 'Scooter'
    },
    {
      icon: carflagicon,
      name: 'Car'
    },
    {
      icon: tractorflagicon,
      name: 'Tractor'
    },
    {
      icon: busflagicon,
      name: 'Bus'
    },
    {
      icon: heavyvehicleflagicon,
      name: 'Heavy Vehicle'
    },
    {
      icon: parkingflag,
      name: 'Parking'
    },
    {
      icon: otherflag,
      name: 'Other'
    }
  ];