import { createSlice } from "@reduxjs/toolkit";
import {RestrictedZoneTableData} from "../features/RestrictedZone/utills"

const initialState={
    restrictedzoneData:RestrictedZoneTableData||[]
}

const RestrictedZoneSlice=createSlice({
    name:"restrictedzone",
    initialState,
    reducers:{
        createRestrictedZone:(state,action)=>{
            const NewRestrictedZoneData=action.payload
            if(NewRestrictedZoneData){
                state.restrictedzoneData=[...state.restrictedzoneData,NewRestrictedZoneData]
            }
        },
        deleteRestrictedZone:(state,action)=>{
            const restrictedZoneId=action.payload
            console.log(restrictedZoneId,"this is delete id")
            const RestrictedZoneToDelete=state.restrictedzoneData.find(restrictedzone=>restrictedzone.id===restrictedZoneId)

            if(RestrictedZoneToDelete){
                state.restrictedzoneData=state.restrictedzoneData.filter(restrictedzone=>restrictedzone.id!==restrictedZoneId)
            }
        },
        updateRestrictedZone:(state,action)=>{
            console.log(state.restrictedzoneData,"undo")
            const updatedRestrictedZoneData = action.payload; // the new fleet data to update
            const RestrictedZoneIndex = state.restrictedzoneData.findIndex(restrictedzone => restrictedzone.id === updatedRestrictedZoneData.id);
            console.log(RestrictedZoneIndex,"fleet index")
            if (RestrictedZoneIndex !== -1) {
                // Update the specific fleet data
                state.restrictedzoneData[RestrictedZoneIndex] = {
                    ...state.restrictedzoneData[RestrictedZoneIndex],
                    ...updatedRestrictedZoneData
                };
            }
        }   
    }
})

export const{createRestrictedZone,deleteRestrictedZone,updateRestrictedZone}=RestrictedZoneSlice.actions
export default RestrictedZoneSlice.reducer