import { Box, Paper, MenuItem, FormControl, Select, Typography } from "@mui/material"
import SearchTextField from "../../../components/SearchTextField"
import FleetSelectIocn from "../../../assets/Icons/FleetSelectIocn.svg"
import StatusSelectIocn from "../../../assets/Icons/StatusSelectIcon.svg"
import SelectTypeIocn from "../../../assets/Icons/TypeSelectIocn.svg"
import { useState } from "react"
import CustomDatePicker from "../../Trips/TripsTable/CustomDatePicker"
import UserTableComponent from "../../UserManagement/UserTable"
import {TransactionsTablesCellItems,TransactionsTablesData} from "../utills"
import TransactionModal from "../TransactionModal/TransactionModal"
import { MenuItemStyle } from "../../../styles/MenuItemStyle"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { formatFilterDate } from "../../../utils/TimeFormat"


const TransactionTable = () => {
    const [searchInput, setSearchInput] = useState("");
    const [completedFleetOptions, setCompletedFleetOptions] = useState("All")
    const [openTransactionModal,setOpenTransactionModal]=useState(false)
    const [selectedTransactionData,setSelectedTransactionData]=useState("")
    const [status, setStatus] = useState("All")
    const [type, setType] = useState("All")
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        return new Date(today.getFullYear(), 0, 1); 
      });
    const [endDate, setEndDate] = useState(new Date());
    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };
    const handleCompletedFleetChange = (e) => {
        setCompletedFleetOptions(e.target.value)
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const handleTypeChange = (e) => {
        setType(e.target.value)
    }
    const filterTransactions = TransactionsTablesData.filter((transactions) => {
        const matchesSearchInput = searchInput ? transactions.user_id.includes(searchInput) : true;
        const matchesFleetOption = completedFleetOptions !== "All" ? transactions.fleet === completedFleetOptions : true;
        const matchesStatus = status !== "All" ? transactions.status === status : true;
        const matchType=type!=="All"?transactions.type===type:true;
        const Filterdate=formatFilterDate(transactions.date)

        if(endDate==null){
            return matchesSearchInput && matchesFleetOption && matchesStatus && matchType
        }
        else{
            const filterStartDate=new Date(startDate)
            const filterEndDate=new Date(endDate)
            const isWithinDateRange = (!filterStartDate || Filterdate >= filterStartDate) && (!filterEndDate || Filterdate <= filterEndDate);
          
            return matchesSearchInput && matchesFleetOption && matchesStatus && matchType && isWithinDateRange
        }
      
        
      });
      

      const handleTransaction = (item)=>{
        setSelectedTransactionData(item)
        setOpenTransactionModal(true)
        
      }
     
    return (
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                minHeight:"75vh",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>

            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography
                        sx={{ display: "flex", gap: "10px", fontSize:"24px",fontWeight:"600",color:"#283246" }}
                    >Transactions
                    </Typography>
                </Box>

            </Box>
            <Box sx=
                {{
                    height: '42px',
                    boxShadow: "none",
                    display:"flex",
                    justifyContent:"end",
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px', 
                    p: 1, 
                    borderRadius: '6px', 
                    mb: 2,
                    display:"flex"

                }}>

                <Box sx={{ display: "flex", gap: "20px", flex: 1 }} >

                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={FleetSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={completedFleetOptions}
                                    label="Fleet"
                                    onChange={(e) => handleCompletedFleetChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}

                                >
                                    <MenuItem  sx={MenuItemStyle} value={"All"}>Fleet</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"Shyamal Road Public Fleet"}>Shyamal Road Public Fleet</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"Iskcon temple Public Fleet"}>Iskcon temple Public Fleet</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"New Ranip Private Fleet"}>New Ranip Private Fleet</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={SelectTypeIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    label="Fleet"
                                    onChange={(e) => handleTypeChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}

                                >
                                    <MenuItem sx={MenuItemStyle} value={"All"}>Type</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Wallet"}>Wallet</MenuItem>
                                    <MenuItem sx={MenuItemStyle} value={"Trip Charge"}>Trip Charge</MenuItem>

                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx=
                        {{
                            display: "flex",
                            alignItems: "center",
                            width: "fit-content",
                            gap: "10px",
                            background: "white",
                            padding: "5px",
                            borderRadius: "8px",
                            border: "1px solid #DDDEE1",
                        }} >
                        <Box>
                            <img src={StatusSelectIocn} />
                        </Box>
                        <Box
                            sx={{
                                width: "fit-content"
                            }}
                        >
                            <FormControl variant="standard" >

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={status}
                                    onChange={(e) => handleStatusChange(e)}
                                    disableUnderline={true}
                                    IconComponent={KeyboardArrowDownIcon}
                                    sx={MenuItemStyle}
                                >
                                    <MenuItem  sx={MenuItemStyle} value={"All"}>Status</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"Paid"}>Paid</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"Unpaid"}>Unpaid</MenuItem>
                                    <MenuItem  sx={MenuItemStyle} value={"Refund"}>Refund</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>

                    </Box>
                    <Box>
                        <CustomDatePicker
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </Box>
                </Box>
                <Box>
                    <SearchTextField
                        value={searchInput}
                        onChange={handleSearchChange}
                    />



                </Box>
            </Box>

            <Box>
                    <UserTableComponent
                     data={filterTransactions}
                     tableHeaders={TransactionsTablesCellItems}
                     showActions={true}
                     handleActions={handleTransaction}
                    />
            </Box>

            <TransactionModal 
            openTransactionModal={openTransactionModal} 
            setOpenTransactionModal={setOpenTransactionModal}
            selectedTransactionData={selectedTransactionData}
            
            />
        </Paper>
    )
}

export default TransactionTable