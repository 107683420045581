import { createSlice } from "@reduxjs/toolkit";
import {ServiceZoneTableData} from "../features/ServiceZone/utills"

const initialState={
    servicezoneData:ServiceZoneTableData||[]
}


const ServiceZoneManageSlice=createSlice({
    name:"servicezone",
    initialState,
    reducers:{
        updateServiceZone:(state,action)=>{
            console.log(state.servicezoneData)
            const updatedServiceZoneData = action.payload; // the new fleet data to update
            const ServiceZoneIndex = state.servicezoneData.findIndex(serviceZone => serviceZone.id === updatedServiceZoneData.id);
            console.log(ServiceZoneIndex,"ServiceZoneIndex index")
            if (ServiceZoneIndex !== -1) {
                // Update the specific fleet data
                state.servicezoneData[ServiceZoneIndex] = {
                    ...state.servicezoneData[ServiceZoneIndex],
                    ...updatedServiceZoneData
                };
            }
        },
        createServiceZone:(state,action)=>{
            const NewServiceZoneData=action.payload
            if(NewServiceZoneData){
                state.servicezoneData=[...state.servicezoneData,NewServiceZoneData]
            }
        },
        deleteServiceZone:(state,action)=>{
            const ServiceZoneId=action.payload
            console.log(ServiceZoneId,"this is delete id")
            const ServiceZoneToDelete=state.servicezoneData.find(serviceZone=>serviceZone.id===ServiceZoneId)

            if(ServiceZoneToDelete){
                state.servicezoneData=state.servicezoneData.filter(serviceZone=>serviceZone.id!==ServiceZoneId)
            }
        }
    }
})

export const {createServiceZone,deleteServiceZone,updateServiceZone}=ServiceZoneManageSlice.actions
export default ServiceZoneManageSlice.reducer