import { Box, Paper, Tab, Tabs, Typography } from "@mui/material"
import { useState } from "react";
import Timeline from '@mui/lab/Timeline';
import TimelineItem,{ timelineItemClasses }  from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import RideEndIcon from "../../../assets/Icons/RideEndIcon.svg"
import RidePauseIcon from "../../../assets/Icons/RidePauseIcon.svg"
import RideStartIcon from "../../../assets/Icons/RideStartIcon.svg"
import RidePaymentIcon from "../../../assets/Icons/PaymentDoneIcon.svg"
import RideStartingIcon from "../../../assets/Icons/RideStartingIcon.svg"
import RideEndingIcon from "../../../assets/Icons/RideEndingIcon.svg"
import RideRatingIcon from "../../../assets/Icons/RideRatingIcon.svg"
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import RideEndModal from "./RideEndModal";


const tabStyles = {
    width: '191px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};

const TripsTimeline = ({ vehicle_id, user_id, date,ratings,trip_id,activity }) => {
    const [value, setValue] = useState(0)
    const[rideEndLocationModal,setRideEndLocationModal]=useState(false)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(activity,"this is activity value")
    return (
        <Box>
            <RideEndModal rideEndLocationModal={rideEndLocationModal} setRideEndLocationModal={setRideEndLocationModal}   />
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}
            >
                <Box
                    sx={{
                        marginBlock: "12px",

                    }}
                >
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Timeline Tabs"
                        sx={{
                            bgcolor: '#F8F8F8',
                            width: "fit-content",
                            borderRadius: '12px',
                            border: '1px solid #DDDEE1',
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                    >
                        {['Vehicle Timeline', 'Rider Timeline'].map((label, index) => (
                            <Tab
                                key={label}
                                disableRipple
                                label={label}
                                sx={tabStyles}
                            />
                        ))}
                    </Tabs>
                </Box>

                <Box

                >
                    {
                        value==0?(<>
                               <Box  
                               
                               >
                        <Timeline position="right"
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                  flex: 0,
                                  padding: 0,
                                },
                              }}
                        >
                            <TimelineItem   >
                                <TimelineSeparator >
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent sx={{":hover":{cursor:"pointer"}}} onClick={()=>setRideEndLocationModal(true)} >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Box>
                                            <img src={RideEndIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:'flex',gap:{
                                                lg:"5px",
                                                xl:"10px"
                                            }}} >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        fontWeight: "600",
                                                        color: "#283246",
                                                        
                                                    }}
                                                >
                                                    User ID 
                                                </Typography>  
                                                <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id} 
                                                </Typography> 
                                                    
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        fontWeight: "600",
                                                        color: "#283246",
                                                        display: "flex",
                                                        gap: "12px"
                                                    }}
                                                >
                                                    ended ride on vehicle 
                                                </Typography>
                                                <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    {vehicle_id}
                                                </Typography>
                                               
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    This Ride has been ended.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","") }
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                   
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"

                                        }}
                                    >
                                        <Box>
                                            <img src={RidePauseIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1
                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{lg:"5px",xl:"10px"}}} >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        fontWeight: "600",
                                                        color: "#283246",
                                                     
                                                       
                                                    }}
                                                >
                                                    User ID </Typography>
                                                     <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}</Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                        fontWeight: "600",
                                                        color: "#283246",
                                                        }}
                                                    >
                                                    Pause ride on vehicle 
                                                    </Typography>
                                                    <Typography

                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }} >
                                                        {vehicle_id}
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    This Ride has been stop.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","") }
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"

                                        }}
                                    >
                                        <Box>
                                            <img src={RideStartIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{lg:"5px",xl:"10px"}}} >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    User ID</Typography> 
                                                     <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}</Typography>  
                                                    <Typography
                                                          sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            color: "#283246",  
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    started ride on vehicle  
                                                    </Typography>
                                                    <Typography

                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }} >
                                                        {vehicle_id}
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    This Ride has been started.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Box>
                                            <img src={RidePaymentIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{
                                                lg:"5px",xl:"10px"
                                            }}} >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    User ID
                                                    </Typography> 
                                                    
                                                     <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}</Typography> 
                                                    
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            color: "#283246",
                                                            display: "flex",
                                                            gap: "8px",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    Checked ride payments
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                   Payment Successfully Done.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                              </Box>
                        </>):(<></>)
                        
                    }
                    {
                        value==1?(<>
                             <Box  >
                        <Timeline 
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                  flex: 0,
                                  padding: 0,
                                },
                              }}
                        >
                            <TimelineItem   >
                                <TimelineSeparator >
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent sx={{":hover":{cursor:"pointer"}}} onClick={()=>setRideEndLocationModal(true)} >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Box>
                                            <img src={RideRatingIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{
                                                lg:"5px",xl:"10px"
                                            }}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    Rider ID 
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id} 
                                                    </Typography>
                                                     <Typography
                                                         sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            color: "#283246",
                                                            fontWeight: "600"
                                                        }}
                                                     >
                                                     gave rating on ride:
                                                     </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}>
                                                            {ratings}
                                                        </Typography>
                                               
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    Rider liked the ride.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px",

                                        }}
                                    >
                                        <Box>
                                            <img src={RideEndingIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{
                                                lg:"4px",xl:"10px"
                                            }}}>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    Rider ID 
                                                    </Typography>
                                                     <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}
                                                    </Typography>
                                                   <Typography
                                                     sx={{
                                                        color: "#283246",
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        fontWeight: "600"
                                                    }}
                                                   >
                                                   Ended ride
                                                   </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{trip_id}
                                                    </Typography>
                                                    <Typography 
                                                         sx={{
                                                            color: "#283246",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    on vehicle 
                                                    </Typography>
                                                    <Typography

                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }} >
                                                        {vehicle_id}
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    Ride has been ended by rider.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6",
                                                    textAlign:"center"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Box>
                                            <img src={RideStartingIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "5px",
                                                flex:1

                                            }}
                                        >
                                            <Box sx={{display:"flex",gap:{
                                                lg:"3px",xl:"10px"
                                            }}} >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    Rider ID  
                                                </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            color: "#283246",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    started ride
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{trip_id}</Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            color: "#283246",
                                                            fontWeight: "600"
                                                        }}
                                                    >
                                                    on vehicle 
                                                    </Typography>
                                                    <Typography

                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }} >
                                                        {vehicle_id}
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "500",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    Ride started successfully. 
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                                <TimelineSeparator>
                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                    <TimelineConnector sx={{ background: "#97A1B6" }} />
                                </TimelineSeparator>
                                <TimelineContent  >

                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: "20px",
                                            background: "#ffff",
                                            padding: "8px",
                                            borderRadius: "10px"
                                        }}
                                    >
                                        <Box>
                                            <img src={RidePaymentIcon} />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flex:1,
                                                flexDirection: "column",
                                                gap: "5px",

                                            }}
                                        >
                                            <Box
                                                sx={{display:"flex",gap:{
                                                    lg:"5px",xl:"10px"
                                                }}} 
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        fontWeight: "600"
                                                    }}
                                                >
                                                    Rider ID</Typography>
                                                  <Typography
                                                        sx={{
                                                            color: "#F99494",
                                                            fontSize: {
                                                                lg:"12px",
                                                                xl:"16px"
                                                            },
                                                            fontWeight: "600"
                                                        }}
                                                    >{user_id}
                                                    </Typography> 
                                                    <Typography
                                                     sx={{
                                                        fontSize: {
                                                            lg:"12px",
                                                            xl:"16px"
                                                        },
                                                        color: "#283246",
                                                        fontWeight: "600"
                                                    }}>
                                                    Checked ride payments
                                                    </Typography>
                                                
                                            </Box>
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            lg:"10px",
                                                            xl:"14px"
                                                        },
                                                        fontWeight: "400",
                                                        color: "#97A1B6"
                                                    }}
                                                >
                                                    Ride successfully validated
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[0]}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"12px",
                                                        xl:"16px"
                                                    },
                                                    fontWeight: "600",
                                                    color: "#283246"
                                                }}
                                            >
                                                {date.split(" ")[1].replace("th,", "").replace("st,","")}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: {
                                                        lg:"10px",
                                                        xl:"14px"
                                                    },
                                                    fontWeight: "500",
                                                    color: "#97A1B6"
                                                }}
                                            >
                                                {date.split(" ")[2]}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </TimelineContent>
                            </TimelineItem>
                        </Timeline>
                            </Box>
                        </>):(<></>)
                    }
                    
                    
                </Box>
            </Paper>
        </Box>
    )
}

export default TripsTimeline