export const SlowZoneTablesCellItems = [
    { label: "Name", key: "name" },
    { label: "Created Date", key: "created_date" },
    { label:'Created By',key:"created_by"},
    { label:"Status",key:'status' },
    { label:"Updated Date",key:'updated_date'},
]

export const SlowZoneTableData = [
    {
        "name":"Shyamal Slow Speed Area",
        "id":"1",
        "fleet":"Shymal Cross Road Public Fleet",
        "zone_type":"Slow Speed Zone",
        "desc":"THIS IS SHYAMAL Slow Speed ZONE",
        "status":"Active",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "speed_mode":"Low",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Ranip Slow Speed Area",
        "id":"2",
        "fleet":"Ranip Road Private Fleet",
        "zone_type":"Slow Speed Zone",
        "speed_mode":"Medium",
        "vehicle_exits_this_zone":["Alarm"],
        "desc":"THIS IS RANIP Slow Speed ZONE",
        "status":"Inactive",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User2",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Shivranjini Slow Speed Area ",
        "id":"3",
        "speed_mode":"High",
        "fleet":"Shivranjini Cross Road Public Fleet",
        "vehicle_exits_this_zone":["Throttle"],
        "zone_type":"Slow Speed Zone",
        "desc":"THIS IS IS Shivranjini Slow Speed ZONE",
        "status":"Active",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Gujarat University Slow Speed Area",
        "id":"4",
        "fleet":"Gujarat University Public Fleet",
        "speed_mode":"Low",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "zone_type":"Slow Speed Zone",
        "desc":"THIS IS Gujarat University  Slow Speed ZONE",
        "status":"Inactive",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
    {
        "name":"Nikol Slow Speed Area",
        "id":"5",
        "fleet":"Nikol Cross Road Public Fleet",
        "speed_mode":"High",
        "vehicle_exits_this_zone":["Throttle","Alarm"],
        "zone_type":"Slow Speed Zone",
        "desc":"THIS IS Nikol Slow Speed ZONE",
        "status":"Active",
        "lat":"",
        "lng":"",
        "created_date":"Oct 2th, 2024 18:33:45",
        "updated_date":"Oct 5th, 2024 18:33:45",
        "created_by":"User1",
        "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    },
]