import { Avatar, Box, Card, IconButton, Paper, Typography, useTheme,Rating } from "@mui/material";
import CostIocn from "../../../assets/Icons/CostIcon.svg"
import DurationIcon from "../../../assets/Icons/DurationIcon.svg"
import PauseDurationIcon from "../../../assets/Icons/PauseDurationIcon.svg"
import DistanceIcon from "../../../assets/Icons/DistanceIcon.svg"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
const TripCards = ({ cost, duration, pause_duration, distance,trip_id,ratings }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    display: "flex",
                    flexDirection:"column",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}
            >
                <Box 
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:"10px"
                    }}
                >
                    <Box>
                        <IconButton 
                            onClick={()=>navigate(-1)}
                        >
                            <KeyboardBackspaceIcon/>
                        </IconButton>
                    </Box>
                    <Box>
                        <Typography sx={{
                            fontSize:"24px",
                            color:"#283246",
                            fontWeight:"700"
                        }} >
                            Trips Id:{trip_id}
                        </Typography>
                    </Box>
                    <Box>
                     <Rating value={ratings} sx={{color:"#F99494"}} size="small" readOnly/>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:"40px"
                    }}
                >
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 1,
                            }}
                        >
                            <img src={CostIocn} height="60px" />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: "#283246",
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Cost
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {cost}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 1,
                            }}
                        >
                            <img src={DurationIcon} height="60px" />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: "#283246",
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Duration
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {duration}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 1,
                            }}
                        >
                            <img src={PauseDurationIcon} height="60px" />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: "#283246",
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Pause Duration
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {pause_duration}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                mb: 1,
                            }}
                        >
                            <img src={DistanceIcon} height="60px" />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: "#283246",
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Distance
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {distance}
                        </Typography>
                    </Card>
                </Box>
            </Paper>
        </Box>
    )
}

export default TripCards