import totalusers from "../../assets/Icons/totalusers.svg";
import activeuser from "../../assets/Icons/activeuser.svg";
import tripuser from "../../assets/Icons/tripuser.svg";
import wallet from "../../assets/Icons/wallet.svg";
import totalspent from "../../assets/Icons/totalspent.svg";
import totaltrips from "../../assets/Icons/totaltrips.svg";
import tripduration from "../../assets/Icons/tripduration.svg";
import distancetravelled from "../../assets/Icons/distancetravelled.svg";
import TotalTripsUpdatedIcon from "../../assets/Icons/TotalTripsUpdatedIcon.svg"
import AverageTripsDurationIcon from "../../assets/Icons/AvrageTripsDurationIco.svg"

import deactivateduser from "../../assets/Icons/deactivateduser.svg";

export const userData = [
  { name: "Total users", count: "100", icon: totalusers },
  { name: "Active users", count: "20", icon: activeuser },
  { name: "Active on trips users", count: "30", icon: tripuser },
  { name: "Deactivated users", count: "50", icon: deactivateduser },
];

export const userDataEdit = [
  { name: "Wallet", count: "$120.00", icon: wallet },
  { name: "Total Spent", count: "$80.00", icon: totalspent },
  { name: "Total Trips", count: "12", icon: TotalTripsUpdatedIcon },
  { name: "Avg Trips Duration", count: "06 min", icon: AverageTripsDurationIcon },
  { name: "Distance Travelled", count: "1.50 km", icon: distancetravelled },

];


export const activeTableCellItem = [{label:"Name",key:"user_name"},{label:"Phone",key:"phone"},{label:"Email",key:"email"},{label:"Wallet Balance",key:"wallet_balance"},{label:"Register Date",key:"register_date"}];
export const deactivateTableCellItem = [{label:"Name",key:"user_name"},{label:"Phone",key:"phone"},{label:"Email",key:"email"},{label:"Deactivated Date",key:"deactivated_date"},{label:"Deactivated By",key:"deactivated_by"}]
export const deletedTableCellItem = [{label:"Name",key:"user_name"},{label:"Phone",key:"phone"},{label:"Email",key:"email"},{label:"Deleted Date",key:"deleted_date"},{label:"Deleted By",key:"deleted_by"}]

export const tripDetailsTableCellItem = [{label:"Vehicle",key:"vehicle"},{label:"Duration",key:"duration"},{label:"Pause Duration",key:"pause_duration"},{label:"Start Date",key:"start_date"},{label:"End Date",key:"end_date"}]
export const paymentTableCellItem = [{label:"Amount",key:"amount"},{label:"Fleet",key:"fleet"},{label:"Type",key:"type"},{label:"Status",key:"status"},{label:"Date",key:"date"}]



export const activeUserDataTable = [
  {
    email: "activeUser1@axonslab.io",
    phone: "+91 9123456789",
    id: "usr_Xyz1234abcDEF1",
    user_name: "ActiveUser1",
    wallet_balance: "$150",
    register_date: "Jan 3rd, 2024 10:25:00",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    fleet:"Shyamal Fleet",
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  
  },
  {
    email: "activeUser2@axonslab.io",
    phone: "+91 9123456790",
    id: "usr_Xyz1234abcDEF2",
    user_name: "ActiveUser2",
    wallet_balance: "$200",
    register_date: "Feb 14th, 2024 12:45:00",
    fleet:"Shyamal Road Fleet",
    status:"active",
    
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser3@axonslab.io",
    phone: "+91 9123456791",
    id: "usr_Xyz1234abcDEF3",
    user_name: "ActiveUser3",
    wallet_balance: "$75",
    register_date: "Mar 22nd, 2024 15:30:00",
    fleet:"Iskcon temple Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
  },
  {
    email: "activeUser4@axonslab.io",
    phone: "+91 9123456792",
    id: "usr_Xyz1234abcDEF4",
    user_name: "ActiveUser4",
    wallet_balance: "$350",
    register_date: "Apr 9th, 2024 08:20:00",
    fleet:"Shivranjani Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser5@axonslab.io",
    phone: "+91 9123456793",
    id: "usr_Xyz1234abcDEF5",
    user_name: "ActiveUser5",
    wallet_balance: "$120",
    register_date: "Jul 12th, 2024 17:19:09",
    fleet:"Shivranjani Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser6@axonslab.io",
    phone: "+91 9123456794",
    id: "usr_Xyz1234abcDEF6",
    user_name: "ActiveUser6",
    wallet_balance: "$220",
    register_date: "Aug 15th, 2024 09:15:00",
    fleet:"Iskcon temple Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser7@axonslab.io",
    phone: "+91 9123456795",
    id: "usr_Xyz1234abcDEF7",
    user_name: "ActiveUser7",
    wallet_balance: "$45",
    register_date: "Sep 23rd, 2024 13:00:00",
    fleet:"Iskcon temple Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser8@axonslab.io",
    phone: "+91 9123456796",
    id: "usr_Xyz1234abcDEF8",
    user_name: "ActiveUser8",
    wallet_balance: "$500",
    register_date: "Oct 30th, 2024 16:45:00",
    fleet:"New Ranip Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser9@axonslab.io",
    phone: "+91 9123456797",
    id: "usr_Xyz1234abcDEF9",
    user_name: "ActiveUser9",
    wallet_balance: "$170",
    register_date: "Nov 5th, 2024 19:00:00",
    fleet:"New Ranip Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
  {
    email: "activeUser10@axonslab.io",
    phone: "+91 9123456798",
    id: "usr_Xyz1234abcDEF10",
    user_name: "ActiveUser10",
    wallet_balance: "$60",
    register_date: "Dec 21st, 2024 11:30:00",
    fleet:"Iskcon temple Fleet",
    status:"active",
    avliable_fleet:["Shymal fleet","Ranip fleet"],
    last_active:"Today",
    trip_details: [
      { id: "trip_1", vehicle: "car 12", duration: "00:45:12", pause_duration: "02:15:35", start_date: "Aug 8th, 2024 09:25:10", end_date: "Aug 8th, 2024 12:50:45" },
      { id: "trip_2", vehicle: "bike 25", duration: "01:30:45", pause_duration: "00:45:15", start_date: "Jul 22nd, 2024 08:00:00", end_date: "Jul 22nd, 2024 09:35:45" },
      { id: "trip_3", vehicle: "truck 09", duration: "02:10:05", pause_duration: "01:00:30", start_date: "Jun 14th, 2024 15:10:00", end_date: "Jun 14th, 2024 17:20:05" },
      { id: "trip_4", vehicle: "scooter 88", duration: "00:55:20", pause_duration: "00:25:45", start_date: "Sep 5th, 2024 10:40:05", end_date: "Sep 5th, 2024 11:35:25" },
      { id: "trip_5", vehicle: "van 07", duration: "03:25:50", pause_duration: "01:10:10", start_date: "May 30th, 2024 13:15:00", end_date: "May 30th, 2024 16:40:50" }
    ],
    payment_details: [
      { id: "payment_1", amount: "$120.00", fleet: "Shyamal Road Public Fleet", type: "Trip Charge", status: "Paid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_2", amount: "$140.00", fleet: "Shyamal Road Public Fleet", type: "Wallet", status: "Unpaid", date: "Jul 16th, 2024 17:19:09" },
      { id: "payment_3", amount: "$200.00", fleet: "Iskcon temple Public Fleet", type: "Trip Charge", status: "Refunded", date: "Jul 16th, 2024 17:19:09" }
    ],
    "activity": [
      {
          user: "9",
          userRole: "Admin",
          type: "update",
          time: "Oct 5th, 2024 18:33:45"
      },
      {
          user: "8",
          userRole: "Admin",
          type: "update",
          time: "Oct 4th, 2024 18:33:45"
      },
      {
          user: "7",
          userRole: "Admin",
          type: "update",
          time: "Oct 3th, 2024 18:33:45"
      },
      {
          user: "1",
          userRole: "Super Admin",
          type: "create",
          time: "Oct 2th, 2024 18:33:45"
      },
  ]
  },
];

export const deactivatedUserDataTable = [
  {
    email: "deactivatedUser1@axonslab.io",
    phone: "+91 8123456789",
    id: "usr_Xyz1234abcDEF11",
    user_name: "DeactivatedUser1",
    deactivated_by: "Admin1",
    deactivated_date: "Jan 3rd, 2024 10:25:00",
    fleet:"Iskcon temple Fleet",
    
  },
  {
    email: "deactivatedUser2@axonslab.io",
    phone: "+91 8123456790",
    id: "usr_Xyz1234abcDEF12",
    user_name: "DeactivatedUser2",
    deactivated_by: "Admin2",
    deactivated_date: "Feb 14th, 2024 12:45:00",
    fleet:"New Ranip Fleet",
  },
  {
    email: "deactivatedUser3@axonslab.io",
    phone: "+91 8123456791",
    id: "usr_Xyz1234abcDEF13",
    user_name: "DeactivatedUser3",
    deactivated_by: "Admin1",
    deactivated_date: "Mar 22nd, 2024 15:30:00",
    fleet:"Shivranjani Fleet",
  },
  {
    email: "deactivatedUser4@axonslab.io",
    phone: "+91 8123456792",
    id: "usr_Xyz1234abcDEF14",
    user_name: "DeactivatedUser4",
    deactivated_by: "Admin2",
    deactivated_date: "Apr 9th, 2024 08:20:00",
    fleet:"Shyamal Road Fleet",
  },
  {
    email: "deactivatedUser5@axonslab.io",
    phone: "+91 8123456793",
    id: "usr_Xyz1234abcDEF15",
    user_name: "DeactivatedUser5",
    deactivated_by: "Admin1",
    deactivated_date: "Jul 12th, 2024 17:19:09",
    fleet:"Iskcon temple Fleet",
  },
  {
    email: "deactivatedUser6@axonslab.io",
    phone: "+91 8123456794",
    id: "usr_Xyz1234abcDEF16",
    user_name: "DeactivatedUser6",
    deactivated_by: "Admin1",
    deactivated_date: "Aug 15th, 2024 09:15:00",
    fleet:"Shivranjani Fleet"
  },
  {
    email: "deactivatedUser7@axonslab.io",
    phone: "+91 8123456795",
    id: "usr_Xyz1234abcDEF17",
    user_name: "DeactivatedUser7",
    deactivated_by: "Admin2",
    deactivated_date: "Sep 23rd, 2024 13:00:00",
    fleet:"New Ranip Fleet",
  },
  {
    email: "deactivatedUser8@axonslab.io",
    phone: "+91 8123456796",
    id: "usr_Xyz1234abcDEF18",
    user_name: "DeactivatedUser8",
    deactivated_by: "Admin2",
    deactivated_date: "Oct 30th, 2024 16:45:00",
    fleet:"Iskcon temple Fleet",
  },
  {
    email: "deactivatedUser9@axonslab.io",
    phone: "+91 8123456797",
    id: "usr_Xyz1234abcDEF19",
    user_name: "DeactivatedUser9",
    deactivated_by: "Admin2",
    deactivated_date: "Nov 5th, 2024 19:00:00",
    fleet:"New Ranip Fleet",
  },
  {
    email: "deactivatedUser10@axonslab.io",
    phone: "+91 8123456798",
    id: "usr_Xyz1234abcDEF20",
    user_name: "DeactivatedUser10",
    deactivated_by: "Admin1",
    deactivated_date: "Dec 21st, 2024 11:30:00",
    fleet:"Iskcon temple Fleet",
  },
];

export const deletedUserDataTable = [
  {
    email: "deletedUser1@axonslab.io",
    phone: "+91 7123456789",
    id: "usr_Xyz1234abcDEF21",
    user_name: "DeletedUser1",
    deleted_by: "Admin1",
    deleted_date: "Jan 3rd, 2024 10:25:00",
    fleet:"Iskcon temple Fleet",
  },
  {
    email: "deletedUser2@axonslab.io",
    phone: "+91 7123456790",
    id: "usr_Xyz1234abcDEF22",
    user_name: "DeletedUser2",
    deleted_by: "Admin2",
    deleted_date: "Feb 14th, 2024 12:45:00",
    fleet:"New Ranip Fleet",
  },
  {
    email: "deletedUser3@axonslab.io",
    phone: "+91 7123456791",
    id: "usr_Xyz1234abcDEF23",
    user_name: "DeletedUser3",
    deleted_by: "Admin1",
    deleted_date: "Mar 22nd, 2024 15:30:00",
    fleet:"Shivranjani Fleet"
  },
  {
    email: "deletedUser4@axonslab.io",
    phone: "+91 7123456792",
    id: "usr_Xyz1234abcDEF24",
    user_name: "DeletedUser4",
    deleted_by: "Admin2",
    deleted_date: "Apr 9th, 2024 08:20:00",
    fleet:"Iskcon temple Fleet",
  },
  {
    email: "deletedUser5@axonslab.io",
    phone: "+91 7123456793",
    id: "usr_Xyz1234abcDEF25",
    user_name: "DeletedUser5",
    deleted_by: "Admin1",
    deleted_date: "Jul 12th, 2024 17:19:09",
    fleet:"Shivranjani Fleet"
  },
];
