export const Notifications = [
    {
      type: "map",
      title: "Map Update",
      desc: "A new map update is available for your region.",
      date: "2024-06-01",
    },
    {
      type: "analytics",
      title: "Analytics Report",
      desc: "Your weekly analytics report is ready.",
      date: "2024-06-03",
    },
    {
      type: "vehicles",
      title: "Vehicle Maintenance",
      desc: "Your vehicle is due for maintenance this week.",
      date: "2024-06-05",
    },
    {
      type: "users",
      title: "New User Signup",
      desc: "A new user has signed up for your service.",
      date: "2024-06-07",
    },
    {
      type: "trips",
      title: "Trip Summary",
      desc: "Here is the summary of your recent trip.",
      date: "2024-06-09",
    },
    {
      type: "fleet",
      title: "Fleet Status Update",
      desc: "Your fleet has been successfully updated.",
      date: "2024-06-11",
    },
    {
      type: "geofence",
      title: "Geofence Alert",
      desc: "A vehicle has crossed the geofence boundary.",
      date: "2024-06-13",
    },
    {
      type: "payment",
      title: "Payment Successful",
      desc: "Your recent payment has been successfully processed.",
      date: "2024-06-15",
    },
    {
      type: "settings",
      title: "Settings Updated",
      desc: "Your account settings have been updated.",
      date: "2024-06-17",
    },
    {
      type: "analytics",
      title: "Analytics Alert",
      desc: "Anomaly detected in analytics data.",
      date: "2024-06-19",
    },
    {
      type: "vehicles",
      title: "Vehicle Location Update",
      desc: "Your vehicle has been located at the new destination.",
      date: "2024-06-21",
    },
    {
      type: "users",
      title: "User Profile Updated",
      desc: "A user has updated their profile information.",
      date: "2024-06-23",
    },
    {
      type: "trips",
      title: "Trip Cancelled",
      desc: "A trip scheduled for tomorrow has been cancelled.",
      date: "2024-06-25",
    },
    {
      type: "fleet",
      title: "New Fleet Added",
      desc: "A new fleet has been added to your account.",
      date: "2024-06-27",
    },
    {
      type: "geofence",
      title: "Geofence Created",
      desc: "A new geofence has been created around your area.",
      date: "2024-06-29",
    },
    {
      type: "payment",
      title: "Payment Reminder",
      desc: "Your payment for this month is due soon.",
      date: "2024-07-01",
    },
    {
      type: "settings",
      title: "Password Change Alert",
      desc: "Your password was recently changed.",
      date: "2024-07-03",
    },
    {
      type: "analytics",
      title: "New Data Insights",
      desc: "Check out the new data insights for your business.",
      date: "2024-07-05",
    },
    {
      type: "vehicles",
      title: "Vehicle Alert",
      desc: "A vehicle in your fleet requires attention.",
      date: "2024-07-07",
    },
    {
      type: "users",
      title: "User Deactivated",
      desc: "A user account has been deactivated.",
      date: "2024-07-09",
    },
    {
      type: "trips",
      title: "Trip Scheduled",
      desc: "A new trip has been scheduled for next week.",
      date: "2024-07-11",
    },
    {
      type: "fleet",
      title: "Fleet Inspection Due",
      desc: "Your fleet is due for inspection next month.",
      date: "2024-07-13",
    },
    {
      type: "geofence",
      title: "Geofence Breach",
      desc: "A vehicle breached a restricted geofence area.",
      date: "2024-07-15",
    },
    {
      type: "payment",
      title: "Payment Failed",
      desc: "Your recent payment attempt has failed.",
      date: "2024-07-17",
    },
    {
      type: "settings",
      title: "Security Settings Updated",
      desc: "Your security settings have been successfully updated.",
      date: "2024-07-19",
    },
    {
      type: "analytics",
      title: "Monthly Analytics Overview",
      desc: "Your monthly analytics overview is now available.",
      date: "2024-07-21",
    },
    {
      type: "vehicles",
      title: "New Vehicle Added",
      desc: "A new vehicle has been added to your fleet.",
      date: "2024-07-23",
    },
    {
      type: "users",
      title: "User Feedback Received",
      desc: "You have received new user feedback.",
      date: "2024-07-25",
    },
    {
      type: "trips",
      title: "Trip Rescheduled",
      desc: "A scheduled trip has been rescheduled to a new date.",
      date: "2024-07-27",
    },
    {
      type: "fleet",
      title: "Fleet Audit",
      desc: "Your fleet is scheduled for an audit next month.",
      date: "2024-07-29",
    },
    {
      type: "geofence",
      title: "Geofence Updated",
      desc: "An existing geofence has been updated.",
      date: "2024-07-31",
    },
    {
      type: "payment",
      title: "Payment Pending",
      desc: "Your payment is pending approval.",
      date: "2024-08-02",
    },
    {
      type: "settings",
      title: "Account Settings Change",
      desc: "Your account settings have been modified.",
      date: "2024-08-04",
    },
    {
      type: "analytics",
      title: "Data Analytics Update",
      desc: "New data has been added to your analytics dashboard.",
      date: "2024-08-06",
    },
    {
      type: "vehicles",
      title: "Vehicle Insurance Due",
      desc: "The insurance for your vehicle is due for renewal.",
      date: "2024-08-08",
    },
    {
      type: "users",
      title: "New User Registered",
      desc: "A new user has registered on your platform.",
      date: "2024-08-10",
    },
    {
      type: "trips",
      title: "Trip Completed",
      desc: "Your recent trip has been successfully completed.",
      date: "2024-08-12",
    },
    {
      type: "fleet",
      title: "Fleet Expansion",
      desc: "Your fleet has expanded with new vehicles.",
      date: "2024-08-14",
    },
    {
      type: "geofence",
      title: "Geofence Violation",
      desc: "A violation was detected in a monitored geofence area.",
      date: "2024-08-15",
    },
    {
      type: "payment",
      title: "Payment Processed",
      desc: "Your payment has been processed successfully.",
      date: "2024-06-02",
    },
    {
      type: "settings",
      title: "Profile Update",
      desc: "Your profile has been updated with new information.",
      date: "2024-06-04",
    },
    {
      type: "analytics",
      title: "Real-Time Analytics",
      desc: "Real-time analytics are now available for your dashboard.",
      date: "2024-06-06",
    },
    {
      type: "vehicles",
      title: "Vehicle Recall",
      desc: "A recall has been issued for one of your vehicles.",
      date: "2024-06-08",
    },
    {
      type: "users",
      title: "User Role Updated",
      desc: "A user's role has been updated in the system.",
      date: "2024-06-10",
    },
    {
      type: "trips",
      title: "Trip Report",
      desc: "Your trip report for last month is available.",
      date: "2024-06-12",
    },
    {
      type: "fleet",
      title: "Fleet Maintenance Reminder",
      desc: "Don't forget to schedule your fleet's maintenance.",
      date: "2024-06-14",
    },
    {
      type: "geofence",
      title: "Geofence Notification",
      desc: "A geofence has been triggered by a passing vehicle.",
      date: "2024-06-16",
    },
    {
      type: "payment",
      title: "Payment Confirmation",
      desc: "Your recent payment has been confirmed.",
      date: "2024-06-18",
    },
    {
      type: "settings",
      title: "Two-Factor Authentication Enabled",
      desc: "Two-factor authentication has been enabled for your account.",
      date: "2024-06-20",
    }
  ];
  