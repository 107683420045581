import { createSlice } from "@reduxjs/toolkit";
import {PaymentBillingPlanTablesData} from "../features/BillingPlan/utills"

const initialState={
    billingPlanData:PaymentBillingPlanTablesData|| []
}


const BillingPlanManageSlice=createSlice({
    name:"billingplan",
    initialState,
    reducers:{
        updateBillingPlan:(state,action)=>{
            console.log(state.billingPlanData,"undo")
            const updatedBillingPlanData = action.payload; // the new fleet data to update
            const billingPlanIndex = state.billingPlanData.findIndex(billingPlan => billingPlan.uuid === updatedBillingPlanData.uuid);
            console.log(billingPlanIndex,"billing plan index")
            if (billingPlanIndex !== -1) {
                state.billingPlanData[billingPlanIndex] = {
                    ...state.billingPlanData[billingPlanIndex],
                    ...updatedBillingPlanData
                };
            }
        },
        createBillingPlan:(state,action)=>{
            const NewBillingPlanData=action.payload
            if(NewBillingPlanData){
                state.billingPlanData=[...state.billingPlanData,NewBillingPlanData]
            }
        },
        deleteBillingPlan:(state,action)=>{
            const DeleteBillingPlanId=action.payload
            console.log(DeleteBillingPlanId,"this is delete id")
            const BillingPlanToDelete=state.billingPlanData.find(billingPlan=>billingPlan.uuid===DeleteBillingPlanId)

            if(BillingPlanToDelete){
                state.billingPlanData=state.billingPlanData.filter(billingPlan=>billingPlan.uuid!==DeleteBillingPlanId)
            }

        }
    }
})

export const {createBillingPlan,updateBillingPlan,deleteBillingPlan}=BillingPlanManageSlice.actions
export default BillingPlanManageSlice.reducer