import React from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";


const TripDetails = ({ open, handleClose, TripData }) => {
  return (
  

    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"


    >
      <Box sx={{ backgroundColor: "#F8F8F8", position: "absolute", top: "20vh", left: "40vw", width: "30vw", height: "60vh", padding: "20px", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px" }} >
        <Box sx={{ display: "flex", alignItems: "center" }} >
          <Box sx={{ flex: 1 }} >
            <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }} >Trip Details</Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose} sx={{fontSize:"24px",fontWeight:"600",color:"#283246"
            }} >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px", marginInline: "20px",
          border: "1px solid #DDDEE1",
          padding: "20px",
          borderRadius: "20px"
        }} >
          <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Vehicle</Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }}> {TripData.vehicle}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }}>Trip id</Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{TripData.id}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Start Date  </Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{TripData.start_date}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >End Date</Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{TripData.end_date}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", paddingBlock: "8px", borderBottom: "1px solid #DDDEE1" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Trip Duration</Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{TripData.duration}</Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", paddingBlock: "8px" }} >
            <Box sx={{ flex: 1 }} >
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Pause Duration</Typography>
            </Box>
            <Box>
              <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{TripData.pause_duration}</Typography>
            </Box>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
};

export default TripDetails;
