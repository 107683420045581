import React from "react";
import { Box } from "@mui/material";
import FleetTable from "./FleetTable";
export const Fleet = () => {
  return(
    <Box
      sx={{
        bgcolor:"#F8F8F8",
        // height:"80vh",
        // width:"80vw",
        borderRadius:"20px",
       
      }}
    >
      <FleetTable/>
    </Box>
  )
};
