import TotalTripsColorIocn from "../../../assets/Icons/TotalTripsColorIocn.svg"
import TripsCardColorIcon from "../../../assets/Icons/TripsPerVehicleUpdatedIcon.svg"
import TripDurationColorIcon from "../../../assets/Icons/AvrageTripsDurationIco.svg"
import TripCost from "../../../assets/Icons/AvrageTripCost.svg"
import TotalTripsUpdatedIcon from "../../../assets/Icons/TotalTripsUpdatedIcon.svg"
import { Avatar, Box, Card, Paper,Typography, useTheme } from "@mui/material";
const TripsCards = () => {
    const theme = useTheme();
    const tripData = [
        {
            name: "Total Trips",
            icon: TotalTripsUpdatedIcon,
            number: "100"
        },
        {
            name: "Trips Per Vehcile",
            icon: TripsCardColorIcon,
            number: "50"
        },
        {
            name: "Avg Trips Duration",
            icon: TripDurationColorIcon,
            number: "$50"
        },
        {
            name: "Avg Trips Cost",
            icon: TripCost,
            number: "$50"
        }
    ]
    return (
        <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          display: "flex",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
        {tripData.map((data) => (
          <Card
            sx={{
              width: "20%",
              borderRadius: "1rem",
              padding: "1rem 3.438rem",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center", // Center content horizontally
              boxShadow: "none",
            }}
          >
            <Box
              sx={{
                borderRadius: "50%",
                width: "52px",
                height: "52px",
                background: "#97A1B61A",
                display: "flex",
                alignItems: "center", 
                justifyContent: "center",
                mb: 1,
              }}
            >
              <Avatar src={data.icon} />
            </Box>

            <Typography
              sx={{
                fontSize: "16px",
                color: theme.typography.text1.color,
                fontWeight: 500,
                marginBottom: "0.5rem"}}
            >
              {data.name}
            </Typography>
            <Typography
              sx={{
                fontSize: "1.375rem",
                color: theme.typography.text1.color,
                fontWeight: 700,
              }}
            >
              {data.number}
            </Typography>
          </Card>
        ))}
      </Paper>
    )
}

export default TripsCards