import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  TablePagination,
  Button,
  Rating,
  Checkbox
} from "@mui/material";
import EditIcon from "../../assets/Icons/editicon.svg";
import DeleteIcon from "../../assets/Icons/delete.svg";
import unlock from "../../assets/Icons/unlock.svg";
import lock from "../../assets/Icons/lock.svg";
import ActionIcon from "../../assets/Icons/ActionHandleIcon.svg"
import { useNavigate, useParams } from "react-router-dom";
import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';
import { useDispatch } from "react-redux";
import service from "../../assets/Icons/ServiceStartEndFlag.svg"
import mapicon from "../../assets/Icons/mapicon.svg"
import { updateSelectedVehicle } from "../../slices/vehicletableselectedValueSlice";




// Reusable Table Component
const UserTableComponent = ({
  tableHeaders,
  data,
  showActions = false,
  handleActions = null,
  handleEdit = null,
  handleDelete = null,
  handleLocationClick = null,
  handleLockClick = null,
  handleFlagClick = null,
  actionTripsNavigation = false,
  actionFleetNavigation = false,
  paymentGatewayNavigation = false,
  taxNavigation = false,
  fleetNavigation = false,
  fleetLocation = false,
  billingPlanNavigation = false,
  parkingZoneNavigation = false,
  userNavigation = false,
  restrictedZoneNavigation = false,
  serviceZoneNavigation = false,
  slowSpeedZoneNavigation = false,
  vehicleNavigation = false,
  showCheckBox = false
}) => {




  const filteredData = useMemo(() => {
    return data?.filter((item) => item); // Adjust the filter logic if needed
  }, [data]);



  const [selectedItems, setSelectedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const paginatedData = useMemo(() => {
    const startIndex = currentPage * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage, rowsPerPage]);

  // Handle page change
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset to first page when rows per page changes
  };
  const dispatch = useDispatch()


  useEffect(() => {
    dispatch(updateSelectedVehicle(selectedItems))
  }, [selectedItems])

  const handleSelectItem = (item) => {
    setSelectedItems((prev) =>
      prev.includes(item.id)
        ? prev.filter((id) => id !== item.id)
        : [...prev, item.id]
    );
  };




  const renderCustomContent = (header, item) => {
    switch (header.label) {

      case "Rating":
        return (
          <Box>
            <Rating value={item.ratings} sx={{ color: "#F99494" }} size="small" readOnly />
          </Box>
        )
      case "Flags":
        return (
          <Box
            // onClick={() => handleFlagClick(item.id)} 
            sx={{ cursor: "pointer" }}>
            <img src={service} alt="flag-icon" />
          </Box>
        );
      case "Billing Plan":
        if (item.billing_plan.includes("Private")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.billing_plan}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "12px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }
        else {
          return (
            <Chip
              label={item.billing_plan}
              variant="outlined"
              icon={<CircleIcon color="#83C3A0" />}
              size="small"
              sx={{
                color: "#83C3A0",
                background: "#F1F7F3",
                borderColor: "#83C3A0",
                fontSize: "12px",
                fontWeight: "600"
              }}
            />
          )
        }
      case "Status":
        if (item.status.includes("Inactive")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "12px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }

        if (item.status.includes("Force Stop")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "14px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }

        if (item.status.includes("Automatically ended")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "12px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }

        if (item.status.includes("Grace Period")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "14px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }
        if (item.status.includes("Paid")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#83C3A0" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#83C3A0",
                    background: "#F1F7F3",
                    borderColor: "#83C3A0",
                    fontSize: "14px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }
        if (item.status.includes("Unpaid")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#F99494",
                    background: "#FFF3F3",
                    borderColor: "#F99494",
                    fontSize: "14px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }
        if (item.status.includes("Refund")) {
          return (
            <Box>

              <Box>
                <Chip
                  label={item.status}
                  variant="outlined"
                  size="small"
                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                  sx={{
                    color: "#C496FF",
                    background: "#FFF3F3",
                    borderColor: "#C496FF",
                    fontSize: "14px",
                    fontWeight: "600"
                  }}
                />
              </Box>
            </Box>
          )
        }
        if ("Active") {
          return (
            <Chip
              label={item.status}
              variant="outlined"
              icon={<CircleIcon color="#83C3A0" />}
              size="small"
              sx={{
                color: "#83C3A0",
                background: "#F1F7F3",
                borderColor: "#83C3A0",
                fontSize: "12px",
                fontWeight: "600"
              }}
            />
          )
        }
      case "Location":
        if (fleetLocation) {
          return (
            <Box >
              <Typography> {item.location} </Typography>
            </Box>
          )
        }
        else {
          return (
            <Box sx={{ display: 'flex', }} >
              <Box
                onClick={() => { handleLocationClick(item.id) }}
                sx={{ cursor: "pointer", borderRadius: '10px', p: 1, width: '30px', height: '30px', background: "#97A1B6" }}>
                <img src={mapicon} alt="location-icon" style={{ width: '30px', height: '30px' }} />
              </Box>
            </Box>
          )
        }
      case "Vehicle Action":
        return (
          <Button
            // startIcon={<img src={item.vehicle_action === "Unlock" ? unlock : lock}  />} 
            onClick={() => handleLockClick(item.id)}
            sx={{ width: '90px', borderRadius: '10px', border: '1px solid #F99494', }}

          >
            <Box sx={{ display: "flex", gap: "5px", justifyContent: "center", alignItems: "center" }} >


              <Box sx={{ marginTop: "3px" }} >
                <img src={item.vehicle_action === "Unlock" ? unlock : lock} />
              </Box>
              <Box>
                <Typography sx={{ fontSize: '16px', color: "#283246", textTransform: 'none', fontFamily: '"Helvetica Neue", Arial, sans-serif', fontWeight: 500 }} >{item.vehicle_action}</Typography>
              </Box>
            </Box>
          </Button>
        );
      case "Battery":
        return (
          <Box sx={{ display: 'flex', }}>
            <Box
              sx={{
                border: '2px solid #00C853',
                backgroundColor: '#E8F5E9',
                padding: '0.25rem 0.5rem',
                borderRadius: '0.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                color: '#263238',
                fontWeight: 'bold',
                fontSize: '0.875rem',
                width: '50px'
              }}
            >
              {item[header.key]}%
              {/* Battery terminal */}
              <Box
                sx={{
                  border: '2px solid #00C853',
                  backgroundColor: '#E8F5E9',
                  height: '15px',
                  width: '6px',
                  position: 'absolute',
                  right: '-6px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  borderTopRightRadius: '4px',
                  borderBottomRightRadius: '4px',
                }}
              />
            </Box>
          </Box>

        );
      default:
        return item[header.key];
    }
  };
  const navigate = useNavigate()
  return (
    <>
      <TableContainer component={Paper} sx={{ boxShadow: 'none' }}>
        <Table>
          <TableHead sx={{
            background: '#DDDEE1', border: '1px solid #DDDEE1',
            background: '#F1F1F2',
          }}>
            <TableRow>
              {showCheckBox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={
                      selectedItems.length === filteredData.length &&
                      filteredData.length > 0
                    }
                    sx={{
                      color: '#283246', // Border color when unchecked
                      '&.Mui-checked': {
                        color: '#F99494', // Fill color when checked
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedItems(filteredData.map((item) => item.id));
                      } else {
                        setSelectedItems([]);
                      }
                    }}
                  />
                </TableCell>
              )}
              {tableHeaders?.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    color: "#283246B2",
                    fontWeight: 600,
                    fontSize: "14px",
                    paddingInline: "10px",
                    paddingBlock: "10px",
                    // textAlign: "center"
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
              {showActions && <TableCell sx={{
                color: "#283246B2",
                fontWeight: 600,
                fontSize: "14px",
                paddingInline: "10px",
                paddingBlock: "10px",
                // textAlign: "center"
              }}>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody sx={{
            border: '1px solid #DDDEE1',

            // background: '#F1F1F2'
          }} >
            {paginatedData?.length > 0 ? (
              paginatedData.map((item, index) => (
                <TableRow key={item.id || index} sx={{}} >
                  {showCheckBox && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        sx={{
                          color: '#283246', // Border color when unchecked
                          '&.Mui-checked': {
                            color: '#F99494', // Fill color when checked
                          }
                        }}
                        checked={selectedItems.includes(item.id)}
                        onChange={() => handleSelectItem(item)}
                      />
                    </TableCell>
                  )}
                  {tableHeaders?.map((header, i) => (
                    <TableCell
                      key={i}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        color: "#283246",
                        // textAlign: "center"
                        // background:"green"
                      }}
                    >
                      {renderCustomContent(header, item)}
                    </TableCell>
                  ))}


                  {showActions && (
                    <TableCell
                      sx={{

                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width: "auto",
                          // justifyContent: "center"
                        }}
                      >
                        {handleEdit && (
                          <Box
                            onClick={() => {
                              // if (!actionFleetNavigation) {
                              //   handleEdit(item.id)
                              // }
                              if (paymentGatewayNavigation) {
                                navigate(`/dashboard/payments/edit-payment-gateway/${item.uuid}`, { state: { data: item } })
                              }
                              if (taxNavigation) {
                                navigate(`/dashboard/payments/edit-tex/${item.uuid}`, { state: { data: item } })
                              }
                              if (billingPlanNavigation) {
                                navigate(`/dashboard/payments/edit-billing-plan/${item.uuid}`, { state: { data: item } })
                              }
                              if (userNavigation) {
                                navigate(`/dashboard/user-edit/${item.id}`, { state: { data: item } })
                              }
                              if (parkingZoneNavigation) {
                                navigate(`/dashboard/geofence/edit-parking-zone/${item.id}`, { state: { data: item } })
                              }
                              if (serviceZoneNavigation) {
                                navigate(`/dashboard/geofence/edit-service-zone/${item.id}`, { state: { data: item } })
                              }
                              if (slowSpeedZoneNavigation) {
                                navigate(`/dashboard/geofence/edit-slow-speed-zone/${item.id}`, { state: { data: item } })
                              }
                              if (restrictedZoneNavigation) {
                                navigate(`/dashboard/geofence/restricted-zone/${item.id}`, { state: { data: item } })
                              }
                              if (vehicleNavigation) {
                                navigate(`/dashboard/vehicles/edit-vehicle/${item.id}`, { state: { data: item } })
                              }
                              if (fleetNavigation) {
                                navigate(`/dashboard/edit-fleet/${item.fleet_id}`, { state: { data: item } })
                              }

                            }}
                            sx={{
                              background: "#283246",
                              width: "1.875rem",
                              height: "2.063rem",
                              padding: "0.438rem",
                              borderRadius: "12px",
                              cursor: "pointer",
                              marginRight: "0.5rem",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={EditIcon} alt="edit-icon" style={{ width: '25px', height: '25px' }} />
                          </Box>
                        )}
                        {handleDelete && (
                          <Box
                            onClick={() => {
                              if (fleetNavigation) {
                                handleDelete(item.fleet_id)
                              }

                              else if (paymentGatewayNavigation || taxNavigation || billingPlanNavigation) {
                                handleDelete(item.uuid)
                              }
                              else {
                                handleDelete(item.id)
                              }
                            }}
                            sx={{
                              background: "#283246",
                              width: "1.875rem",
                              height: "2.063rem",
                              padding: "0.438rem",
                              borderRadius: "12px",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img src={DeleteIcon} alt="delete-icon" style={{ width: '25px', height: '25px' }} />
                          </Box>
                        )}
                        {
                          handleActions && (
                            <Box
                              onClick={() => {
                                if (actionTripsNavigation) {
                                  navigate(`/dashboard/trips-details/${item.trip_id}`, { state: { data: item } })
                                } else {
                                  handleActions(item)
                                }

                              }}
                              sx={{
                                background: "#283246",
                                width: "1.875rem",
                                height: "2.063rem",
                                padding: "0.438rem",
                                borderRadius: "0.313rem",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img src={ActionIcon} alt="delete-icon" style={{ width: '25px', height: '25px' }} />
                            </Box>
                          )
                        }
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                <Typography sx={{ textAlign: "center" }} >No data </Typography>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length} // Total number of items
        page={currentPage}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        onRowsPerPageChange={handleRowsPerPageChange}
        labelRowsPerPage="Rows per page"
        sx={{
          ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows": {
            fontSize: "14px", // Change font size
            color: "#283246", // Change font color
            fontFamily: '"Helvetica Neue", Arial, sans-serif',
          },
          ".MuiTablePagination-actions button": {
            color: "#283246", // Change color of pagination buttons (next, previous)
          },
          ".MuiTablePagination-actions .MuiButtonBase-root": {
            backgroundColor: "#283246", // Add background color to the pagination number buttons
            borderRadius: "50px", // Optional: add border-radius to make buttons rounded
            padding: "0.2rem 0.5rem", // Optional: control padding for the buttons
            color: "white", // Optional: change the text color inside the buttons
            "&:hover": {
              backgroundColor: "#3a465c", // Change background on hover
            },
            marginInline:"2px"
          }
        }}
      />
    </>
  );
};

export default UserTableComponent;
