import { Typography, Box, IconButton, Paper, Tab, Tabs, Grid, MenuItem, InputLabel, Select, FormControl, Chip } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CustomTextField from "../../../components/MainTextField/CustomTextField"
import { useState } from "react"
import { MainMap } from "../../../components/MainMap/MainMap"
import IOSSwitch from "../../../components/Switch/IOSswitch"
import FilledButton from "../../../components/MainButtons/FilledButton"
import OutLinedButton from "../../../components/MainButtons/OutLinedButton"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinkTimeline from "../../../assets/Icons/LinkTimeLine.svg"
import CircleIcon from '@mui/icons-material/Circle';
import { updateSlowSpeedZone } from "../../../slices/slowspeedzoneSlice";
import { useDispatch } from "react-redux";
import getCurrentTimeFormatted from "../../../utils/TimeFormat";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const tabStyles = {
    width: '250px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};

const EditSlowSpeedZone = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { data } = location.state || {}
    console.log("this is data", data)

    const [formData, setFormData] = useState({
        id: data.id,
        name: data.name,
        zone_type: data.zone_type,
        fleet: data.fleet,
        desc: data.desc,
        status: data.status,
        vehicle_exits_this_zone: data.vehicle_exits_this_zone,
        speed_mode: data.speed_mode,
        lat: "",
        lng: "",
        created_date: getCurrentTimeFormatted(),
        updated_date: getCurrentTimeFormatted(),
        created_by: "User1",
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    const handleSwitchChange = (zoneType) => {
        setFormData(prevState => {
            const updatedExitZones = prevState.vehicle_exits_this_zone.includes(zoneType)
                ? prevState.vehicle_exits_this_zone.filter(zone => zone !== zoneType) // remove if exists
                : [...prevState.vehicle_exits_this_zone, zoneType]; // add if doesn't exist

            return {
                ...prevState,
                vehicle_exits_this_zone: updatedExitZones
            };
        });
    };
    console.log(formData)
    return (
        <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center"
                }}
            >

                <Box>
                    <Typography
                        sx={{
                            color: "#283246",
                            fontSize: "24px",
                            fontWeight: "600"
                        }}
                    >
                        {data.name}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Active Users"
                    sx={{
                        bgcolor: '#F8F8F8',
                        width: "fit-content",
                        borderRadius: '12px',
                        border: '1px solid #DDDEE1',

                        '& .MuiTabs-indicator': {
                            display: 'none',
                        },
                    }}
                >
                    {['Restricted Zone Information ', 'TImeline'].map((label, index) => (
                        <Tab
                            key={label}
                            disableRipple
                            label={label}
                            sx={tabStyles}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box>
                {
                    value == 0 ?
                        (<>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }} >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            Name <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <CustomTextField
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            name="name"
                                            placeholder={"Enter Slow Speed Zone Name"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl sx={{ marginTop: "25px" }} disabled >
                                            <InputLabel sx={{ fontSize: "12px", fontWeight: 700, mb: 1, color: "#283246", margin: "-13px" }}>
                                                Zone Type  <span style={{ color: "#F99494" }}>*</span>
                                            </InputLabel>
                                            <Select

                                                value={formData.zone_type}
                                                defaultValue={formData.zone_type}
                                                onChange={handleInputChange}
                                                IconComponent={KeyboardArrowDownIcon}
                                                sx={{
                                                    borderRadius: "10px",
                                                    width: {
                                                        xs: "100px",
                                                        sm: "300px",
                                                        md: "400px",
                                                        lg: "300px",
                                                        xl: "400px"
                                                    },
                                                    height: '40px'
                                                }}
                                                name="zone_type"


                                            >
                                                <MenuItem value={"Select Zone Type"}>Select Zone Type</MenuItem>
                                                <MenuItem value="Parking Zone">Parking Zone</MenuItem>
                                                <MenuItem value="Restricted Zone">Restricted Zone</MenuItem>
                                                <MenuItem value="Service Zone">Service Zone</MenuItem>
                                                <MenuItem value="Slow Speed Zone">Slow Speed Zone</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>

                                        <InputLabel sx={inputLabel}>
                                            Fleet  <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <Select
                                            value={formData.fleet}
                                            defaultValue={formData.fleet}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px'
                                            }}
                                            name="fleet"

                                        >
                                            <MenuItem value={"Select Fleet"}>Select Fleet</MenuItem>
                                            <MenuItem value={"Shymal Cross Road Public Fleet"}>Shymal Cross Road Public Fleet</MenuItem>
                                            <MenuItem value={"Ranip Road Private Fleet"}>Ranip Road Private Fleet</MenuItem>
                                            <MenuItem value={"Shivranjini Cross Road Public Fleet"}>Shivranjini Cross Road Public Fleet</MenuItem>
                                            <MenuItem value={"Gujarat University Public Fleet"}>Gujarat University Public Fleet</MenuItem>
                                            <MenuItem value={"Nikol Cross Road Public Fleet"}>Nikol Cross Road Public Fleet</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            Description <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <CustomTextField
                                            value={formData.desc}
                                            onChange={handleInputChange}
                                            name="desc"

                                            placeholder={"Enter Description of Slow Speed Zone"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            Speed Mode <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <Select
                                            value={formData.speed_mode}
                                            defaultValue={formData.speed_mode}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px'
                                            }}
                                            name="speed_mode"

                                        >
                                            <MenuItem value={"Select Speed Mode"}>Select Speed Mode</MenuItem>
                                            <MenuItem value={"Low"}>Low</MenuItem>
                                            <MenuItem value={"Medium"}>Medium</MenuItem>
                                            <MenuItem value={"High"}>High</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel sx={inputLabel}>
                                            Status <span style={{ color: "#F99494" }}>*</span>
                                        </InputLabel>
                                        <Select
                                            value={formData.status}
                                            defaultValue={formData.status}
                                            onChange={handleInputChange}
                                            IconComponent={KeyboardArrowDownIcon}
                                            sx={{
                                                borderRadius: "10px",
                                                width: {
                                                    xs: "100px",
                                                    sm: "300px",
                                                    md: "400px",
                                                    lg: "300px",
                                                    xl: "400px"
                                                },
                                                height: '40px'
                                            }}
                                            name="status"

                                        >
                                            <MenuItem value={"Select Status"}>Select Status</MenuItem>
                                            <MenuItem value="Inactive">
                                                <Box>
                                                    <Chip
                                                        label="Inactive"
                                                        variant="outlined"
                                                        size="small"
                                                        icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                                        sx={{
                                                            color: "#F99494",
                                                            background: "#FFF3F3",
                                                            borderColor: "#F99494",
                                                            fontSize: "16px",
                                                            fontWeight: "600"
                                                        }}
                                                    />
                                                </Box>
                                            </MenuItem>
                                            <MenuItem value="Active">
                                                <Box>
                                                    <Chip
                                                        label="Active"
                                                        variant="outlined"
                                                        icon={<CircleIcon color="#83C3A0" />}
                                                        size="small"
                                                        sx={{
                                                            color: "#83C3A0",
                                                            background: "#F1F7F3",
                                                            borderColor: "#83C3A0",
                                                            fontSize: "16px",
                                                            fontWeight: "600"
                                                        }}
                                                    />
                                                </Box>
                                            </MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} mt={2} >
                                        <InputLabel sx={inputLabel}>
                                            When a vehicle exits this zone, the following will be activated
                                        </InputLabel>
                                        <Box sx={{ display: "flex", gap: "15px" }} >
                                            <Box sx={{ display: "flex", gap: "10px" }} >
                                                <IOSSwitch onChange={() => handleSwitchChange("Throttle")} defaultChecked={formData.vehicle_exits_this_zone.includes("Throttle")} />
                                                <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Throttle</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", gap: "10px" }} >
                                                <IOSSwitch onChange={() => handleSwitchChange("Alarm")} defaultChecked={formData.vehicle_exits_this_zone.includes("Alarm")} />
                                                <Typography sx={{ color: "#283246", fontSize: "15px", fontWeight: "700" }} >Alarm</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box>
                                    <MainMap largeHeight={"400px"} extraLargeHeight={"450px"} />
                                </Box>
                                <Box sx={{ marginBlock: "20px" }} >
                                    <Box sx={{ display: "flex", gap: "10px" }} >

                                        <Box>
                                            <FilledButton
                                                onClick={() => {
                                                    dispatch(updateSlowSpeedZone(formData))
                                                    navigate("/dashboard/geofence/slow-speed-zone")
                                                }}
                                                sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                                                Save Chnages
                                            </FilledButton>
                                        </Box>
                                        <Box>
                                            <OutLinedButton
                                                onClick={() => { navigate("/dashboard/geofence/slow-speed-zone") }}
                                                sx={{
                                                    width: '150px', height: '50px', borderRadius: '12px', color: "#97A1B6", borderColor: "#97A1B6", background: "#F8F8F8",
                                                    fontSize: "16px", fontWeight: "600",
                                                    ":hover": {
                                                        color: "#97A1B6",
                                                        borderColor: "#97A1B6",
                                                        background: "white"
                                                    },
                                                }}  >
                                                Cancel
                                            </OutLinedButton>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>) :
                        (<></>)
                }
                {
                    value == 1 ? (<Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px"
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    color: "#283246",
                                    fontSize: "24px",
                                    fontWeight: "600"
                                }}
                            >
                                {data.name}

                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: "18px",
                                    color: "#28324680",
                                    textAlign: "center",
                                    paddingInline: "150px",
                                    fontWeight: "500"
                                }}
                            >
                                This is timeline for slow speed zone here you can see activity that done with slow speed zone like create and update with time.
                            </Typography>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                            <Timeline position="alternate" >
                                {
                                    data.activity.map((activity, key) => {
                                        return (
                                            <TimelineItem >
                                                <TimelineSeparator
                                                    sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                                                >
                                                    <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                                    <TimelineConnector sx={{ background: "#97A1B6",width:"5px" }} />
                                                </TimelineSeparator>
                                                <TimelineContent

                                                >
                                                    <Box
                                                        sx={{
                                                            width: key % 2 !== 0 ? "135%" : "93%",
                                                            background: "#ffff",
                                                            display: "flex",
                                                            paddingBlock: "20px",
                                                            flexDirection: "row",
                                                            gap: {
                                                                lg: "20px",
                                                                xl: "33px"
                                                            },
                                                            paddingInline: "20px",
                                                            borderRadius: "20px"
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                backgroundColor: "#F994941A",

                                                                width: "fit-content",
                                                                padding: {
                                                                    lg: "18px",
                                                                    xl: "20px"
                                                                },
                                                                borderRadius: "60px",

                                                            }}

                                                        >
                                                            <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                                        </Box>
                                                        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                                            <Box>
                                                                <Typography sx={{

                                                                    color: "#283246",
                                                                    fontSize: {
                                                                        lg: "14px",
                                                                        xl: "16px"
                                                                    },
                                                                    fontWeight: "600"

                                                                }} >
                                                                    User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this Slow Speed Zone.
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{
                                                                    fontSize: "14px",
                                                                    color: '#97A1B6'

                                                                }} >
                                                                    This Slow Speed Zone  {activity.type == "update" ? ("updated") : ("created")}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[0]}
                                                            </Typography>
                                                            <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                                {activity.time.split(" ")[1].replace("th,", "")}
                                                            </Typography>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[2]}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </TimelineContent>
                                            </TimelineItem>
                                        )
                                    })
                                }


                            </Timeline>
                        </Box>
                    </Box>) : (<></>)
                }
            </Box>

        </Paper>
    )
}

export default EditSlowSpeedZone



