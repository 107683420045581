import React from "react";
import { TextField } from "@mui/material";
import { fontWeight, styled } from "@mui/system";


const StyledTextField = styled(TextField)(
  ({ theme, borderRadius, customStyles }) => ({
    "& .MuiOutlinedInput-root": {
      borderRadius: borderRadius || "10px", // Default border-radius
      width: "350px", // Default width
      height: "40px", // Default height
      "& fieldset": {
        borderColor: "#DDDEE1", // Default border color
      },
      "&:hover fieldset": {
        borderColor: theme.palette.grey[500], // Border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.grey[500], // Border color when focused
      },
    },

    "& .MuiInputBase-input": {
      color: "#283246", // Set input value color
      fontSize:  "14px", // Set input font size
      fontFamily: '"Helvetica Neue", Arial, sans-serif',
      fontWeight:500
    },

    "& .MuiInputBase-input::placeholder": {
      color: "#283246", // Placeholder color
      fontSize: "14px", // Placeholder font size
      fontWeight: 500, // Placeholder font weight
      fontFamily: '"Helvetica Neue", Arial, sans-serif',
    },
    "& input:-webkit-autofill": {
      backgroundColor: "transparent", // Prevent blue background on autofill
      WebkitBoxShadow: "0 0 0 100px transparent inset", // Hide autofill shadow
      
    },
    ...customStyles, // Apply any additional custom styles passed as props
  })
);

const CustomTextField = ({
  placeholder,
  borderRadius="10px",
  customStyles,
  sx,
  multiline=false,
  rows=0,
  ...props
}) => {
  return (
    <StyledTextField
      placeholder={placeholder || "Write text here"}
      variant="outlined"
      fullWidth
      borderRadius={borderRadius}
      customStyles={customStyles}
      multiline={multiline}
      
      rows={rows}
      sx={{
        "& .MuiOutlinedInput-root": {
          // Apply responsive width based on breakpoints
          width: {
            xs: "100px",   // Width for small screens
            sm: "300px",    // Width for medium screens
            md: "400px",    // Width for large screens
            lg:"300px",
            xl:"400px"
          },
        },
        ...sx, // Spread any additional sx styles
      }}
      {...props} // Pass additional props like onChange, value, etc.
    />
  );
};

export default CustomTextField;
