import { IconButton, Paper, Box, Typography, InputLabel, Select, MenuItem, Chip, Grid } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom"
import { useState } from "react";
import CustomTextField from "../../../components/MainTextField/CustomTextField";
import CircleIcon from '@mui/icons-material/Circle';
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import { MainMap } from "../../../components/MainMap/MainMap";
import { useDispatch } from "react-redux";
import { createFleet } from "../../../slices/fleetSlice";
import getCurrentTimeFormatted from "../../../utils/TimeFormat";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



const CreateFleet = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const navigate = useNavigate()
    const dispatch=useDispatch()
  
    const [formData, setFormData] = useState({
        fleet_id:Math.floor(Math.random() * (1000000 - 10 + 1)) + 10,
        fleet_name: "",
        support_email: "",
        alert_email: "",
        fleet_type: "Select Fleet Type",
        billing_plan: "Select Billing Plan",
        address: "",
        lat: "",
        lng: "",
        support_phone_number: "",
        parking_requirment: "Select Parking Requirment",
        auto_end_ride_critical_battery_vehicle: "Select Auto End Ride Application",
        distance_lock_unlock_vehicle: "Select Distance",
        auto_end_ride_ideal_vehicle: "Select Auto End Ride Requirment",
        low_battery_level: "Select Low battery level",
        pause_ride: "Select Ride Pause",
        auto_end_ride_paused_vehicle: "Select Auto End Ride Requirment",
        auto_close_vehicle: "Select Auto close vehicle",
        location:"Shyamal Cross Road",
        created_date:getCurrentTimeFormatted(),
        updatedAt: [
        {
            user:"1",
            role:"Admin",
            updatedAt:"Aug 10th, 2024 14:25:34"
        },
        {
            user:"2",
            role:"Super Admin",
            updatedAt:"Sep 5th, 2024 09:47:12"
        },
        {
            user:"3",
            role:"Admin",
            updatedAt:"Oct 2th, 2024 18:33:45"
        },
        
    ]

    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    console.log(formData, "this is data")




    return (
        <>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                    display: "flex",
                    flexDirection: 'column',
                    gap: "20px"
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  
                    <Box>
                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }}>
                            Create Fleet
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: "flex",gap:"20px" }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }} >
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Name <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.fleet_name}
                                onChange={handleInputChange}
                                name="fleet_name"
                               
                                placeholder={"Enter Fleet Name"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Support Email <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.support_email}
                                onChange={handleInputChange}
                                name="support_email"
                               
                                placeholder={"Enter Support Email"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Alert Email <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <CustomTextField
                                value={formData.alert_email}
                                onChange={handleInputChange}
                                name="alert_email"
                                
                                placeholder={"Enter Alert Email"}
                            />
                        </Box>
                        <Box>
                            <InputLabel sx={inputLabel}>
                                Fleet Type <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <Select
                                value={formData.fleet_type}
                                defaultValue={formData.fleet_type}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px'
                                }}
                                name="fleet_type"

                            >
                                <MenuItem value={"Select Fleet Type"}>Select Fleet Type</MenuItem>
                                <MenuItem value="Private">Private</MenuItem>
                                <MenuItem value="Public">Public</MenuItem>
                            </Select>
                        </Box>

                        <Box>
                            <InputLabel sx={inputLabel}>
                                Billing Plan <span style={{ color: "#F99494" }}>*</span>
                            </InputLabel>
                            <Select
                                value={formData.billing_plan}
                                defaultValue={formData.billing_plan}
                                onChange={handleInputChange}
                                IconComponent={KeyboardArrowDownIcon}
                                sx={{
                                    borderRadius: "10px",
                                    width: {
                                        xs: "100px",
                                        sm: "300px",
                                        md: "400px",
                                        lg: "300px",
                                        xl: "400px"
                                    },
                                    height: '40px'
                                }}
                                name="billing_plan"

                            >
                                <MenuItem value={"Select Billing Plan"}>Select Billing Type</MenuItem>
                                <MenuItem value="Private">
                                    <Box>
                                        <Chip
                                            label="Private Billing Plan"
                                            variant="outlined"
                                            size="small"
                                            icon={<CircleIcon color="#F99494" sx={{ fontSize: "12px" }} />}
                                            sx={{
                                                color: "#F99494",
                                                background: "#FFF3F3",
                                                borderColor: "#F99494",
                                                fontSize: "12px"
                                            }}
                                        />
                                    </Box>
                                </MenuItem>
                                <MenuItem value="Public">
                                    <Box>
                                        <Chip
                                            label="Public Billing Plan"
                                            variant="outlined"
                                            icon={<CircleIcon color="#83C3A0" />}
                                            size="small"
                                            sx={{
                                                color: "#83C3A0",
                                                background: "#F1F7F3",
                                                borderColor: "#83C3A0",
                                                fontSize: "12px"
                                            }}
                                        />
                                    </Box>
                                </MenuItem>
                            </Select>
                        </Box>
                        <Box>
                            <Box>
                                <InputLabel sx={inputLabel}>
                                    Address <span style={{ color: "#F99494" }}>*</span>
                                </InputLabel>
                                <CustomTextField
                                    value={formData.address}
                                    onChange={handleInputChange}
                                    name="address"
                                    multiline={true}
                                    rows={4}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                           
                                            height: "100px"
                                        }
                                    }}
                                    placeholder={"Enter Fleet Address"}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{flex:1}} >
                        <MainMap largeHeight={"550px"} extraLargeHeight={"550px"} />
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    gap: "20px"
                }} >
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Location Latitude  <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.lat}
                            onChange={handleInputChange}
                            name="lat"
                            
                            placeholder={"Enter Fleet Latitude"}
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Location Longitude  <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.lng}
                            onChange={handleInputChange}
                            name="lng"
                           
                            placeholder={"Enter Fleet Longitude"}
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={inputLabel}>
                            Support Phone Number <span style={{ color: "#F99494" }}>*</span>
                        </InputLabel>
                        <CustomTextField
                            value={formData.support_phone_number}
                            onChange={handleInputChange}
                            name="support_phone_number"
                            
                            placeholder={"Enter Support Phone Number"}
                        />
                    </Box>
                </Box>

            </Paper>

           

            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                    display: "flex",
                    flexDirection: 'column',
                    gap: "20px"
                }}
            >
                <Box>
                    <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }}>
                        Advance Settings
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {/* Row 1 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Parking requirement</InputLabel>
                        <Select
                            value={formData.parking_requirment}
                            defaultValue={formData.parking_requirment}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="parking_requirment"
                        >
                            <MenuItem value={"Select Parking Requirment"}>Parking requirement</MenuItem>
                            <MenuItem value="Required">Required</MenuItem>
                            <MenuItem value="Not Required">Not Required</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 2 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - idle vehicle </InputLabel>
                        <Select
                            value={formData.auto_end_ride_ideal_vehicle}
                            defaultValue={formData.auto_end_ride_ideal_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="auto_end_ride_ideal_vehicle"
                        >
                            <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                            <MenuItem value="Required">Required</MenuItem>
                            <MenuItem value="Not Required">Not Required</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 3 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - paused vehicle  <span style={{ color: "#F99494" }}>*</span></InputLabel>
                        <Select
                            value={formData.auto_end_ride_paused_vehicle}
                            defaultValue={formData.auto_end_ride_paused_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="auto_end_ride_paused_vehicle"
                        >
                            <MenuItem value={"Select Auto End Ride Requirment"}>Select Auto End Ride Requirment</MenuItem>
                            <MenuItem value="Required">Required</MenuItem>
                            <MenuItem value="Not Required">Not Required</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 4 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto end ride - critical battery vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                        <Select
                            value={formData.auto_end_ride_critical_battery_vehicle}
                            defaultValue={formData.auto_end_ride_critical_battery_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="auto_end_ride_critical_battery_vehicle"
                        >
                            <MenuItem value={"Select Auto End Ride Application"}>Select Auto End Ride Application</MenuItem>
                            <MenuItem value="Applicable">Applicable</MenuItem>
                            <MenuItem value="Not Applicable">Not Applicable</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 5 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Low battery level <span style={{ color: "#F99494" }}>*</span> </InputLabel>

                        <Select
                            value={formData.low_battery_level}
                            defaultValue={formData.low_battery_level}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="low_battery_level"
                        >
                            <MenuItem value={"Select Low battery level"}>Select Low battery level</MenuItem>
                            <MenuItem value="50">50%</MenuItem>
                            <MenuItem value="40">40%</MenuItem>
                            <MenuItem value="30">30%</MenuItem>
                            <MenuItem value="20">20%</MenuItem>
                            <MenuItem value="10">10%</MenuItem>
                            <MenuItem value="0">0%</MenuItem>
                        </Select>
                    </Grid>

                    {/* Row 6 */}
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Auto close vehicle</InputLabel>
                        <Select
                            value={formData.auto_close_vehicle}
                            defaultValue={formData.auto_close_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="auto_close_vehicle"
                        >
                            <MenuItem value={"Select Auto close vehicle"}>Select Auto close vehicle</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Distance to unlock & lock vehicle  <span style={{ color: "#F99494" }}>*</span> </InputLabel>
                        <Select
                            value={formData.distance_lock_unlock_vehicle}
                            defaultValue={formData.distance_lock_unlock_vehicle}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="distance_lock_unlock_vehicle"
                        >
                            <MenuItem value={"Select Distance"}>Select Distance</MenuItem>
                            <MenuItem value="30">30 meter</MenuItem>
                            <MenuItem value="20">20 meter</MenuItem>
                            <MenuItem value="10">10 meter</MenuItem>
                            <MenuItem value="0">0 meter</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <InputLabel sx={inputLabel}>Pause Ride</InputLabel>
                        <Select
                            value={formData.pause_ride}
                            defaultValue={formData.pause_ride}
                            onChange={handleInputChange}
                            IconComponent={KeyboardArrowDownIcon}
                            sx={{
                                borderRadius: "10px",
                                width: {
                                    xs: "100px",
                                    sm: "300px",
                                    md: "400px",
                                    lg: "300px",
                                    xl: "400px"
                                },
                                height: '40px',
                            }}
                            name="pause_ride"
                        >
                            <MenuItem value={"Select Ride Pause"}>Select Ride Pause</MenuItem>
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
                <Box sx={{display:"flex",gap:"10px"}} 
                    >

                    <Box onClick={()=>{
                    dispatch(createFleet(formData))
                    navigate("/dashboard/fleet")
                    }}  >
                        <FilledButton sx={{width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600" }}>
                            Save
                        </FilledButton>
                    </Box>
                    <Box>
                        <OutLinedButton 
                        onClick={()=>navigate("/dashboard/fleet")}
                        sx={{
                            width: '150px', height: '50px', borderRadius: '12px', color: "#97A1B6", borderColor: "#97A1B6", background: "#F8F8F8",
                            fontSize: "16px", fontWeight: "600",
                            ":hover": {
                                color: "#97A1B6",
                                borderColor: "#97A1B6",
                                background: "white"
                            },
                        }} >
                            Cancel
                        </OutLinedButton>
                    </Box>
                </Box>
            </Paper>
        </>
    )
}

export default CreateFleet