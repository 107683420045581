export const TransactionsTablesCellItems = [
    { label: "User ID", key: "user_id" },
    { label: "Amount", key: "amount" },
    { label: "Fleet", key: "fleet" },
    { label:"Type",key:"type" },
    { label:"Status",key:"status" },
    { label:"Date",key:"date" },    
]

export const TransactionsTablesData = [
    {
        "user_id":"001",
        "amount":"$120",
        "fleet":"Shyamal Road Public Fleet",
        "type":"Trip Charge",
        "status":"Paid",
        "date":"Jul 16th, 2024 17:19:09",
        "trip_id":"10",
        "User_Name":"User Name 1"
    },
    {
        "user_id":"002",
        "amount":"$150",
        "fleet":"New Ranip Private Fleet",
        "type":"Wallet",
        "status":"Unpaid",
        "date":"Jul 17th, 2024 17:19:09",
        "trip_id":"20",
        "User_Name":"User Name 2"
    },
    {
        "user_id":"003",
        "amount":"$60",
        "fleet":"Iskcon temple Public Fleet",
        "type":"Trip Charge",
        "status":"Refund",
        "date":"Jul 14th, 2024 17:19:09",
        "trip_id":"30",
        "User_Name":"User Name 3"
    },
    {
        "user_id":"004",
        "amount":"$12",
        "fleet":"Shyamal Road Public Fleet",
        "type":"Wallet",
        "status":"Paid",
        "date":"Jul 9th, 2024 17:19:09",
        "trip_id":"13",
        "User_Name":"User Name 4"
    },
    {
        "user_id":"006",
        "amount":"$16",
        "fleet":"New Ranip Private Fleet",
        "type":"Trip Charge",
        "status":"Paid",
        "date":"Jul 18th, 2024 17:19:09",
        "trip_id":"101",
        "User_Name":"User Name 5"
    },
]
    