import React from "react";
import {AnalyticsRoutes} from "./AnalyticsRoutes/index"
import {AnalyticsCards} from "./AnalyticsCards/index"
import {AnalyticsGraphs} from "./AnalyticsGraphs/index"
import { Box } from "@mui/material";

export const Analytics = () => {
  return(
    <Box sx={{display:"flex",flexDirection:"column",gap:"20px"}}>
      <Box>
          {/* <AnalyticsRoutes/> */}
      </Box>
      <Box>
        <AnalyticsCards/>
      </Box>
      <Box>
        <AnalyticsGraphs/>
      </Box>
    </Box>
  )
};
