import Chart from "react-apexcharts"
import { Box, Typography } from "@mui/material";
import CustomDatePicker from "../../../Trips/TripsTable/CustomDatePicker";
import { useState } from "react";

const VechicleConnectedPieChart = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(null)
    const ConnectedVechicleData = [
        {
            type: "Connected vehicles",
            Number: 50
        },
        {
            type: "Total vehicles",
            Number: 100
        },
        {
            type: "Not Connected vehicles",
            Number: 50
        },
    ]
    const series = [50, 50];
    const options = {
        chart: {
            type: "donut",
        },
        labels: ["Connected Vechicles", "Disconnected Vechiles"],
        colors: ["#283246", "#FFA9A9"],
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        stroke: {
            show: true,
            width: 15, // Adjust the width of the border around the slices
            colors: '#F8F8F8',
            curve: 'smooth',
        },
        annotations: {
            position: 'front',
            // images: [
            //     {
            //       src: TotalUsers, // Replace with your actual image path
            //       width: 100, // Adjust the width as needed
            //       height: 100, // Adjust the height as needed
            //       offsetX: 0,
            //       offsetY: 0,
            //       relativeTo: 'chart',
            //     },
            //   ],
            texts: [
                {
                    text: '50%', // Replace this with your desired text
                    x: 210,
                    y: 140,
                    fontSize: '30px',
                    fontWeight: 'bold',
                    fontFamily: "Helvetica Neue",
                    color: '#283246', // Text color
                    textAnchor: 'middle',
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
                {
                    text: 'Connected', // Replace this with your desired text
                    x: 210,
                    y: 180,
                    fontSize: '20px',
                    fontWeight: '800',
                    fontFamily: "Helvetica Neue",
                    color: '#283246', // Text color
                    textAnchor: 'middle',
                    backgroundColor: 'transparent',
                    borderRadius: 5,
                    padding: 10,
                },
            ],
        },

    };
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="10px"
            bgcolor="#F8F8F8"
            borderRadius="25px"

        >
            <Box>
            <Box sx={{
                display:'flex',
                alignItems:"center",
                justifyContent:"space-between",
                // /margin:"10px"
                
                }} >
                <Box>
                    <Typography sx={{
                        paddingInline: "15px",
                        paddingTop:"20px",
                        color: "#283246",
                        fontSize: "16px",
                        fontWeight: "600"
                    }} > Vehicle Connected </Typography>
                </Box>
                <Box>
                    {/* <CustomDatePicker
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    /> */}
                </Box>
            </Box>
            <Box>
                <Typography sx={{
                    paddingInline: "15px",
                    color: "#97A1B6",
                    fontSize: "14px",
                    fontWeight: "600"
                }}>Connected vehicles right now</Typography>
            </Box>
            </Box>
            <Box>
                <Chart
                    options={options}
                    series={series}
                    type="donut"
                    height={"330px"}
                />
            </Box>
            <Box
                display="flex"
                justifyContent="space-between"
            >
                {
                    ConnectedVechicleData.map((data, key) => {
                        return (
                            <Box textAlign="center" key={key} >
                                <Box>
                                    <Typography sx={{
                                        paddingInline: "15px",
                                        paddingBlock: "5px",
                                        color: "#283246",
                                        fontSize: "16px",
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: "600"
                                    }}  >{data.Number}</Typography>
                                </Box>
                                <Box>
                                    <Typography sx={{
                                        paddingInline: "15px",
                                        paddingBlock: "5px",
                                        color: "#97A1B6",
                                        fontSize: "14px",
                                        fontFamily: 'Helvetica Neue',
                                        fontWeight: "600"
                                    }} >{data.type}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default VechicleConnectedPieChart