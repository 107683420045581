import { Typography, Box, IconButton, Paper, Tab, Tabs,Grid,MenuItem,InputLabel,Select } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState } from "react";
import CustomTextField from "../../../components/MainTextField/CustomTextField";
import FilledButton from "../../../components/MainButtons/FilledButton";
import OutLinedButton from "../../../components/MainButtons/OutLinedButton";
import IOSSwitch from "../../../components/Switch/IOSswitch";
import ApplePay from "../../../assets/Icons/ApplePay.svg"
import GooglePay from "../../../assets/Icons/GooglePay.svg"
import IDealPay from "../../../assets/Icons/IdealPay.svg"
import BanContact from "../../../assets/Icons/BanContact.svg"
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LinkTimeline from "../../../assets/Icons/LinkTimeLine.svg"
import { useDispatch } from "react-redux";
import { updatePaymentGateway } from "../../../slices/paymentgatewaySlice";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const tabStyles = {
    width: '250px',
    height: '40px',
    fontWeight: 600,
    fontSize: '1rem',
    textTransform: 'none',
    color: '#F99494',
    '&.Mui-selected': {
        background: 'linear-gradient(90deg, #535A6A 0%, #283246 100%)',
        color: '#FFFFFF',
        borderRadius: '12px',
    },
};
const EditPaymentGayeway = () => {
    const inputLabel = {
        fontSize: "12px",
        fontWeight: 700,
        mb: 1,
        color: "#283246"
    }
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const { data } = location.state || {}
    console.log("this is data", data)

    const [formData, setFormData] = useState({
        uuid:data.uuid,
        name: data.name,
        currency: data.currencey,
        publish_key: data.publish_key,
        secret_key: data.secret_key,
        client_id: data.client_id,
        cvv_requirement: data.cvv_requirement,
        payment_methods: data.payment_methods,
        activity: [
            {
                user: "9",
                userRole: "Admin",
                type: "update",
                time: "Oct 5th, 2024 18:33:45"
            },
            {
                user: "8",
                userRole: "Admin",
                type: "update",
                time: "Oct 4th, 2024 18:33:45"
            },
            {
                user: "7",
                userRole: "Admin",
                type: "update",
                time: "Oct 3th, 2024 18:33:45"
            },
            {
                user: "1",
                userRole: "Super Admin",
                type: "create",
                time: "Oct 2th, 2024 18:33:45"
            },
        ]

    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    }
    console.log(formData)

    const handleSwitchChange = (index) => {
        setFormData(prevState => {
            const updatedMethods = prevState.payment_methods.map((method, i) => 
                i === index ? { ...method, support: !method.support } : method
            );
            return { ...prevState, payment_methods: updatedMethods };
        });
    };

    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    display:"flex",
                    flexDirection:"column",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center"
                    }}
                >
                   
                    <Box>
                        <Typography
                            sx={{
                                color: "#283246",
                                fontSize: "24px",
                                fontWeight: "600"
                            }}
                        >
                            {data.name}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="Active Users"
                        sx={{
                            bgcolor: '#F8F8F8',
                            width: "fit-content",
                            borderRadius: '12px',
                            border: '1px solid #DDDEE1',
                            '& .MuiTabs-indicator': {
                                display: 'none',
                            },
                        }}
                    >
                        {['Payment Information ', 'Timeline'].map((label, index) => (
                            <Tab
                                key={label}
                                disableRipple
                                label={label}
                                sx={tabStyles}
                            />
                        ))}
                    </Tabs>
                </Box>
                <Box>
                    {
                        value==0?(
                            <Box>
                            <Grid container spacing={2}>
                                {/* Row 1 */}
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Name <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        name="name"
                                        placeholder={"Enter Payment Gateway Name"}
                                    />
                                </Grid>
        
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Currency  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <Select
                                        value={formData.currency}
                                        defaultValue={formData.currency}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px'
                                        }}
                                        name="currency"
        
                                    >
                                        <MenuItem value={"Select Currencey"}>Select Currencey</MenuItem>
                                        <MenuItem value="IND">IND</MenuItem>
                                        <MenuItem value="EUR">EUR</MenuItem>
                                        <MenuItem value="USD">USD</MenuItem>
                                    </Select>
                                </Grid>
        
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Publishable Key  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.publish_key}
                                        type="password"
                                        onChange={handleInputChange}
                                        name="publish_key"
                                        placeholder={"Enter Publish Key"}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Secret Key  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.secret_key}
                                        type="password"
                                        onChange={handleInputChange}
                                        name="secret_key"
                                        placeholder={"Enter Secret Key"}
                                    />
                                </Grid>
        
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        Client ID  <span style={{ color: "#F99494" }}>*</span>
                                    </InputLabel>
                                    <CustomTextField
                                        value={formData.client_id}
                                        type="password"
                                        onChange={handleInputChange}
                                        name="client_id"
                                        placeholder={"Enter Client ID Key"}
                                    />
                                </Grid>
        
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel sx={inputLabel}>
                                        CVV required for transaction
                                    </InputLabel>
                                    <Select
                                        value={formData.cvv_requirement}
                                        defaultValue={formData.cvv_requirement}
                                        onChange={handleInputChange}
                                        IconComponent={KeyboardArrowDownIcon}
                                        sx={{
                                            borderRadius: "10px",
                                            width: {
                                                xs: "100px",
                                                sm: "300px",
                                                md: "400px",
                                                lg: "300px",
                                                xl: "400px"
                                            },
                                            height: '40px'
                                        }}
                                        name="cvv_requirement"
        
                                    >
                                        <MenuItem value={"Select CVV Requirement"}>Select CVV Requirement</MenuItem>
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </Box>
                        ):(<></>)
                    }

                    {
                        value==1?( <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px"
                            }}
                        >
                            <Box>
                                <Typography
                                    sx={{
                                        color: "#283246",
                                        fontSize: "24px",
                                        fontWeight: "600"
                                    }}
                                >
                                    {data.name}
    
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: "18px",
                                        color: "#28324680",
                                        textAlign: "center",
                                        paddingInline: "150px",
                                        fontWeight:"500"
                                    }}
                                >
                                   This is timeline for payment Gateway here you can see activity that done with payment Gateway like create and update with time.
                                </Typography>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center' }} >
                                <Timeline position="alternate" >
                                    {
                                        data.activity.map((activity, key) => {
                                            return (
                                                <TimelineItem >
                                                    <TimelineSeparator
                                                        sx={{ paddingInline: key % 2 !== 0 ? "140px" : "5px" }}
                                                    >
                                                        <TimelineDot sx={{ background: '#F99494', border: "1px solid #97A1B6" }} />
                                                        <TimelineConnector sx={{ background: "#97A1B6",width:"5px" }} />
                                                    </TimelineSeparator>
                                                    <TimelineContent
    
                                                    >
                                                      <Box
                                                        sx={{
                                                            width: key % 2 !== 0 ? "135%" : "93%",
                                                            background: "#ffff",
                                                            display: "flex",
                                                            paddingBlock: "20px",
                                                            flexDirection: "row",
                                                            gap: {
                                                                lg: "20px",
                                                                xl: "33px"
                                                            },
                                                            paddingInline: "20px",
                                                            borderRadius: "20px"
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                backgroundColor: "#F994941A",

                                                                width: "fit-content",
                                                                padding: {
                                                                    lg: "18px",
                                                                    xl: "20px"
                                                                },
                                                                borderRadius: "60px",

                                                            }}

                                                        >
                                                            <img src={LinkTimeline} height={"30px"} width={"30px"} />
                                                        </Box>
                                                        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }} >
                                                            <Box>
                                                                <Typography sx={{

                                                                    color: "#283246",
                                                                    fontSize: {
                                                                        lg: "14px",
                                                                        xl: "16px"
                                                                    },
                                                                    fontWeight: "600"

                                                                }} >
                                                                    User ID : {activity.user} ({activity.userRole}) {activity.type == "update" ? ("updated") : ("created")} this Payment Gateway.
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography sx={{
                                                                    fontSize: "14px",
                                                                    color: '#97A1B6'

                                                                }} >
                                                                    This Payment Gateway  {activity.type == "update" ? ("updated") : ("created")}
                                                                </Typography>
                                                            </Box>
                                                        </Box>

                                                        <Box>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[0]}
                                                            </Typography>
                                                            <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "600", textAlign: "center" }}>
                                                                {activity.time.split(" ")[1].replace("th,", "")}
                                                            </Typography>
                                                            <Typography sx={{ color: '#97A1B6', textAlign: "center" }} >
                                                                {activity.time.split(" ")[2]}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            )
                                        })
                                    }
    
    
                                </Timeline>
                            </Box>
                        </Box>):(<></>)
                    }
                </Box>
            </Paper>

            {
                value==0?(

                    <Paper
                    sx={{
                        borderRadius: "1.25rem",
                        background: "#F8F8F8",
                        padding: "1.563rem",
                        boxShadow: "none",
                        gap: 5,
                        display: "flex",
                        flexDirection: "column",
                        mt: 1,
                        mb: 2,
                    }}>
    
                    <Box>
                        <Typography
                            sx={{
                                color: "#283246",
                                fontSize: "24px",
                                fontWeight: "800"
                            }}
                        >Supported payment methods</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: "space-between"
                        }}
                    >
                        {
                            formData.payment_methods.map((method, key) => {
                                return (
                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "25px", bgcolor: "#FFFFFF", gap: "40px",borderRadius:"15px" }} >
                                        <Box>
                                            {method.name.includes("Apple") ? (<img src={ApplePay} />) : (<></>)}
                                            {method.name.includes("Google") ? (<img src={GooglePay} />) : (<></>)}
                                            {method.name.includes("iDEAL") ? (<img src={IDealPay} />) : (<></>)}
                                            {method.name.includes("Bancontact") ? (<img src={BanContact} />) : (<></>)}
                                        </Box>
                                        <Box>
                                            <Typography sx={{ color: "#283246", fontSize: "16px", fontWeight: "400" }} >{method.name}</Typography>
                                        </Box>
                                        <Box>
                                            <IOSSwitch onChange={()=>handleSwitchChange(key)} checked={method.support} />
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    <Box sx={{ display: "flex", gap: "10px" }} >
    
                        <Box>
                            <FilledButton 
                            onClick={()=>{
                                dispatch(updatePaymentGateway(formData))
                                navigate("/dashboard/payments/payment-gateway")
                            }}
                            sx={{ width: '150px', height: '50px', borderRadius: '10px', fontSize: "16px", fontWeight: "600"  }}>
                                Save changes
                            </FilledButton>
                        </Box>
                        <Box
                            onClick={()=>{
                                navigate("/dashboard/payments/payment-gateway")
                            }}
                        >
                            <OutLinedButton   
                            
                            sx={{
                            width: '150px', height: '50px', borderRadius: '12px', color: "#97A1B6", borderColor: "#97A1B6", background: "#F8F8F8",
                            fontSize: "16px", fontWeight: "600",
                            ":hover": {
                                color: "#97A1B6",
                                borderColor: "#97A1B6",
                                background: "white"
                            },
                        }} >
                                Cancel
                            </OutLinedButton>
                        </Box>
                    </Box>
                </Paper>
                ):(<></>)
            }
        </Box>
    )
}

export default EditPaymentGayeway