import { createSlice } from "@reduxjs/toolkit";
import {PaymentGatewayTablesData} from "../features/PaymentGateway/utils"

const initialState={
    paymentgatewayData:PaymentGatewayTablesData
}

const PaymentGateWaySlice=createSlice({
    name:"paymentgateway",
    initialState,
    reducers:{
        updatePaymentGateway:(state,action)=>{
            console.log(state.fleetData,"undo")
            const updatePaymentGatewayData = action.payload; // the new fleet data to update
            const paymentGatewayIndex = state.paymentgatewayData.findIndex(paymentgateway => paymentgateway.uuid === updatePaymentGatewayData.uuid);
            console.log(paymentGatewayIndex,"fleet index")
            if (paymentGatewayIndex !== -1) {
                // Update the specific fleet data
                state.paymentgatewayData[paymentGatewayIndex] = {
                    ...state.paymentgatewayData[paymentGatewayIndex],
                    ...updatePaymentGatewayData
                };
            }
        },
        createPaymentGateway:(state,action)=>{
            const NewPaymentGateWayData=action.payload
            if(NewPaymentGateWayData){
                state.paymentgatewayData=[...state.paymentgatewayData,NewPaymentGateWayData]
            }
        },
        deletePaymentGateway:(state,action)=>{
            const paymentGateWayId=action.payload
            console.log(paymentGateWayId,"this is delete id")
            const paymentGateWayToDelete=state.paymentgatewayData.find(paymentgateway=>paymentgateway.uuid===paymentGateWayId)

            if(paymentGateWayToDelete){
                state.paymentgatewayData=state.paymentgatewayData.filter(paymentGateway=>paymentGateway.uuid!==paymentGateWayId)
            }
        }
    }
})

export const {createPaymentGateway,deletePaymentGateway,updatePaymentGateway}=PaymentGateWaySlice.actions
export default PaymentGateWaySlice.reducer