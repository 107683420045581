import { Typography,Paper } from "@mui/material"
import ParkingZoneTable from "./ParkingZoneTable/ParkingZoneTable"


const ParkingZone = () => {
    return (
        <> <Paper
            sx={{
                borderRadius: "1.25rem",
                background: "#F8F8F8",
                padding: "1.563rem",
                boxShadow: "none",
                minHeight:"75vh",
                gap: 2,
                mt: 1,
                mb: 2,
            }}>
            <ParkingZoneTable />
        </Paper>


        </>
    )
}

export default ParkingZone