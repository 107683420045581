import { Typography,Paper,Box } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom";
import VehiclesCards from "./VehiclesCards";
import VehiclesForms from "./VehiclesForms";
import VehiclesFlags from "./VehiclesFlags";
import VehiclesQrCode from "./VehiclesQrCode";
const EditVehicles = () => {

    const location = useLocation()
    const { data } = location.state || {}
    console.log("this is data in vechicle", data)
    return (
        <Box>
           
                <VehiclesCards
                    vehicle_type={data.vehicle_type}
                    vehicle_name={data.vehicle_name}
                    vehicle_status={data.vehicle_status}
                    total_trips={data.total_trips}
                    distance_travelled={data.distance_travelled}
                    revenue_genrated={data.revenue_genrated}
                />
            
                <VehiclesForms
                    vehicle_name={data.vehicle_name}
                    battery={data.battery}
                    distance_travelled={data.distance_travelled}
                    flag={data.flag}
                    flags={data.flags}
                    fleet={data.fleet}
                    id={data.id}
                    location={data.location}
                    revenue_genrated={data.revenue_genrated}
                    total_trips={data.total_trips}
                    user_name={data.user_name}
                    vehicle_action={data.vehicle_action}
                    vehicle_qr={data.vehicle_qr}
                    vehicle_status={data.vehicle_status}
                    vehicle_type={data.vehicle_type}
                    qr_code={data.qr_code}
                    iot_type={data.iot_type}
                    iot_name={data.iot_name}
                    imei={data.imei}
                    bluetooth_flow={data.bluetooth_flow}
                    activity={data.activity}
                    trips_table_data={data.trips_table_data}
                    qr_code_link={data.qr_code_link}
                    service_start_end={data.service_start_end}
                    maintenance={data.maintenance}
                    battery_critical={data.battery_critical}
                    iot_fault={data.iot_fault}

                />
           
          
        </Box>
    )
}

export default EditVehicles