// src/slices/userManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { activeUserDataTable, deactivatedUserDataTable, deletedUserDataTable } from "../features/UserManagement/utils";

const initialState = {
  activeUser: activeUserDataTable,
  deactivatedUser:deactivatedUserDataTable,
  deletedUser:deletedUserDataTable,
};

const userManageSlice = createSlice({
  name: "userManage",
  initialState,
  reducers: {
    updatedActiveUser: (state, action) => {
      console.log(state.activeUser,"this is active user")
      const updatedActiveUser=action.payload
      const ActiveUserIndex=state.activeUser.findIndex(activeUser=>activeUser.id===updatedActiveUser.id)
      console.log(ActiveUserIndex,"ACTIVE USER UPDATE INDEX")
      if(ActiveUserIndex!==-1){
        state.activeUser[ActiveUserIndex]={
          ...state.activeUser[ActiveUserIndex],
          ...updatedActiveUser
        }
      }
    },
    updatedDeactivateUser: (state, action) => {
      state.deactivatedUser = action.payload;
    },
    updatedDeletedUser: (state, action) => {
      state.deletedUser = action.payload;
    },
    deleteActiveUser: (state, action) => {
      const userId = action.payload;
      // Find the user in the activeUser array
      const userToDelete = state.activeUser.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the activeUser array
        state.activeUser = state.activeUser.filter(user => user.id !== userId);
        
        // Add the user to the deletedUser array with static fields
        state.deletedUser.push({
          ...userToDelete,
          deleted_by: "Admin1",  // Static value
          deleted_date: "Jan 3rd, 2024 10:25:00"  // Static value
        });
      }
    },
    deleteDeactivateUser: (state, action) => {
      const userId = action.payload;
      // Find the user in the activeUser array
      const userToDelete = state.deactivatedUser.find(user => user.id === userId);

      if (userToDelete) {
        // Remove the user from the activeUser array
        state.deactivatedUser = state.deactivatedUser.filter(user => user.id !== userId);
        
        // Add the user to the deletedUser array with static fields
        state.deletedUser.push({
          ...userToDelete,
          deleted_by: "Admin1",  // Static value
          deleted_date: "Jan 3rd, 2024 10:25:00"  // Static value
        });
      }
    },
 
  },
});

export const { updatedActiveUser, updatedDeactivateUser,updatedDeletedUser,deleteActiveUser,deleteDeactivateUser} = userManageSlice.actions;
export default userManageSlice.reducer;
