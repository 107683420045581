import { Box, Grid, Typography, Paper,Divider } from "@mui/material"
import UserIcon from "../../../assets/Icons/UserNewIcon.svg"
import VehicleIcon from "../../../assets/Icons/VehicleNewIcon.svg"
import TripsIcon from "../../../assets/Icons/TripsNewIcon.svg"
import { useState } from "react"
import AnimatedNumber from "../../../components/AnimateNumbers"



export const AnalyticsCards = () => {

    
    let UserMapKey=0
    const UserCardContent = [
        { name: "Total User", number: "100" },
        { name: "Deactivated User", number: "70" },
        { name: "Active", number: "20" },
        { name: "Active On Trips", number: "50" }
    ]

    const VehiclesCardContent = [
        { name: "Total Vehicles", number: "78" },
        { name: "In Use", number: "20" },
        { name: "Total idle", number: "50" },
        { name: "Unassigned Vehicle", number: "8" }
    ]

    const TripsCardContent = [
        { name: "Total Trips", number: "300" },
        { name: "Trips Per Vehicle", number: "300" },
        { name: "Today Trips", number: "50" },
        { name: "Avg Trip Duration", number: "8" }
    ]
    
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };


    const chunkedUserContent = chunkArray(UserCardContent, 2);
    const chunkedVehiclesContent=chunkArray(VehiclesCardContent,2)
    const chunkedTripsContent=chunkArray(TripsCardContent,2)
    return (

        <Box sx={{ display: "flex", gap: "20px" }} >
            <Box sx={{ backgroundColor: "white", flex: 1, display: "flex", flexDirection: "column",borderRadius:"20px",padding:"16px" }} >
                <Box sx={{ display: "flex", alignItems: "center", }} >
                    <Box sx={{ flex: "1 1 50%" }} >
                        <img src={UserIcon} />
                    </Box>
                    <Box sx={{ flex: "1 1 50%" }}>
                        <Typography sx={{ color: "#283246", fontSize: "20px" }} >User</Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={3} >
                        {chunkedUserContent.map((chunk, index) => (
                            <Grid item  xs={6} key={index}  >
                                {chunk.map((item, subIndex) => {
                                    console.log(item.name,"this is item of user")
                                    console.log(UserMapKey,"this is key value")
                                    
                                    return (
                                        <Box key={subIndex} sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        alignItems:'center',
                                        
                                        // borderRight:UserMapKey==0?"1px solid red":"",
                                        // borderBottom:UserMapKey==0?"1px solid red":"",
                                        // borderLeft:UserMapKey==3?"1px solid red":"",
                                        // borderTop:UserMapKey==3?"1px solid red":""
                                        
                                        }} >
                                            <Box>
                                                <Typography  sx={{color:"#F99494",fontWeight:"600",fontSize:"30px",textAlign:"center"}} > 
                                                    <AnimatedNumber value={item.number} />
                                                </Typography>
                                            </Box>
                                            
                                            <Box>
                                                <Typography sx={{color:"#283246",fontSize:"16px",textAlign:'center'}} >{item.name}</Typography>
                                            </Box>
                                            <Box sx={{height:"20px"}} >
                                            {/* <Divider orientation="vertical" flexItem sx={{ backgroundColor:"red",height:"5px", }} /> */}
                                            
                                            </Box>

                                           
                                        </Box>
                                        
                                    )
                                    
                                })}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "white", flex: 1, display: "flex", flexDirection: "column",borderRadius:"20px",padding:"16px" }} >
                <Box sx={{ display: "flex", alignItems: "center", }} >
                    <Box sx={{ flex: "1 1 50%" }} >
                        <img src={VehicleIcon} />
                    </Box>
                    <Box sx={{ flex: "1 1 50%" }}>
                        <Typography sx={{ color: "#283246", fontSize: "20px" }} >Vehicles</Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={5}>
                        {chunkedVehiclesContent.map((chunk, index) => (
                            <Grid item  xs={6} key={index}>
                                {chunk.map((item, subIndex) => {
                                    
                                    
                                    return (
                                        <Box key={subIndex} sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        alignItems:'center',
                                        
                                        // borderRight:UserMapKey==0?"1px solid red":"",
                                        // borderBottom:UserMapKey==0?"1px solid red":"",
                                        // borderLeft:UserMapKey==3?"1px solid red":"",
                                        // borderTop:UserMapKey==3?"1px solid red":""
                                        
                                        }} >
                                            <Box>
                                                <Typography  sx={{color:"#F99494",fontWeight:"600",fontSize:"30px",textAlign:"center"}} >  <AnimatedNumber value={item.number} /></Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{color:"#283246",fontSize:"16px",textAlign:'center'}} >{item.name}</Typography>
                                            </Box>
                                            <Box sx={{height:"20px"}} ></Box>

                                           
                                        </Box>
                                    )
                                    
                                })}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ backgroundColor: "white", flex: 1, display: "flex", flexDirection: "column",borderRadius:"20px",padding:"16px" }} >
                <Box sx={{ display: "flex", alignItems: "center", }} >
                    <Box sx={{ flex: "1 1 50%" }} >
                        <img src={TripsIcon} />
                    </Box>
                    <Box sx={{ flex: "1 1 50%" }}>
                        <Typography sx={{ color: "#283246", fontSize: "20px" }} >Trips</Typography>
                    </Box>
                </Box>
                <Box>
                    <Grid container spacing={1}>
                        {chunkedTripsContent.map((chunk, index) => (
                            <Grid item  xs={6} key={index}>
                                {chunk.map((item, subIndex) => {
                                    
                                    
                                    return (
                                        <Box key={subIndex} sx={{
                                        display:"flex",
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        alignItems:'center',
                                        
                                        // borderRight:UserMapKey==0?"1px solid red":"",
                                        // borderBottom:UserMapKey==0?"1px solid red":"",
                                        // borderLeft:UserMapKey==3?"1px solid red":"",
                                        // borderTop:UserMapKey==3?"1px solid red":""
                                        
                                        }} >
                                            <Box>
                                                <Typography  sx={{color:"#F99494",fontWeight:"600",fontSize:"30px",textAlign:"center"}} >  <AnimatedNumber value={item.number} /></Typography>
                                            </Box>
                                            <Box>
                                                <Typography sx={{color:"#283246",fontSize:"16px",textAlign:'center'}} >{item.name}</Typography>
                                            </Box>
                                            <Box sx={{height:"20px"}} ></Box>

                                            {/* {UserMapKey++} */}
                                        </Box>
                                    )
                                    
                                })}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>


        </Box>
    )
}