import { Box, Paper, Typography } from "@mui/material"
import FilledButton from "../../../components/MainButtons/FilledButton"
import Plus from "../../../assets/Icons/plus.svg"
import SearchTextField from "../../../components/SearchTextField"
import { useState } from "react"
import {useNavigate} from "react-router-dom"
import UserTableComponent from "../../UserManagement/UserTable"
import {PaymentBillingPlanTablesCellItems,PaymentBillingPlanTablesData} from "../utills"
import { useDispatch, useSelector } from "react-redux"
import DeleteBillingPlanModal from "../DeleteBillingPlan/DeleteBillingPlan"
import { deleteBillingPlan } from "../../../slices/billingplanSlice"





const BillingPlanTable = () => {
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const {billingPlanData}=useSelector((state)=>state.billingPlanManage)
    console.log(billingPlanData,"this is redux")
    const [searchInput, setSearchInput] = useState("");
    const[deleteModal,setDeleteModal]=useState(false)
    const[deleteBillingPlanId,setBillingPlanId]=useState()

    const handleDeleteModalClose=()=>{
        setDeleteModal(false)
    }

    const handleDeleteBillingPlan=()=>{
        
        dispatch(deleteBillingPlan(deleteBillingPlanId))
        setDeleteModal(false)
    
    }

    const OpenDeleteBillingPlan=(fleet_id)=>{
        setDeleteModal(true)
        setBillingPlanId(fleet_id)
    }


    const handleSearchChange = (event) => {
        setSearchInput(event.target.value);
    };

    
    const filteredBillingPlanData = searchInput? billingPlanData.filter((billingplan) =>billingplan?.name?.toLowerCase().includes(searchInput?.toLowerCase())): billingPlanData
    return (
        <Box sx={{display:"flex",flexDirection:"column",gap:"20px",minHeight:"75vh"}} > 
            <Box sx={{ display: "flex", justifyContent: "space-between" }} >
                <Box  >
                    <Typography
                        sx={{ display: "flex", gap: "10px",fontSize:"24px",fontWeight:"600",color:"#283246" }}
                    >Billing Plan
                    </Typography>
                </Box>
                <Box 
                     onClick={()=>{navigate("/dashboard/payments/create-billing-plan")}}
                >
                    <FilledButton
                        startIcon={<img src={Plus} style={{ width: "18px", height: "18px" }} />}
                        sx={{ width: '200px', height: '35px', borderRadius: '6px', ml: 3,fontSize: "14px", fontWeight: "600"  }}
                    >
                        Add Billing Plan
                    </FilledButton>
                </Box>
            </Box>
            <Paper
                sx={{
                    boxShadow: "none",
                    display:"flex",
                    justifyContent:"end",
                    border: '1px solid #DDDEE1',
                    background: '#F1F1F2',
                    height: '42px', 
                    p: 1, 
                    borderRadius: '6px', 
                    mb: 2,
                    display:"flex"
                    
                }}>

                  <Box>
                      <SearchTextField sx={{marginBottom:0}} onChange={handleSearchChange} />
                  </Box>
                </Paper>
            <Box>
                <UserTableComponent
                tableHeaders={PaymentBillingPlanTablesCellItems}
                data={filteredBillingPlanData}
                showActions={true}
                handleEdit={()=>{}}
                handleDelete={OpenDeleteBillingPlan}
                billingPlanNavigation={true}
                />
            </Box>
            <DeleteBillingPlanModal
            open={deleteModal}
            handleClose={handleDeleteModalClose}
            handleDeleteBillingPlan={handleDeleteBillingPlan}
            />
        </Box>
    )
}

export default BillingPlanTable