import { Box, Typography, TextField, Card, Grid,CardContent,Button } from "@mui/material"
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AxonsLogo from "../assets/Icons/AxonsLogo.svg"
import LoginPageImage from "../assets/Icons/LoginPageImage.svg"


const Login = () => {





  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    try {

      navigate("/dashboard/analytics");
    } catch (error) {
      console.error(error);
      alert("Invalid login credentials");
    }
  }

  return (
    <Grid
      container
      sx={{ bgcolor: "#FFFFFF", padding: "1rem" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          minHeight:"96vh"
        }}
      >
        <Box>
          <img src={AxonsLogo} alt="axonslablogin" />
        </Box>
        <Card
          sx={{
            width: { xs: "100%", sm: 440 },
            minHeight: 320,
            borderRadius: "25px",
            mt: 7,
            boxShadow: 'none'
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                // fontFamily: theme.typography.fontFamily,
                // color: theme.palette.secondary.main,
              }}
            >
              Welcome Back 
            </Typography>
            <Box sx={{ padding: "0.6rem" }}>
              <Typography
                sx={{
                  // fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  marginLeft: "0.4rem",
                  // color: theme.palette.secondary.main,
                  display: 'flex'
                }}
              >
                Email
              </Typography>
              <TextField
                sx={{
                  mt: 1,
                  mb: 1,
                  width: '100%',
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "& input": {
                      padding: "0.8rem",
                    },
                    "& fieldset": {
                      borderWidth: "2px", // Set border width
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    // borderColor: theme.palette.secondary.main,
                  },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#d32f2f', // Red border for validation error
                  },
                  "& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#d32f2f', // Ensure red border remains on focus during error
                  }
                }}
                placeholder="Enter your email"
                value={email}
                onChange={(e)=>setEmail(e.target.value)}
              />
              <Typography
                sx={{
                  // fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  marginLeft: "0.4rem",
                  // color: theme.palette.secondary.main,
                  display: 'flex'
                }}
              >
                Password
              </Typography>
              <TextField
                sx={{
                  mt: 1,
                  mb: 1,
                  width: '100%',
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "& input": {
                      padding: "0.8rem",
                    },
                    "& fieldset": {
                      borderWidth: "2px", // Set border width
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    // borderColor: theme.palette.secondary.main,
                  },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#d32f2f', // Red border for validation error
                  },
                  "& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: '#d32f2f', // Ensure red border remains on focus during error
                  }
                }}
                placeholder="Enter your password"
                value={password}
                type="password"
                onChange={(e)=>setPassword(e.target.value)}
              />

            </Box>
            <Button
              variant="contained"
              disableRipple
              sx={{
                mt: 1,
                borderRadius: "12px",
                width: "80%",
                height: 55,
                background: "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                transition: "all 0.3s ease",
                "&:hover": {
                  background: "linear-gradient(to right, rgba(60, 70, 90, 1) 40%, rgba(20, 30, 50, 1) 100%)",
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
                "&:active": {
                  transform: "scale(0.98)",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}

              onClick={handleSubmit}
            >
              
                Login

            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          mt: { xs: 5, md: 2 },
        }}
      >
     
         
              <div className="slide">
                <img alt="carousel_image" src={LoginPageImage} height="570px"  />
              </div>
            
 

      </Grid>
    </Grid>
  )
}


export default Login