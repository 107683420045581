import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user : null,
  activeUsers: [{name:'hell'}],
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    setActiveUserData :(state,action)=>{
      state.activeUsers = action.payload
    }
  },
});

export const { loginSuccess, logout ,setActiveUserData} = userSlice.actions;
export default userSlice.reducer;


