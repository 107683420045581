// src/slices/vehicleManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { vehicleTableData } from "../features/Vehicles/utils";

const initialState = {
  vehicles: vehicleTableData,
};

const vehicleManageSlice = createSlice({
  name: "vehicle",
  initialState,
  reducers: {
    updatedVehicle: (state, action) => {
      console.log(state.vehicles, "undo")
      const updatedVehiclesData = action.payload; // the new fleet data to update
      const VehiclesIndex = state.vehicles.findIndex(vehicle => vehicle.id === updatedVehiclesData.id);
      console.log(VehiclesIndex, "fleet index")
      if (VehiclesIndex !== -1) {
        // Update the specific fleet data
        state.vehicles[VehiclesIndex] = {
          ...state.vehicles[VehiclesIndex],
          ...updatedVehiclesData
        };
      }
    },
    createVehicle: (state, action) => {
      const NewVehicleData = action.payload
      if (NewVehicleData) {
        state.vehicles = [...state.vehicles, NewVehicleData]
      }
    },
    deleteVehicle: (state, action) => {
      const deleteVehicleID = action.payload
      console.log(deleteVehicleID, "this is delete id")
      const VehicleToDelete = state.vehicles.find(vehicle => vehicle.id === deleteVehicleID)

      if (VehicleToDelete) {
        state.vehicles = state.vehicles.filter(vehicle => vehicle.id !== deleteVehicleID)
      }

    },

    vehicleAction:(state,action)=>{
      const VehicleId=action.payload
      const VehicleUpdateIndex=state.vehicles.findIndex(vehicle=>vehicle.id===VehicleId)

      if(VehicleUpdateIndex!=-1){
        state.vehicles[VehicleUpdateIndex]={
          ...state.vehicles[VehicleUpdateIndex],
          vehicle_action: state.vehicles[VehicleUpdateIndex].vehicle_action === "Lock" ? "Unlock" : "Lock"

        }
      }

      console.log(VehicleId,"this is vechile id")
    }
  },
});

export const { updatedVehicle, deleteVehicle, createVehicle, vehicleAction } = vehicleManageSlice.actions;
export default vehicleManageSlice.reducer;
