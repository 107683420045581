import { Box, IconButton, Modal, Typography } from "@mui/material"
import { MainMap } from "../../../components/MainMap/MainMap"
import CloseIcon from '@mui/icons-material/Close';




const RideEndModal = ({ rideEndLocationModal, setRideEndLocationModal }) => {

    const handleClose = () => {
        setRideEndLocationModal(false)
    }


    return (

        <Modal
            open={rideEndLocationModal}
            onClose={handleClose}
            

        >
            <Box sx={{ backgroundColor: "#F8F8F8", position: "absolute", top: "20vh", left: "40vw", width: "30vw", height: "65vh", padding: "20px",borderRadius:"20px" }} >
                <Box sx={{ display: "flex", alignItems: "center" }} >
                    <Box sx={{ flex: 1 }} >
                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }} >Vehicle Location</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose} size="large" >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                    <MainMap largeHeight={"400px"} extraLargeHeight={"470px"} />
                </Box>
            </Box>
        </Modal>

    )
}

export default RideEndModal