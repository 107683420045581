import { createSlice } from "@reduxjs/toolkit";

const initialState={
    selectedVehicle:[]
}

const VehicleTableSelectedValueManageSlice=createSlice({
    name:"Selectedvehicles",
    initialState,
    reducers:{
        updateSelectedVehicle:(state,payload)=>{
            console.log(payload,"this is payload")
            state.selectedVehicle=payload.payload
        }
    }
})

export const {updateSelectedVehicle}=VehicleTableSelectedValueManageSlice.actions
export default VehicleTableSelectedValueManageSlice.reducer