import { Paper } from "@mui/material"
import { MainMap } from "../../../components/MainMap/MainMap"

const TripsMap=()=>{
  return(

    <>
      <Paper
         sx={{
          borderRadius: "1.25rem",
          background: "#F8F8F8",
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 1,
          mb: 2,
        }}
      >
      <MainMap  largeHeight={"460px"} extraLargeHeight={"505px"} />
      </Paper>
    </>
    
    
  )
}

export default TripsMap