import { createSlice } from "@reduxjs/toolkit";
import {ParkingZoneTablesData} from "../features/ParkingZone/utills"


const intialState={
    parkingzoneData:ParkingZoneTablesData 
}


const ParkingZoneManageSlice=createSlice({
    name:"parkingzone",
    initialState:intialState,
    reducers:{
        updateParkingZone:(state,action)=>{
            console.log(state.parkingzoneData)
            const updatedParkingZoneData = action.payload; // the new fleet data to update
            const ParkingZoneIndex = state.parkingzoneData.findIndex(parkingZone => parkingZone.id === updatedParkingZoneData.id);
            console.log(ParkingZoneIndex,"ParkingZoneIndex index")
            if (ParkingZoneIndex !== -1) {
                // Update the specific fleet data
                state.parkingzoneData[ParkingZoneIndex] = {
                    ...state.parkingzoneData[ParkingZoneIndex],
                    ...updatedParkingZoneData
                };
            }
        },
        deleteParkingZone:(state,action)=>{
            const ParkingZoneId=action.payload
            console.log(ParkingZoneId,"this is delete id")
            const ParkingZoneToDelete=state.parkingzoneData.find(parkingZone=>parkingZone.id===ParkingZoneId)

            if(ParkingZoneToDelete){
                state.parkingzoneData=state.parkingzoneData.filter(parkingZone=>parkingZone.id!==ParkingZoneId)
            }
        },
        createParkingZone:(state,action)=>{
            const NewParkingZoneData=action.payload
            if(NewParkingZoneData){
                state.parkingzoneData=[...state.parkingzoneData,NewParkingZoneData]
            }
        }
    }
})


export const {createParkingZone,deleteParkingZone,updateParkingZone}=ParkingZoneManageSlice.actions
export default ParkingZoneManageSlice.reducer