import { createSlice } from "@reduxjs/toolkit";
import {TexTablesData} from "../features/Tax/utils"

const initialState={
    taxData:TexTablesData || []
}

const TaxManageSlice=createSlice({
    name:"tax",
    initialState,
    reducers:{
        updateTax:(state,action)=>{
            console.log(state.taxData,"undo")
            const updatedTaxData = action.payload; // the new fleet data to update
            const TaxIndex = state.taxData.findIndex(tax => tax.uuid === updatedTaxData.uuid);
            console.log(TaxIndex,"fleet index")
            if (TaxIndex !== -1) {
                // Update the specific fleet data
                state.taxData[TaxIndex] = {
                    ...state.taxData[TaxIndex],
                    ...updatedTaxData
                };
            }
        },
        createTax:(state,action)=>{
            const NewTaxData=action.payload
            if(NewTaxData){
                state.taxData=[...state.taxData,NewTaxData]
            }
        },
        deleteTax:(state,action)=>{
            const TaxId=action.payload
            console.log(TaxId,"this is delete id")
            const TaxToDelete=state.taxData.find(tax=>tax.uuid===TaxId)

            if(TaxToDelete){
                state.taxData=state.taxData.filter(tax=>tax.uuid!==TaxId)
            }
        }
    }
})

export const {createTax,deleteTax,updateTax}=TaxManageSlice.actions
export default TaxManageSlice.reducer