import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { combineReducers } from "redux";
import userReducer from "../features/user/userSlice"; // Example slice
import userManageReducer from "../slices/userManageSlice"
import vehicleManageReducer from "../slices/vehicleManageSlice"
import fleetReducer from "../slices/fleetSlice"
import parkingzoneReducer from "../slices/parkingzoneSlice"
import restrictedzoneReducer from "../slices/restrictedzoneSlice"
import servicezoneReducer from "../slices/servicezoneSlice"
import slowspeedzoneReducer from "../slices/slowspeedzoneSlice"
import paymentgatewayReducer from "../slices/paymentgatewaySlice"
import taxReducer from "../slices/taxSlice"
import billingplanReducer from "../slices/billingplanSlice"
import selectedVehicleReducer from "../slices/vehicletableselectedValueSlice"
import settingReducer from "../slices/settingsSlice"


const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  user: userReducer,
  userManage:userManageReducer,
  vehicleManage:vehicleManageReducer,
  fleetManage:fleetReducer,
  parkingzoneManage:parkingzoneReducer,
  restrictedzoneManage:restrictedzoneReducer,
  servicezoneManage:servicezoneReducer,
  slowspeedzoneManage:slowspeedzoneReducer,
  paymentgatewayManage:paymentgatewayReducer,
  taxManage:taxReducer,
  billingPlanManage:billingplanReducer,
  selectedVehicleManage:selectedVehicleReducer,
  settingManage:settingReducer
  // Add more slices here
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
