import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import Chip from '@mui/material/Chip';


const PaymentDetailsModal = ({ paymentDetailsModal, setPaymentDeailsModal, selectedPaymentData }) => {
    console.log(selectedPaymentData, "this is in modal")
    const handleClose = () => {
        setPaymentDeailsModal(false)
    }


    return (

        <Modal
            open={paymentDetailsModal}
            onClose={handleClose}


        >
            <Box sx={{ backgroundColor: "#F8F8F8", position: "absolute", top: "20vh", left: "40vw", width: "30vw", height: "60vh", padding: "20px", borderRadius: "20px", display: "flex", flexDirection: "column", gap: "20px" }} >
                <Box sx={{ display: "flex", alignItems: "center" }} >
                    <Box sx={{ flex: 1 }} >
                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }} >Transaction details</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose} size="large" >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px", marginInline: "20px",
                    border: "1px solid #DDDEE1",
                    padding: "20px",
                    borderRadius: "20px"
                }} >
                    <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Amount</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }}> {selectedPaymentData?.amount}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Status</Typography>
                        </Box>
                        {
                            selectedPaymentData?.status == "Paid" ? (<Box>
                                <Chip
                                    label={selectedPaymentData?.status}
                                    variant="outlined"
                                    size="small"
                                    icon={<CircleIcon color="#83C3A0" sx={{ fontSize: "12px" }} />}
                                    sx={{
                                        color: "#83C3A0",
                                        background: "#F1F7F3",
                                        borderColor: "#83C3A0",
                                        fontSize: "12px"
                                    }}
                                />
                            </Box>) : (<></>)
                        }
                        {
                            selectedPaymentData?.status == "Unpaid" ? (<Box>
                                <Chip
                                    label={selectedPaymentData?.status}
                                    variant="outlined"
                                    size="small"
                                    icon={<CircleIcon color="#F99494" sx={{ fontSize: "16px" }} />}
                                    sx={{
                                        color: "#F99494",
                                        background: "#FFF3F3",
                                        borderColor: "#F99494",
                                        fontSize: "20px",
                                        fontWeight:"600"
                                    }}
                                />
                            </Box>) : (<></>)
                        }
                        {
                            selectedPaymentData?.status=="Refund"?( <Box>
                                <Chip
                                  label={selectedPaymentData?.status}
                                  variant="outlined"
                                  size="small"
                                  icon={<CircleIcon color="#F99494" sx={{ fontSize: "16px" }} />}
                                  sx={{
                                    color: "#C496FF",
                                    background: "#FFF3F3",
                                    borderColor: "#C496FF",
                                    fontSize: "20px",
                                    fontWeight:"600"
                                  }}
                                />
                              </Box>):(<></>)
                        }
                    </Box>
                    <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }}>Fleet</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{selectedPaymentData?.fleet}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Payment Type  </Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{selectedPaymentData?.type}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", borderBottom: "1px solid #DDDEE1", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Trip ID</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{selectedPaymentData?.id}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", paddingBlock: "8px" }} >
                        <Box sx={{ flex: 1 }} >
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >Transection Date</Typography>
                        </Box>
                        <Box>
                            <Typography sx={{ color: "#535A6A", fontSize: "20px", fontWeight: "500" }} >{selectedPaymentData?.date}</Typography>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Modal>

    )
}

export default PaymentDetailsModal