import { Box, IconButton, Typography,Card,Avatar,useTheme } from "@mui/material"
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from "react-router-dom";
import TotalVehiclesColorIcon from "../../../assets/Icons/VehicleNewIcon.svg"
import TodayTripsColorIcon from "../../../assets/Icons/AvrageTripsDurationIco.svg"
import TotalTripsColorIocn from "../../../assets/Icons/TotalTripsUpdatedIcon.svg"
import RupeeImageIcon from "../../../assets/Icons/RuppeImageIcon.svg"

const FleetCards = ({fleet_name,revenue_generated,today_trips,total_trips,total_vehicle}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
        <Box sx={{display:"flex",flexDirection:"column",gap:"10px"}} >
            <Box sx={{display:"flex",alignItems:"center",gap:"2px"}} >
               
                <Box>
                    <Typography 
                        sx={{
                            color:"#283246",
                            fontSize:"24px",
                            fontWeight:"600"
                        }}
                    > {fleet_name} </Typography>
                </Box>
            </Box>
                <Box
                    sx={{
                        display:"flex",
                        gap:"40px"
                    }}
                >
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                // backgroundColor:"#97A1B61A",
                                mb: 1,
                            }}
                        >
                            <Avatar src={TotalVehiclesColorIcon} />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: theme.typography.text1.color,
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Total Vehicle
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {total_vehicle}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:"#97A1B61A",
                                mb: 1,
                            }}
                        >
                            <Avatar src={TotalTripsColorIocn} />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: theme.typography.text1.color,
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Total Trips
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {total_trips}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:"#97A1B61A",
                                mb: 1,
                            }}
                        >
                            <Avatar src={TodayTripsColorIcon} />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: theme.typography.text1.color,
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Today Trips
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {today_trips}
                        </Typography>
                    </Card>
                    <Card
                        sx={{
                            width: "20%",
                            borderRadius: "1rem",
                            padding: "1rem 3.438rem",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center content horizontally
                            boxShadow: "none",
                            
                        }}
                    >
                        <Box
                            sx={{
                                borderRadius: "50%",
                                width: "52px",
                                height: "52px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor:"#97A1B61A",
                                mb: 1,
                            }}
                        >
                            <Avatar src={RupeeImageIcon} />
                        </Box>

                        <Typography
                            sx={{
                                fontSize: "16px",
                                color: theme.typography.text1.color,
                                fontWeight: 500,
                                marginBottom: "0.5rem"
                            }}
                        >
                            Revenue Generated
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "1.375rem",
                                color: theme.typography.text1.color,
                                fontWeight: 700,
                            }}
                        >
                            {revenue_generated}
                        </Typography>
                    </Card>
                </Box>
        </Box>
    )
}

export default FleetCards