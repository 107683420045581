import { Paper } from "@mui/material"
import SlowSpeedZoneTable from "./SlowSpeedZoneTable/SlowSpeedZoneTable"



const SlowSpeedZone=()=>{
    return(
        <Paper
        sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
        }}>
        <SlowSpeedZoneTable/>
    </Paper>
    )
}

export default SlowSpeedZone