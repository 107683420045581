import { Box, Paper } from "@mui/material"
import FleetCards from "./FleetCards"
import EditFleetForm from "./EditFleetForm"
import { useLocation } from "react-router-dom";
const EditFleet = () => {
    const location = useLocation()

    const { data } = location.state || {}
    console.log("this is data", data)

    return (
        <Box>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>

                <FleetCards 
                    fleet_name={data.fleet_name}
                    revenue_generated={data.revenue_generated}
                    today_trips={data.today_trips}
                    total_trips={data.total_trips}
                    total_vehicle={data.total_vehicle}

                />
            </Paper>
            <Paper
                sx={{
                    borderRadius: "1.25rem",
                    background: "#F8F8F8",
                    padding: "1.563rem",
                    boxShadow: "none",
                    gap: 2,
                    mt: 1,
                    mb: 2,
                }}>

                <EditFleetForm 
                    fleet_id={data.fleet_id}
                    address={data.address}
                    alert_email={data.alert_email}
                    auto_close_vehicle={data.auto_close_vehicle}
                    auto_end_ride_critical_battery_vehicle={data.auto_end_ride_critical_battery_vehicle}
                    auto_end_ride_ideal_vehicle={data.auto_end_ride_ideal_vehicle}
                    auto_end_ride_paused_vehicle={data.auto_end_ride_paused_vehicle}
                    billing_plan={data.billing_plan}
                    createdAt={data.createdAt}
                    createdUser={data.createdUser}
                    createdUserRole={data.createdUserRole}
                    distance_lock_unlock_vehicle={data.distance_lock_unlock_vehicle}
                    fleet_name={data.fleet_name}
                    fleet_type={data.fleet_type}
                    lat={data.lat}
                    lng={data.lng}
                    location={data.location}
                    low_battery_level={data.low_battery_level}
                    parking_requirment={data.parking_requirment}
                    pause_ride={data.pause_ride}
                    support_email={data.support_email}
                    support_phone_number={data.support_phone_number}
                    activity={data.activity}
                />
            </Paper>
            
        </Box>
    )
}

export default EditFleet