import { Box, Typography,Paper } from "@mui/material"
import TaxTable from "./TaxTable/TaxTable"


const Tax=()=>{
    return(
        <Box>
           <Paper
        sx={{
            borderRadius: "1.25rem",
            background: "#F8F8F8",
            padding: "1.563rem",
            boxShadow: "none",
            gap: 2,
            mt: 1,
            mb: 2,
        }}>
            <TaxTable/>
        </Paper>
        </Box>
    )
}

export default Tax