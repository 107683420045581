import { Box, Typography } from "@mui/material";
import Chart from "react-apexcharts"
const FleetRevenueLineChart = () => {
  const series = [
    {
      name: 'Value',
      data: [50,120,80,42,23,73,150] // Adjust this data as needed
    }
  ];
  const options = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ],
      labels: {
        style: {
          colors: ["#535A6A"],
          fontSize: '14px',
          fontFamily: 'Helvetica Neue',
          fontWeight: 400,
          // cssClass: 'apexcharts-yaxis-label',
        },
      }

    },

    dataLabels: {
      enabled: false,},
    
    yaxis: {
      min: 0,
      max: 250,
      labels: {
        formatter: (value) => `$${value}`, // Add $ sign to y-axis labels
      },
      style: {
        colors: ["#535A6A"],
        fontSize: '14px',
        fontFamily: 'Helvetica Neue',
        fontWeight: 400,
        // cssClass: 'apexcharts-yaxis-label',
      },
    },
    fill: {
        colors: "#FFBABAF2",
        opacity: 0.5,
        type: 'gradient',
        gradient: {
            shade: 'light',
            type: "vertical",
            shadeIntensity: 0,
            gradientToColors: "#FFBABAF2",
            inverseColors: true,
            opacityFrom: 0.1,
            opacityTo: 0.7,
            stops: [0, 0, 100],

        }
    },

    colors: ['#F99494'], // Line color
    stroke: {
      curve: "straight",
      width: 3, // Line width
    },
    markers: {
      size: 5,
    },
    grid: {
      borderColor: '#e7e7e7',
    },
    tooltip: {
      shared: true,
      intersect: false,
    },
  };

  return (
    <Box
      bgcolor="#F8F8F8"
      borderRadius="25px"
    >
      <Box>
      <Typography 
         sx={{
          padding:"20px",
          color: "black",
          fontSize: "16px",
          fontWeight: "600",
          display:"flex"
        }}>Fleet Revenue : $
        <Typography
            sx={{
                color: "#F99494",
                fontSize: "16px",
                fontWeight: "600"
            }}
        >200</Typography> 
        
        </Typography>
      </Box>
      <Box>
        <Chart
          options={options}
          series={series}
          type="area"
          height="330"
        />
      </Box>
    </Box>
  )
}
export default FleetRevenueLineChart