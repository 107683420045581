import { Box, Typography } from "@mui/material"
import TransactionTable from "./TransactionTable/TransactionTable"

const Transaction=()=>{
    return(
        <Box>
           <TransactionTable/>
        </Box>
    )
}

export default Transaction